import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { AuthService } from '../../services/auth.service';
import { PropsWithChildren, createContext, useState } from 'react';

export const ApolloContext = createContext<{ apiError?: string, tokenInvalidated: boolean }>({
  apiError: undefined,
  tokenInvalidated: false
});

export function Apollo(props: PropsWithChildren) {

  const [apiError, setApiError] = useState<string>();
  const [tokenInvalidated, setTokenInvalidated] = useState<boolean>(false);

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  });

  const authLink = setContext((_, { headers }) => {
    setApiError(undefined);
    const token = AuthService.getToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        const code = (extensions as { code?: string })?.code;
  
        if (code === 'UNAUTHENTICATED') {
          setApiError(message);
          setTokenInvalidated(true);
        }
      });
    }
    if (networkError) {
      if (!isInternetOnline()) {
        setApiError('No internet connection');
      } else {
        setApiError('Server connectivity error. Please contact us if this persists');
      }
    }
  });

  const isInternetOnline = () =>
    typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
        ? navigator.onLine
        : true;

  const apolloClient = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloContext.Provider value={{ apiError, tokenInvalidated }}>
      <ApolloProvider client={apolloClient}>
        {props.children}
      </ApolloProvider>
    </ApolloContext.Provider>
  );
}