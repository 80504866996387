import { HTMLProps, useEffect, useState } from "react";
import { toHumanReadableCurrency } from "../../utils/string.utils";

interface Props extends HTMLProps<HTMLInputElement> {
  label: string;
  containerClassname?: string;
  isCurrency?: boolean;
}

export function FormInput({ label, containerClassname, className, isCurrency, ...props }: Props) {

  function getInputClassname() {
    let className = 'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg '
    className += 'focus:ring-pn-blue focus:border-pn-blue block w-full p-2.5 '

    if (className) {
      className += className;
    }
    return className;
  }

  function getId() {
    return props.id || label.toLowerCase().replace(' ', '_');
  }

  return (
    <div className={`flex flex-col mb-4 ${containerClassname}`}>
      <label className="text-sm font-semibold mb-2" htmlFor={getId()}>{label}</label>
      <div className="relative">
        <p className="absolute top-3 text-slate-500 right-5 text-sm">{isCurrency ? toHumanReadableCurrency(parseInt(props.value as string) * 100) : ''}</p>
        <input className={getInputClassname()} id={getId()} {...props} />
      </div>
    </div>
  );
}
