import * as d3 from 'd3';
import { format } from 'date-fns';
import { useEffect, useRef } from 'react';

let isInitialized = false;

export function AnalyticsLogin() {

  // set the dimensions and margins of the graph
  var margin = { top: 0, right: 50, bottom: 20, left: 40 },
    width = 1150 - margin.left - margin.right,
    height = 300 - margin.top - margin.bottom;

  const ref = useRef<HTMLDivElement>(null);
  const svg = useRef<d3.Selection<SVGGElement, unknown, null, undefined>>();

  // append the svg object to the body of the page
  // let svg: 

  useEffect(() => {
    return () => {
      isInitialized = false;
      if (ref.current) {
        while (ref.current.hasChildNodes()) {
          ref.current.removeChild(ref.current.firstChild!);
        }
      }
    }
  }, []);

  useEffect(() => {
    init();
  }, [ref]);

  function init() {

    if (isInitialized) {
      return;
    }
    isInitialized = true;

    svg.current = d3.select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    const xAxis = data.map(d => d.hour);
    const yAxis = data.map(d => d.day);

    // Build X scales and axis:
    const x = d3.scaleBand()
      .range([0, width])
      .domain(xAxis)
      .padding(0.05);

    svg.current!.append("g")
      .style("font-size", 12)
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).tickSize(0))
      .select(".domain").remove()

    // Build Y scales and axis:
    const y = d3.scaleBand()
      .range([height, 0])
      .domain(yAxis)
      .padding(0.05);

    svg.current!.append("g")
      .style("font-size", 12)
      .call(d3.axisLeft(y).tickSize(0))
      .select(".domain").remove()

    // Build color scale
    const colorize = d3.scaleSequential()
      .interpolator(d3.interpolateInferno)
      .domain([20, 1])

    // create a tooltip
    const tooltip = d3.select(ref.current)
      .append("div")
      .style("opacity", 0)
      .style("position", 'absolute')
      .attr("class", "tooltip")
      .style("background-color", "#14223d")
      .style("color", "#ffffff")
      .style("border", "1px solid #cccccc")
      .style("border-radius", "8px")
      .style("padding", "15px 20px")

    // Three function that change the tooltip when user hover / move / leave a cell
    const mouseover = () => {
      tooltip
        .style("opacity", 0.9);
      // d3.select(ref.current)
      //   .style("stroke", "black")
      //   .style("opacity", 1);
    }
    function mousemove(event: any, d: d3.DSVRowString<string>) {
      tooltip
        .html(`<b>${d.value} investors logged in</b><br />${d.day} ${d.hour} hrs`)
        .style("left", (event.x - 80)  + "px")
        .style("top", (event.y - 100) + "px");
    }
    const mouseleave = () => {
      tooltip
        .style("opacity", 0);
      // d3.select(ref.current)
      //   .style("stroke", "none")
      //   .style("opacity", 0.8);
    }

    // add the squares
    svg.current!.selectAll()
      .data(data, d => `${d!.day}, ${d!.hour}`)
      .join("rect")
      .attr("x", d => x(d.hour)!)
      .attr("y", d => y(d.day)!)
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("width", x.bandwidth())
      .attr("height", y.bandwidth())
      .style("fill", (d) => colorize(d.value))
      .style("stroke-width", 4)
      .style("stroke", "none")
      .style("opacity", 0.8)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove as any)
      .on("mouseleave", mouseleave)
  }

  return (
    <div className='rounded-lg shadow-lg bg-white p-5'>
      <h2 className="text-bold text-md">Login frequency of investors</h2>
      <div className="container mt-3" ref={ref} style={{ width: '100%', height: 300 }}></div>
    </div>
  )

}

const data = [
  { "day": "SUN", "hour": 0, "value": 7 },
  { "day": "SUN", "hour": 1, "value": 2 },
  { "day": "SUN", "hour": 2, "value": 5 },
  { "day": "SUN", "hour": 3, "value": 9 },
  { "day": "SUN", "hour": 4, "value": 10 },
  { "day": "SUN", "hour": 5, "value": 0 },
  { "day": "SUN", "hour": 6, "value": 3 },
  { "day": "SUN", "hour": 7, "value": 1 },
  { "day": "SUN", "hour": 8, "value": 7 },
  { "day": "SUN", "hour": 9, "value": 8 },
  { "day": "SUN", "hour": 10, "value": 6 },
  { "day": "SUN", "hour": 11, "value": 2 },
  { "day": "SUN", "hour": 12, "value": 7 },
  { "day": "SUN", "hour": 13, "value": 8 },
  { "day": "SUN", "hour": 14, "value": 5 },
  { "day": "SUN", "hour": 15, "value": 7 },
  { "day": "SUN", "hour": 16, "value": 9 },
  { "day": "SUN", "hour": 17, "value": 3 },
  { "day": "SUN", "hour": 18, "value": 2 },
  { "day": "SUN", "hour": 19, "value": 8 },
  { "day": "SUN", "hour": 20, "value": 1 },
  { "day": "SUN", "hour": 21, "value": 6 },
  { "day": "SUN", "hour": 22, "value": 10 },
  { "day": "SUN", "hour": 23, "value": 3 },
  { "day": "MON", "hour": 0, "value": 4 },
  { "day": "MON", "hour": 1, "value": 9 },
  { "day": "MON", "hour": 2, "value": 0 },
  { "day": "MON", "hour": 3, "value": 1 },
  { "day": "MON", "hour": 4, "value": 6 },
  { "day": "MON", "hour": 5, "value": 7 },
  { "day": "MON", "hour": 6, "value": 10 },
  { "day": "MON", "hour": 7, "value": 8 },
  { "day": "MON", "hour": 8, "value": 5 },
  { "day": "MON", "hour": 9, "value": 6 },
  { "day": "MON", "hour": 10, "value": 7 },
  { "day": "MON", "hour": 11, "value": 10 },
  { "day": "MON", "hour": 12, "value": 4 },
  { "day": "MON", "hour": 13, "value": 2 },
  { "day": "MON", "hour": 14, "value": 6 },
  { "day": "MON", "hour": 15, "value": 8 },
  { "day": "MON", "hour": 16, "value": 9 },
  { "day": "MON", "hour": 17, "value": 5 },
  { "day": "MON", "hour": 18, "value": 0 },
  { "day": "MON", "hour": 19, "value": 0 },
  { "day": "MON", "hour": 20, "value": 7 },
  { "day": "MON", "hour": 21, "value": 8 },
  { "day": "MON", "hour": 22, "value": 4 },
  { "day": "MON", "hour": 23, "value": 3 },
  { "day": "TUE", "hour": 0, "value": 2 },
  { "day": "TUE", "hour": 1, "value": 4 },
  { "day": "TUE", "hour": 2, "value": 8 },
  { "day": "TUE", "hour": 3, "value": 6 },
  { "day": "TUE", "hour": 4, "value": 0 },
  { "day": "TUE", "hour": 5, "value": 7 },
  { "day": "TUE", "hour": 6, "value": 9 },
  { "day": "TUE", "hour": 7, "value": 5 },
  { "day": "TUE", "hour": 8, "value": 3 },
  { "day": "TUE", "hour": 9, "value": 10 },
  { "day": "TUE", "hour": 10, "value": 2 },
  { "day": "TUE", "hour": 11, "value": 6 },
  { "day": "TUE", "hour": 12, "value": 7 },
  { "day": "TUE", "hour": 13, "value": 4 },
  { "day": "TUE", "hour": 14, "value": 8 },
  { "day": "TUE", "hour": 15, "value": 5 },
  { "day": "TUE", "hour": 16, "value": 3 },
  { "day": "TUE", "hour": 17, "value": 1 },
  { "day": "TUE", "hour": 18, "value": 9 },
  { "day": "TUE", "hour": 19, "value": 10 },
  { "day": "TUE", "hour": 20, "value": 7 },
  { "day": "TUE", "hour": 21, "value": 2 },
  { "day": "TUE", "hour": 22, "value": 5 },
  { "day": "TUE", "hour": 23, "value": 3 },
  { "day": "WED", "hour": 0, "value": 0 },
  { "day": "WED", "hour": 1, "value": 9 },
  { "day": "WED", "hour": 2, "value": 3 },
  { "day": "WED", "hour": 3, "value": 6 },
  { "day": "WED", "hour": 4, "value": 8 },
  { "day": "WED", "hour": 5, "value": 5 },
  { "day": "WED", "hour": 6, "value": 2 },
  { "day": "WED", "hour": 7, "value": 4 },
  { "day": "WED", "hour": 8, "value": 7 },
  { "day": "WED", "hour": 9, "value": 10 },
  { "day": "WED", "hour": 10, "value": 0 },
  { "day": "WED", "hour": 11, "value": 3 },
  { "day": "WED", "hour": 12, "value": 5 },
  { "day": "WED", "hour": 13, "value": 7 },
  { "day": "WED", "hour": 14, "value": 9 },
  { "day": "WED", "hour": 15, "value": 2 },
  { "day": "WED", "hour": 16, "value": 4 },
  { "day": "WED", "hour": 17, "value": 18 },
  { "day": "WED", "hour": 18, "value": 6 },
  { "day": "WED", "hour": 19, "value": 10 },
  { "day": "WED", "hour": 20, "value": 1 },
  { "day": "WED", "hour": 21, "value": 5 },
  { "day": "WED", "hour": 22, "value": 3 },
  { "day": "WED", "hour": 23, "value": 7 },
  { "day": "THU", "hour": 0, "value": 6 },
  { "day": "THU", "hour": 1, "value": 3 },
  { "day": "THU", "hour": 2, "value": 2 },
  { "day": "THU", "hour": 3, "value": 9 },
  { "day": "THU", "hour": 4, "value": 8 },
  { "day": "THU", "hour": 5, "value": 0 },
  { "day": "THU", "hour": 6, "value": 5 },
  { "day": "THU", "hour": 7, "value": 7 },
  { "day": "THU", "hour": 8, "value": 10 },
  { "day": "THU", "hour": 9, "value": 4 },
  { "day": "THU", "hour": 10, "value": 3 },
  { "day": "THU", "hour": 11, "value": 2 },
  { "day": "THU", "hour": 12, "value": 6 },
  { "day": "THU", "hour": 13, "value": 1 },
  { "day": "THU", "hour": 14, "value": 7 },
  { "day": "THU", "hour": 15, "value": 8 },
  { "day": "THU", "hour": 16, "value": 0 },
  { "day": "THU", "hour": 17, "value": 9 },
  { "day": "THU", "hour": 18, "value": 10 },
  { "day": "THU", "hour": 19, "value": 2 },
  { "day": "THU", "hour": 20, "value": 4 },
  { "day": "THU", "hour": 21, "value": 3 },
  { "day": "THU", "hour": 22, "value": 7 },
  { "day": "THU", "hour": 23, "value": 6 },
  { "day": "FRI", "hour": 0, "value": 9 },
  { "day": "FRI", "hour": 1, "value": 6 },
  { "day": "FRI", "hour": 2, "value": 3 },
  { "day": "FRI", "hour": 3, "value": 8 },
  { "day": "FRI", "hour": 4, "value": 4 },
  { "day": "FRI", "hour": 5, "value": 10 },
  { "day": "FRI", "hour": 6, "value": 2 },
  { "day": "FRI", "hour": 7, "value": 1 },
  { "day": "FRI", "hour": 8, "value": 0 },
  { "day": "FRI", "hour": 9, "value": 7 },
  { "day": "FRI", "hour": 10, "value": 3 },
  { "day": "FRI", "hour": 11, "value": 6 },
  { "day": "FRI", "hour": 12, "value": 8 },
  { "day": "FRI", "hour": 13, "value": 4 },
  { "day": "FRI", "hour": 14, "value": 9 },
  { "day": "FRI", "hour": 15, "value": 10 },
  { "day": "FRI", "hour": 16, "value": 2 },
  { "day": "FRI", "hour": 17, "value": 7 },
  { "day": "FRI", "hour": 18, "value": 5 },
  { "day": "FRI", "hour": 19, "value": 1 },
  { "day": "FRI", "hour": 20, "value": 3 },
  { "day": "FRI", "hour": 21, "value": 6 },
  { "day": "FRI", "hour": 22, "value": 4 },
  { "day": "FRI", "hour": 23, "value": 8 },
  { "day": "SAT", "hour": 0, "value": 3 },
  { "day": "SAT", "hour": 1, "value": 6 },
  { "day": "SAT", "hour": 2, "value": 0 },
  { "day": "SAT", "hour": 3, "value": 9 },
  { "day": "SAT", "hour": 4, "value": 5 },
  { "day": "SAT", "hour": 5, "value": 10 },
  { "day": "SAT", "hour": 6, "value": 8 },
  { "day": "SAT", "hour": 7, "value": 1 },
  { "day": "SAT", "hour": 8, "value": 2 },
  { "day": "SAT", "hour": 9, "value": 4 },
  { "day": "SAT", "hour": 10, "value": 3 },
  { "day": "SAT", "hour": 11, "value": 7 },
  { "day": "SAT", "hour": 12, "value": 6 },
  { "day": "SAT", "hour": 13, "value": 5 },
  { "day": "SAT", "hour": 14, "value": 8 },
  { "day": "SAT", "hour": 15, "value": 9 },
  { "day": "SAT", "hour": 16, "value": 1 },
  { "day": "SAT", "hour": 17, "value": 0 },
  { "day": "SAT", "hour": 18, "value": 4 },
  { "day": "SAT", "hour": 19, "value": 10 },
  { "day": "SAT", "hour": 20, "value": 5 },
  { "day": "SAT", "hour": 21, "value": 3 },
  { "day": "SAT", "hour": 22, "value": 6 },
  { "day": "SAT", "hour": 23, "value": 7 },
].map(d => {
  return {
    day: d.day,
    hour: format(new Date().setHours(d.hour), 'HH:00'),
    value: d.value
  }
});
