import { Sidebar } from "../../components/sidebar/sidebar";

export function RewardsPage() {
  return (
    <>
      <Sidebar />

      <section className="content rewards">
        <h1></h1>
      </section>
    </>
  )
}