import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/sidebar/sidebar";
import { AppRoute } from "../../routes";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import ImgContactPerson from '../../assets/img/properties/contact-person.jpeg';
import ImgMap from '../../assets/img/properties/property-map.png';
import { QUERY_GET_PROPERTY } from "../../graphql/queries/get-property";
import { AuthService } from "../../services/auth.service";
import { addCurencySubunits,  toCurrencyDisplay, toTokens } from "../../utils/currency.util";
import { format } from "date-fns";
import { toPercentageDisplay } from "../../utils/string.utils";
// import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { getPropertyImageUrl } from "../../utils/image.utils";
import { Property } from "../../__generated__/graphql";
import { FormButton } from "../../components/form/button";
import { PropertyDeleteModal } from "./component/deletemodal";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";


export function PropertyPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [getProperty, { data, loading, error }] = useLazyQuery<{ property: Property }>(QUERY_GET_PROPERTY, { errorPolicy: 'all' });
  const [showModal, setShowModal] = useState(false);

  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    getProperty({ variables: { id } } )
  }, []);

  function handleEdit() {
    navigate(`${AppRoute.PropertyEdit}?id=${data?.property.id}`)
  }



  return (
    <>
      {/* <GlobalErrorHandler /> */}
    
      <Sidebar />
      { data && data.property && (
      <div className=" content property ">
        <PropertyDeleteModal show={showModal} onShowHide={setShowModal} property={data?.property} />
        <div className="flex m-flex-column-reverse justify-between mb-6 ">
          <div className="breadcrumb">
            <Link className="text-sm " to={AppRoute.PropertyList}>Properties</Link>
            <span className="divider text-lg">&gt;</span>
            <span className="text-gray-500  text-sm ">{data.property.title}</span>
          </div>
          <div className="actions">
            {/* <Link className="btn btn-secondary mr-2" to={`${AppRoute.Property}?id=${id}`} > Edit </Link> */}
            <FormButton className="btn mr-2" title="Edit" size="sm"  onClick={handleEdit} />
            <FormButton className="btn " title="Delete" size="sm" onClick={()=>{setShowModal(true)}} />
            {/* <a className="btn btn-secondary"> Delete </a> */}

          </div>
        </div>
        { data.property.images && !!data.property.images.length &&
          <div className="images mb-12 ">
            <div className="big-image"><img className=" rounded-md " alt="" src={ getPropertyImageUrl(data.property.id, data.property.images[0]) } /></div>
            <div className="small-images m-hidden">
            { data.property.images.map(image => 
              <img key={image.id} className=" rounded-md " alt="" src={getPropertyImageUrl(data.property.id, image)} />
            )}
            </div>
          </div>
        }
        <div className="flex flex-col max-w-fit md:flex-row gap-6 ">
          <div className="details card flex-3 max-w-fit">
            <h1 className="font-sans text-deep-blue tracking-normal text-3xl  font-bold leading-normal mb-2">{data.property.title}</h1>
            <div className="flex gap-4 mb-6 ">
              <span className="text-sm ">{data.property.bed} bed</span>
              <span className="text-sm ">{data.property.bath} bath</span>
              <span className="text-sm ">{data.property.sqFootage} sq.ft</span>
              <span className="text-sm ">{data.property.city.name}</span>
              <span className="text-sm ">{data.property.type?.name}</span>

            </div>
            <hr className="border-0 h-0.5 bg-lighter-grey my-8" />

            <div className="flex flex-col gap-4 ">
              <div className="flex gap-2 ">
                <div className="icon" />
                <div className="flex flex-col gap-2 ">
                  <h3 className="font-semibold font-sans text-deep-blue tracking-normal  leading-normal ">{data.property.city.name}</h3>
                  <p className="font-light ">{data.property.city.excerpt}</p>
                </div>
              </div>
              { data.property.isRented &&
                <div className="flex gap-2 ">
                  <div className="icon" />
                  <div className="flex flex-col gap-2 ">
                    <h3 className="font-semibold  font-sans text-deep-blue tracking-normal  leading-normal">Rented</h3>
                    <p className="font-light ">Currently occupied and professionally managed by our team</p>
                  </div>
                </div>
              }
              { data.property.isRented && !!data.property.rents.length && 
                <div className="flex gap-2 ">
                  <div className="icon" />
                  <div className="flex flex-col gap-2 ">
                    <h3 className="font-semibold font-sans text-deep-blue tracking-normal leading-normal">Current rent is {toCurrencyDisplay(data.property.rents[0]?.rent)} per month</h3>
                    <p className="font-light ">Distributed monthly among all investors after standard charges and fees</p>
                  </div>
                </div>
              }
              <div className="flex gap-2 ">
                <div className="icon" />
                <div className="flex flex-col gap-2 ">
                  <h3 className="font-semibold font-sans text-deep-blue tracking-normal  leading-normal">{toPercentageDisplay(data.property.financials[0]?.projectedGrossYield)} annual gross yield</h3>
                  <p className="font-light ">With a net yield of {toPercentageDisplay(data.property.financials[0]?.projectedNetYield)}</p>
                </div>
              </div>
            </div>
            <hr className="border-0 h-0.5 bg-lighter-grey my-8" />
            <div>
              <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Investment Calculator</h2>
              <h3 className="text-center text-lg text-gray-800  font-light  mb-2 mt-6 ">Projected investment return of</h3>
              <h3 className="text-center text-lg font-semibold  mb-6 ">£26,475 in 5 years</h3>
              <div className="flex m-flex-col justify-between border rounded-md  px-6  py-2 mb-6 ">
                <div className="flex flex-col gap-2 ">
                  <p className="font-light  text-gray-800  text-sm ">Investment</p>
                  <h4 className="font-sans text-deep-blue  tracking-normal font-bold leading-normal">£50,000</h4>
                </div>
                <div className="flex flex-col gap-2 ">
                  <p className="font-light  text-gray-800  text-sm ">Total rental income</p>
                  <h4 className="font-sans text-deep-blue  tracking-normal font-bold leading-normal">£14,373</h4>
                </div>
                <div className="flex flex-col gap-2 ">
                  <p className="font-light  text-gray-800  text-sm ">Value appreciation</p>
                  <h4 className="font-sans text-deep-blue  tracking-normal font-bold leading-normal">£15,000</h4>
                </div>
              </div>
              <div className="chart">
              </div>
              <div className="flex flex-col gap-4 ">
                <div>
                  <div className="flex justify-between mb-6  ">
                    <p className="font-light ">Initial Investment</p>
                    <p className="font-semibold ">£50,000</p>
                  </div>
                  <div className="progress">
                    <div className="baseline" />
                    <div className="current" style={{ width: '50%' }} />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between mb-6  ">
                    <p className="font-light ">Property value growth (5 year)</p>
                    <p className="font-semibold ">30%</p>
                  </div>
                  <div className="progress">
                    <div className="baseline" />
                    <div className="current" style={{ width: '50%' }} />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between mb-6  ">
                    <p className="font-light ">Expected annual rental yield</p>
                    <p className="font-semibold ">5.75</p>
                  </div>
                  <div className="progress">
                    <div className="baseline" />
                    <div className="current" style={{ width: '50%' }} />
                  </div>
                </div>
              </div>
              <div className="bg-light-grey rounded-md  text-center px-6   py-2">
                <p className="font-light  text-xs">Default values are based on property numbers</p>
              </div>
            </div>
            
            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Property Description</h2>
            <p className="font-light  mb-6  ">PropNerd is offering an opportunity to invest in an {data.property.bed}-bedroom apartment in {data.property.city.name}.</p>
            <p className="font-light  mb-6  ">{ data.property.city.propertyDescription }</p>
            <p className="font-light  mb-6 whitespace-pre-line  ">{ data.property.description }</p>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Financials</h2>
            <div className="flex flex-col sm:flex-row  gap-6 mt-6  ">
              <div className="flex flex-1 flex-col gap-4 ">
                <div className="flex justify-between">
                  <p className="font-semibold ">Property Cost</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Property Price</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.price || data.property.agreementPrice)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Transaction Costs</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.transactionFee)}</p>
                </div>
                <div className="flex justify-between border-b  pb-6  ">
                  <p className="font-light ">PropNerd Fee</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.platformFee)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Investment Cost</p>
                  <p className="font-semibold  text-yellow-500 ">{toCurrencyDisplay((data.property.price || data.property.agreementPrice) + data.property.transactionFee + data.property.platformFee)}</p>
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-4 ">
                <div className="flex justify-between">
                  <p className="font-semibold ">Rental income (Year 1)</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Annual gross rent</p>
                  <p className="font-semibold ">{toCurrencyDisplay(data.property.rents[0]?.rent * 12)}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Service Charges</p>
                  <p className="font-semibold ">({toCurrencyDisplay(data.property.financials[0]?.annualServiceCharges)})</p>
                </div>
                <div className="flex justify-between border-b  pb-6  ">
                  <p className="font-light ">Mgmt / Maint.</p>
                  <p className="font-semibold ">({toCurrencyDisplay(data.property.financials[0]?.annualManagementAndMaintenanceCharges)})</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-light ">Annual net income</p>
                  <p className="font-semibold  text-yellow-500 ">{toCurrencyDisplay(
                    (data.property.rents[0]?.rent * 12)
                    - data.property.financials[0]?.annualServiceCharges
                    - data.property.financials[0]?.annualManagementAndMaintenanceCharges
                  )}</p>
                </div>
                <div className="bg-light-grey rounded-md  text-center px-6   py-2">
                  <p className="font-light  text-xs">This is an estimate for the 1st year of ownership</p>
                </div>
              </div>
            </div>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Fundtime timeline</h2>
            <div className="flex">
              <div className="bg-light-grey rounded-md  px-6   py-2">
                <p className="font-light  text-xs">The timeline is an estimate. Actual dates may vary.</p>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-6  ">
              { data.property.timelineEvents.map((event: any, index: number) => (
              <div className="flex flex-col ">
                { index === 0 && (
                <div className="timeline-item current">
                  <div className="circle" />
                </div>
                )}
                <p className="font-light  text-sm ">{format(event.date, 'PPP')}</p>
                <p className="font-semibold ">{event.title}</p>
                <p className="text-sm ">{event.description}</p>
              </div>
              ))}
            </div>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Location</h2>
            <p className="font-semibold  flex items-center mb-6  "><span className="lni lni-map-marker text-yellow-500  mr-1 " /> {data.property.city.name}</p>
            <div className="mb-6  "><img className="rounded-lg" src={ ImgMap } style={{ width: '100%'}} /></div>
            <p className="font-light ">{data.property.city.description}</p>

            <h2 className=" font-sans text-deep-blue  tracking-normal text-2xl mb-4 mt-8 font-bold leading-normal ">Amenities</h2>
            <div className="flex flex-wrap gap-6 ">
              {data.property.amenities && data.property.amenities.map((amenity: any, index: number) => (
              <div className="flex gap-2 ">
                <span className="lni lni-gym" />
                <p>{amenity.title}</p>
              </div>
              ))}
            </div>

            <h2 className="mt-12 font-sans text-deep-blue  tracking-normal text-2xl mb-4  font-bold leading-normal ">Documents</h2>
            <div className="documents flex flex-col gap-4 ">
              {/* <div className="flex justify-between items-center px-6  py-6   border rounded-md ">
                <div className="flex items-center gap-2 ">
                  <span className="lni lni-certificate" />
                  <p>Initial Valuation</p>
                </div>
                <span className="lni lni-download" />
              </div>
              <div className="flex justify-between items-center px-6  py-6   border rounded-md ">
                <div className="flex items-center gap-2 ">
                  <span className="lni lni-certificate" />
                  <p>Investor Memo</p>
                </div>
                <span className="lni lni-download" />
              </div>
              <div className="flex justify-between items-center px-6  py-6   border rounded-md ">
                <div className="flex items-center gap-2 ">
                  <span className="lni lni-certificate" />
                  <p>Projections</p>
                </div>
                <span className="lni lni-download" />
              </div> */}
            </div>

            <h2 className="mt-12 font-sans text-deep-blue  tracking-normal text-2xl mb-4  font-bold leading-normal ">Have more questions about this property?</h2>
            <div className="flex gap-4 ">
              <img src={ImgContactPerson} className="rounded-lg" style={{ width: 100, height: 100 }} />
              <div className="flex flex-col gap-4 ">
                <p>Contact our real estate experts</p>
                <a href="#message" className=" py-6 btn btn-secondary"><i className="lni lni-envelope" />Message Us</a>
              </div>
            </div>

          </div>


        </div>
      </div>
      )}
    </>
  )
}