import { useLazyQuery, useMutation } from '@apollo/client';
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleAction, RoleModule, User, UserStatus } from '../../__generated__/graphql';
import { FormButton } from '../../components/form/button';
import { LoadingPlaceholder } from '../../components/loading/placeholder';
import { Sidebar } from '../../components/sidebar/sidebar';
import { Tab } from '../../components/tab/tab';
import { MUTATION_SEND_UNVERIFIED_REMINDER_EMAIL } from '../../graphql/mutations/admin';
import { MUTATION_UPDATE_INVESTOR } from '../../graphql/mutations/investor';
import { QUERY_GET_USER_LIST } from '../../graphql/queries/user';
import { PaginatedData } from '../../interfaces/pagination.interface';
import { AppRoute } from '../../routes';
import { AuthService } from '../../services/auth.service';
import { useAuthAndErrorHandling } from '../../utils/invalid-token.util';
import { toCurrency } from '../../utils/string.utils';
import { PaginationBar } from '../../components/pagination/pagination.component';

export function InvestorListPage() {
  const tabs = useRef([
    {
      name: 'Active',
      action: RoleAction.ListActive,
    },
    {
      name: 'Unverified',
      action: RoleAction.ListUnverified,
    },
    {
      name: 'Blocked',
      action: RoleAction.ListBlocked,
    },
    {
      name: 'High Balance',
      action: RoleAction.ListHighBalance,
    },
  ]);

  const navigate = useNavigate();

  const [getUsers, { loading, error, data }] = useLazyQuery<{ users: PaginatedData<User> }>(QUERY_GET_USER_LIST, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [updateInvestor, { loading: updateLoading, error: updateError, data: updateData }] = useMutation(MUTATION_UPDATE_INVESTOR, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [sendUnverifiedReminderEmail, { loading: sendingReminderLoading, data: sendingReminderData }] = useMutation(MUTATION_SEND_UNVERIFIED_REMINDER_EMAIL, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [selectedTabItem, setSelectedTabItem] = useState('Active');
  const [selectedRow, setSelectedRow] = useState<number>();
  const [reminderEmailSentForInvestor, setReminderEmailSentForInvestor] = useState<string>();


  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
  }, []);

  // Navigate table using up, down and enter
  useEffect(() => {
    const handleKeyNav = (event: KeyboardEvent) => {
      if (!data) {
        return;
      }

      switch (event.key) {
        case 'Enter':
          if (selectedRow !== undefined && selectedRow >= 0 && selectedRow < data.users.items.length) {
            selectInvestor(data.users.items[selectedRow].id);
          }
          break;
        case 'ArrowUp':
          if (selectedRow !== undefined && selectedRow > 0) {
            setSelectedRow(selectedRow - 1);
          } else {
            setSelectedRow(data.users.items.length - 1);
          }
          break;
        case 'ArrowDown':
          if (selectedRow !== undefined && selectedRow < data.users.items.length - 1) {
            setSelectedRow(selectedRow + 1);
          } else {
            setSelectedRow(0);
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyNav);

    return () => {
      window.removeEventListener('keydown', handleKeyNav);
    };
  }, [data, selectedRow, selectInvestor]);

  useEffect(() => {
    if (updateData) {
      getUsers({ variables: { take: 10, skip: 0, where: { filters: getFiltersForTabItem(selectedTabItem) } } });
    }
  }, [updateData])

  useEffect(() => {
    if (sendingReminderData) {
      setReminderEmailSentForInvestor(sendingReminderData.sendUnverifiedReminderEmail.id);
    }
  }, [sendingReminderData]);

  useEffect(() => {
    getUsers({ variables: { take: 10, skip: 0, where: { filters: getFiltersForTabItem(selectedTabItem) } } });
  }, [selectedTabItem]);

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function getTabList() {
    return tabs.current
      .filter(tab => AuthService.hasRole(RoleModule.Investor, tab.action))
      .map(tab => tab.name);
  }

  function getFiltersForTabItem(tabItem: string) {
    switch (tabItem) {
      case 'Active':
        return [
          { field: 'user_type', operator: '=', value: 'investor' },
          { field: 'status', operator: '=', value: 'active' },
        ]
      case 'Unverified':
        return [
          { field: 'user_type', operator: '=', value: 'investor' },
          { field: 'status', operator: '=', value: 'unverified' },
        ]
      case 'Blocked':
        return [
          { field: 'user_type', operator: '=', value: 'investor' },
          { field: 'status', operator: '=', value: 'blocked' },
        ]
      case 'High Balance':
        return [
          { field: 'user_type', operator: '=', value: 'investor' },
          { field: 'investorProfile.balance', operator: '>', value: "1000000" },
        ]
      default:
        return {};
    }
  }

  function blockOrUnblock(user: User) {
    if (user.status === UserStatus.Unverified) {
      return;
    }
    if (user.status === UserStatus.Blocked) {
      updateInvestor({ variables: { id: user.id, status: UserStatus.Active } });
    } else {
      updateInvestor({ variables: { id: user.id, status: UserStatus.Blocked } });
    }
  }

  function sendReminder(user: User) {
    sendUnverifiedReminderEmail({ variables: { id: user.id } });
  }

  function selectInvestor(id: string) {
    navigate(`${AppRoute.Investor}?id=${id}`);
  }

  function getFullName(user: User) {
    return [user.firstName, user.lastName].join(' ');
  }

  return (
    <>
      <Sidebar />

      <section className='content full properties'>
        <h1 className='mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>Investors</h1>

        <div className="flex flex-col-reverse md:flex-row md:justify-between">
          <Tab items={getTabList()}
            selectedTabItem={selectedTabItem}
            loading={loading}
            handleTabItemClick={handleTabItemClick}
          />
        </div>

        <PaginationBar
          take={10}
          total={data?.users?.total || 0}
          currentTotal={data?.users?.items.length || 0}
          onChange={(take, skip) => {
            getUsers({ variables: { take, skip, where: { filters: getFiltersForTabItem(selectedTabItem) } } });
          }}
        />

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className='px-4 py-3 border-b'>Name</th>
              <th className='px-4 py-3 border-b'>Email</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Balance</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Status</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && data && data.users.items && data.users.items.map((user: User, index: number) => (
              <tr
                key={index}
                className='transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:text-white'
                onClick={() => navigate(`/investor/${user.id}`)}
              >
                <td className='px-4 py-3 text-sm text-nowrap'>{getFullName(user)}</td>
                <td className='px-4 py-3 text-sm'>{user.email}</td>
                <td className='hidden px-4 py-3 text-sm text-right md:table-cell'>
                  {toCurrency(user.investorProfile?.balance)}
                </td>
                <td className='hidden px-4 py-3 text-sm text-center md:table-cell'>{user.status}</td>
                <td className='hidden float-right px-4 py-3 text-sm md:table-cell'>
                  {user.status === UserStatus.Active && AuthService.hasRole(RoleModule.Investor, RoleAction.Block) &&
                    <FormButton title='Block' size='xs' onClick={() => blockOrUnblock(user)} disabled={updateLoading} loading={updateLoading} />}
                  {user.status === UserStatus.Blocked && AuthService.hasRole(RoleModule.Investor, RoleAction.Unblock) &&
                    <FormButton title='Unblock' size='xs' onClick={() => blockOrUnblock(user)} disabled={updateLoading} loading={updateLoading} />}
                  {user.status === UserStatus.Unverified && AuthService.hasRole(RoleModule.Investor, RoleAction.UnverifiedReminderEmail) && reminderEmailSentForInvestor !== user.id &&
                    <FormButton title='Send Reminder' size='xs' onClick={() => sendReminder(user)} disabled={updateLoading} loading={updateLoading} />}
                  {user.status === UserStatus.Unverified && AuthService.hasRole(RoleModule.Investor, RoleAction.UnverifiedReminderEmail) && reminderEmailSentForInvestor === user.id &&
                    <FormButton title='Email Sent!' size='xs' disabled={updateLoading} loading={sendingReminderLoading} />}
                </td>
              </tr>
            ))}
            {loading && <tr><td colSpan={5} className='text-center'><LoadingPlaceholder /></td></tr>}
            {!loading && (!data || !data.users.items || data.users.items.length === 0) && <tr><td colSpan={5} className='text-sm text-center'>
              <div className='flex flex-col items-center gap-2 p-5'>
                <InformationCircleIcon width={60} />
                <p className='font-bold'>No {selectedTabItem} Investors</p>
              </div>
            </td></tr>}
          </tbody>
        </table>
      </section>

    </>
  )
}
