import { gql } from "@apollo/client";

export const MUTATION_UPDATE_INVESTOR = gql`mutation updateInvestor($id: String!, $status: UserStatus) {
  updateInvestor(data: {id: $id, status: $status}) {
    id
  }
}`
export const MUTATION_UPDATE_BALANCE = gql`
  mutation updateBalance($id: String!, $balance: Int!) {
    updateBalance(id: $id, balance: $balance){
      id
      balance
    }
  }
`;