import { HTMLProps, useEffect, useState } from "react";
import { toHumanReadableCurrency } from "../../utils/string.utils";

interface Props extends HTMLProps<HTMLSelectElement> {
  label: string;
  options: { value: string, label: string }[];
  containerClassname?: string;
  isCurrency?: boolean;
}

export function FormSelect({ label, options, containerClassname, className, isCurrency, ...props }: Props) {
  const [value, setValue] = useState(props.defaultValue || '');

  useEffect(() => {
    setValue(props.defaultValue || '');
  }, [props.defaultValue]);

  function getInputClassname() {
    let className = 'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg ';
    className += 'focus:ring-pn-blue focus:border-pn-blue block w-full p-2.5 ';

    if (className) {
      className += className;
    }
    return className;
  }

  function getId() {
    return props.id || label.toLowerCase().replace(' ', '_');
  }

  return (
    <div className={`flex flex-col mb-4 ${containerClassname}`}>
      <label className="text-sm font-semibold mb-2" htmlFor={getId()}>{label}</label>
      <div className="relative">
        <p className="absolute top-3 text-slate-500 right-5 text-sm">{isCurrency ? toHumanReadableCurrency(parseInt(value as string) * 100) : ''}</p>
        <select
          className={getInputClassname()}
          id={getId()}
          value={value}
          {...props}
          onChange={(e) => {
            setValue(e.currentTarget.value);
            props.onChange && props.onChange(e);
          }}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
}
