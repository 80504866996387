import { useNavigate } from "react-router-dom";
import { FormButton } from "../../../components/form/button";
import { AppRoute } from "../../../routes";

interface Props {
  mode: 'add' | 'edit';
  disabled?: boolean;
  disabledText?: string;
}

export function PropertyFormActions({ mode , disabled , disabledText }: Props) {

  const navigate = useNavigate();

  return (
    <div className="flex justify-end gap">
      <FormButton title="Cancel" color="white" onClick={() => navigate(AppRoute.PropertyList)} />
      <FormButton title={mode === 'add' ? 'Add' : (disabled? `${disabledText}` : 'Save')} type="submit" disabled={disabled}  />
    </div>
  )
}