import { gql } from "@apollo/client";

export const QUERY_GET_USER_LIST = gql`query findUsers($take: Int, $skip: Int, $where: WhereInput) {
  users(paginationOptions: {take:$take, skip:$skip}, where:$where) {
    items {
      id
      firstName
      lastName
      email
      investorProfile {
        balance
      }
      adminProfile {
        id
        role {
          id
          name
        }
      }
      status
    }
    total
  }
}`

export const QUERY_GET_USER_BY_ID = gql`
  query getUserById($id: String!) {
    userById(id: $id) {
      id
      firstName
      lastName
      email
      phone
      status
      investorProfile {
        balance
        solanaWalletAddress
      }
    }
  }
`;

export const QUERY_GET_ADMIN_BY_ID = gql`query getUser($id:String!) {
  userById(id:$id) {
    id
    firstName
    lastName
    email
    phone
    status
    adminProfile {
      id
      role {
        name
      }
    }
  }
}`

export const QUERY_MY_ROLE = gql`query getMyRole {
  myRole {
    name
    assignments {
      module
      action
    }
  }
}`




// export const GET_USER_BY_EMAIL = gql`
//   query getUserByEmail($email: String!) {
//     userByEmail(email: $email) {
//       id
//       email
//       firstName
//       lastName
//     }
//   }
// `;