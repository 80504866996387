import { format } from "date-fns";

export function toCurrency(
  value: number,
  thousandSeparator = true,
  currency: string = "£",
  subUnit: number = 100
) {
  if (value === null || value === undefined || isNaN(value)) {
    return "";
  }
  if (thousandSeparator) {
    return `${currency}${(value / subUnit)
      .toFixed(0)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }
  return `${currency}${(value / subUnit).toFixed(0)}`;
}

export function toHumanReadableCurrency(
  value: number,
  currency: string = "£",
  subUnit: number = 100
) {
  if (!value || isNaN(value)) {
    return "";
  }

  value = value / subUnit;

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => value >= item.value);
  return item
    ? `${currency}${(value / item.value)
        .toFixed(2)
        .replace(regexp, "")
        .concat(item.symbol)}`
    : `${currency}0`;
}

export function toCurrencyValue(value?: number, subUnit: number = 100) {
  if (value === 0) {
    return "0";
  }
  if (!value || isNaN(value)) {
    return "";
  }
  return `${(value / subUnit).toFixed(0)}`;
}

export function toCurrencyValueDecimal(
  value?: number,
  subUnit: number = 100,
  decimalPlaces: number = 2
) {
  if (!value || isNaN(value)) {
    return "";
  }
  return `${(value / subUnit).toFixed(decimalPlaces)}`;
}

export function toDateForInput(date: string) {
  if (!date) {
    return "";
  }
  return date.split("T")[0];
}

export function toDateForDisplay(date?: string) {
  if (!date) {
    return "";
  }
  return format(new Date(date), "MMM dd, yy");
}

export function toDateTimeForDisplay(date?: string) {
  if (!date) {
    return "";
  }
  return format(new Date(date), "MMM dd hh:mm a");
}

export function toPercentageDisplay(value: number): string {
  if (value == null || value === undefined || isNaN(value)) {
    return "";
  }
  return `${(value / 100).toFixed(2)}%`;
}
