import React, { useEffect, useState } from 'react';

interface Props {
  currentTotal: number;
  total: number;
  take?: number;
  defaultSkip?: number;
  loading?: boolean;
  onChange: (take: number, skip: number) => void;
}

export function PaginationBar({ currentTotal, total, take = 5, defaultSkip = 0, loading = false, onChange }: Props) {

  const [skip, setSkip] = useState(defaultSkip);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    onChange(take, skip); 
  }, [currentPage])

  const handleNextPage = () => {
    setSkip(skip + take);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (skip > 0) {
      setSkip(skip - take);
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex items-center justify-between mb-2">
      <div>
        Page {currentPage} of {Math.ceil(total / take)} pages
      </div>
      <div>
        {(() => {
          const start = skip + 1;
          const end = Math.min(skip + take, total);

          return `Showing ${start}-${end} of ${total} records`;
        })()}
      </div>
      <div>
        <button onClick={handlePrevPage} disabled={skip === 0} className={`rounded-lg mr-4 text-white py-2 px-4 ${skip !== 0 ? 'bg-[#14223D] hover:bg-[#1d325e]' : 'bg-slate-400'}`}>
          Previous
        </button>
        <button onClick={handleNextPage} disabled={currentTotal < take} className={`rounded-lg mr-4 text-white py-2 px-4 ${currentTotal >= take ? 'bg-[#14223D] hover:bg-[#1d325e]' : 'bg-slate-400'}`}>
          Next
        </button>
      </div>
    </div>
  );
}