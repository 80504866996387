
import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

let isInitialized = false;

export function AnalyticsInvestmentCount() {

  const margin = { top: 40, right: 30, bottom: 60, left: 60 },
    width = 500 - margin.left - margin.right,
    height = 600 - margin.top - margin.bottom;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      init();
    }
  }, [ref]);

  useEffect(() => {
    return () => {
      isInitialized = false;
      if (ref.current) {
        while (ref.current.hasChildNodes()) {
          ref.current.removeChild(ref.current.firstChild!);
        }
      }
    }
  }, [])

  function parseTimestamp(timestamp: string): number {
    // You can use any method to parse the string into a numerical value representing the timestamp
    // Example: Convert string to milliseconds since epoch
    return new Date(timestamp).getTime();
  }

  function init() {

    if (isInitialized) {
      return;
    }
    isInitialized = true;

    const svg = d3.select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const dates = Array.from(new Set(data.map(d => d.timestamp)));

    const cities = Array.from(new Set(data.map(d => d.city)));

    const stack = d3.stack()
      .keys(cities)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);

    const stackData = dates.map(date => {
      const entry: any = { timestamp: date };
      cities.forEach(city => {
        entry[city] = data.find(d => d.timestamp === date && d.city === city)?.investmentCount || 0;
      });
      return entry;
    });

    const series = stack(stackData);

    const x = d3.scaleBand()
      .domain(dates)
      .range([0, width])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([0, d3.max(series[series.length - 1], d => d[1])!])
      .nice()
      .range([height, 0]);

    const color = d3.scaleOrdinal<string>()
      .domain(cities)
      .range(d3.schemeSet2);

    const tooltip = d3.select(ref.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#14223d")
      .style("color", "#ffffff")
      .style("border", "1px solid #cccccc")
      .style("border-radius", "8px")
      .style("padding", "15px 20px")

    svg.append("g")
      .selectAll("g")
      .data(series)
      .join("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data(d => d)
      .join("rect")
      .attr("x", (d, i) => x(d.data.timestamp as unknown as string)!)
      .attr("y", d => y(d[1]))
      .attr("height", d => y(d[0]) - y(d[1]))
      .attr("width", x.bandwidth())
      .on("mouseover", () => {
        tooltip.transition().duration(200).style("opacity", 0.9);
      })
      .on("mousemove", (event: any, d: any) => {
        const tooltipHtml = `Date: ${d.data.timestamp} <br />
          City: ${d.data.city} <br />
          Investments: ${d[1] - d[0]}`;
        tooltip
          .html(tooltipHtml)
          .style("left", event.pageX - 50 + "px")
          .style("top", event.pageY - 180 + "px");
      })
      .on("mouseleave", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });

    svg.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("transform", "rotate(-45)");

    svg.append("g")
      .call(d3.axisLeft(y))
      .append("text")
      .attr("y", -margin.left + 10)
      .attr("x", -height / 2)
      .attr("dy", "0.71em")
      .attr("fill", "#000")
      .attr("transform", "rotate(-90)")
      .attr("text-anchor", "middle")
      .text("Investments");

    const legend = svg.append("g")
      .attr("transform", `translate(${width - 100},20)`);

    legend.selectAll("rect")
      .data(cities)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("y", (d, i) => i * 20)
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", (d) => color(d));

    legend.selectAll("text")
      .data(cities)
      .enter()
      .append("text")
      .attr("x", 15)
      .attr("y", (d, i) => i * 20 + 9)
      .text(d => d)
      .style("font-size", "10px")
      .attr("alignment-baseline", "middle");
  }

  return (
    <div className='rounded-lg shadow-lg bg-white p-5'>
      <h2 className="text-bold text-md">Number of investments by city and time</h2>
      <div className="container mt-3" ref={ref} style={{ width: '100%', height: 600 }}></div>
    </div>
  )
}
const data = [
  { city: 'London', investmentTotal: 200000/4, investmentCount: 20, timestamp: '2024-04-01' },
  { city: 'Manchester', investmentTotal: 10000, investmentCount: 10, timestamp: '2024-04-01' },
  { city: 'Birmingham', investmentTotal: 30000, investmentCount: 30, timestamp: '2024-04-01' },
  { city: 'Liverpool', investmentTotal: 40000, investmentCount: 40, timestamp: '2024-04-01' },
  { city: 'Leeds', investmentTotal: 50000, investmentCount: 50, timestamp: '2024-04-01' },
  { city: 'Glasgow', investmentTotal: 60000, investmentCount: 60, timestamp: '2024-04-01' },
  { city: 'Sheffield', investmentTotal: 70000, investmentCount: 70, timestamp: '2024-04-01' },
  { city: 'Newcastle', investmentTotal: 80000, investmentCount: 80, timestamp: '2024-04-01' },
  { city: 'Bristol', investmentTotal: 90000, investmentCount: 90, timestamp: '2024-04-01' },
  { city: 'Edinburgh', investmentTotal: 100000, investmentCount: 100, timestamp: '2024-04-01' },

  { city: 'London', investmentTotal: 1200000/4, investmentCount: 90, timestamp: '2024-04-02' },
  { city: 'Manchester', investmentTotal: 140000, investmentCount: 100, timestamp: '2024-04-02' },
  { city: 'Birmingham', investmentTotal: 110000, investmentCount: 80, timestamp: '2024-04-02' },
  { city: 'Liverpool', investmentTotal: 100000, investmentCount: 70, timestamp: '2024-04-02' },
  { city: 'Leeds', investmentTotal: 90000, investmentCount: 60, timestamp: '2024-04-02' },
  { city: 'Glasgow', investmentTotal: 80000, investmentCount: 50, timestamp: '2024-04-02' },
  { city: 'Sheffield', investmentTotal: 70000, investmentCount: 40, timestamp: '2024-04-02' },
  { city: 'Newcastle', investmentTotal: 60000, investmentCount: 30, timestamp: '2024-04-02' },
  { city: 'Bristol', investmentTotal: 50000, investmentCount: 20, timestamp: '2024-04-02' },
  { city: 'Edinburgh', investmentTotal: 40000, investmentCount: 10, timestamp: '2024-04-02' },

  { city: 'London', investmentTotal: 900000/4, investmentCount: 90, timestamp: '2024-04-03' },
  { city: 'Manchester', investmentTotal: 100000, investmentCount: 100, timestamp: '2024-04-03' },
  { city: 'Birmingham', investmentTotal: 80000, investmentCount: 80, timestamp: '2024-04-03' },
  { city: 'Liverpool', investmentTotal: 70000, investmentCount: 70, timestamp: '2024-04-03' },
  { city: 'Leeds', investmentTotal: 60000, investmentCount: 60, timestamp: '2024-04-03' },
  { city: 'Glasgow', investmentTotal: 50000, investmentCount: 50, timestamp: '2024-04-03' },
  { city: 'Sheffield', investmentTotal: 40000, investmentCount: 40, timestamp: '2024-04-03' },
  { city: 'Newcastle', investmentTotal: 30000, investmentCount: 30, timestamp: '2024-04-03' },
  { city: 'Bristol', investmentTotal: 20000, investmentCount: 20, timestamp: '2024-04-03' },
  { city: 'Edinburgh', investmentTotal: 10000, investmentCount: 10, timestamp: '2024-04-03' },

  { city: 'London', investmentTotal: 200000/4, investmentCount: 20, timestamp: '2024-04-04' },
  { city: 'Manchester', investmentTotal: 10000, investmentCount: 10, timestamp: '2024-04-04' },
  { city: 'Birmingham', investmentTotal: 30000, investmentCount: 30, timestamp: '2024-04-04' },
  { city: 'Liverpool', investmentTotal: 40000, investmentCount: 40, timestamp: '2024-04-04' },
  { city: 'Leeds', investmentTotal: 50000, investmentCount: 50, timestamp: '2024-04-04' },
  { city: 'Glasgow', investmentTotal: 60000, investmentCount: 60, timestamp: '2024-04-04' },
  { city: 'Sheffield', investmentTotal: 70000, investmentCount: 70, timestamp: '2024-04-04' },
  { city: 'Newcastle', investmentTotal: 80000, investmentCount: 80, timestamp: '2024-04-04' },
  { city: 'Bristol', investmentTotal: 90000, investmentCount: 90, timestamp: '2024-04-04' },
  { city: 'Edinburgh', investmentTotal: 100000, investmentCount: 100, timestamp: '2024-04-04' },

  { city: 'London', investmentTotal: 1200000/4, investmentCount: 90, timestamp: '2024-04-05' },
  { city: 'Manchester', investmentTotal: 140000, investmentCount: 100, timestamp: '2024-04-05' },
  { city: 'Birmingham', investmentTotal: 110000, investmentCount: 80, timestamp: '2024-04-05' },
  { city: 'Liverpool', investmentTotal: 100000, investmentCount: 70, timestamp: '2024-04-05' },
  { city: 'Leeds', investmentTotal: 90000, investmentCount: 60, timestamp: '2024-04-05' },
  { city: 'Glasgow', investmentTotal: 80000, investmentCount: 50, timestamp: '2024-04-05' },
  { city: 'Sheffield', investmentTotal: 70000, investmentCount: 40, timestamp: '2024-04-05' },
  { city: 'Newcastle', investmentTotal: 60000, investmentCount: 30, timestamp: '2024-04-05' },
  { city: 'Bristol', investmentTotal: 50000, investmentCount: 20, timestamp: '2024-04-05' },
  { city: 'Edinburgh', investmentTotal: 40000, investmentCount: 10, timestamp: '2024-04-05' },

  { city: 'London', investmentTotal: 900000/4, investmentCount: 90, timestamp: '2024-04-06' },
  { city: 'Manchester', investmentTotal: 100000, investmentCount: 100, timestamp: '2024-04-06' },
  { city: 'Birmingham', investmentTotal: 80000, investmentCount: 80, timestamp: '2024-04-06' },
  { city: 'Liverpool', investmentTotal: 70000, investmentCount: 70, timestamp: '2024-04-06' },
  { city: 'Leeds', investmentTotal: 60000, investmentCount: 60, timestamp: '2024-04-06' },
  { city: 'Glasgow', investmentTotal: 50000, investmentCount: 50, timestamp: '2024-04-06' },
  { city: 'Sheffield', investmentTotal: 40000, investmentCount: 40, timestamp: '2024-04-06' },
  { city: 'Newcastle', investmentTotal: 30000, investmentCount: 30, timestamp: '2024-04-06' },
  { city: 'Bristol', investmentTotal: 20000, investmentCount: 20, timestamp: '2024-04-06' },
  { city: 'Edinburgh', investmentTotal: 10000, investmentCount: 10, timestamp: '2024-04-06' },

  { city: 'London', investmentTotal: 200000/4, investmentCount: 20, timestamp: '2024-04-07' },
  { city: 'Manchester', investmentTotal: 10000, investmentCount: 10, timestamp: '2024-04-07' },
  { city: 'Birmingham', investmentTotal: 30000, investmentCount: 30, timestamp: '2024-04-07' },
  { city: 'Liverpool', investmentTotal: 40000, investmentCount: 40, timestamp: '2024-04-07' },
  { city: 'Leeds', investmentTotal: 50000, investmentCount: 50, timestamp: '2024-04-07' },
  { city: 'Glasgow', investmentTotal: 60000, investmentCount: 60, timestamp: '2024-04-07' },
  { city: 'Sheffield', investmentTotal: 70000, investmentCount: 70, timestamp: '2024-04-07' },
  { city: 'Newcastle', investmentTotal: 80000, investmentCount: 80, timestamp: '2024-04-07' },
  { city: 'Bristol', investmentTotal: 90000, investmentCount: 90, timestamp: '2024-04-07' },
  { city: 'Edinburgh', investmentTotal: 100000, investmentCount: 100, timestamp: '2024-04-07' },
];