import { useNavigate } from "react-router-dom";
import { FormButton } from "../../../components/form/button";
import { AppRoute } from "../../../routes";

interface Props {
  mode: 'add' | 'edit';
}

export function RoleFormActions({ mode }: Props) {

  const navigate = useNavigate();

  return (
    <div className="flex justify-end gap">
      <FormButton title="Cancel" color="white" onClick={() => navigate(AppRoute.RoleList)} />
      <FormButton title={mode === 'add' ? 'Add' : 'Save'} type="submit" />
    </div>
  )
}