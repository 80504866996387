import { gql } from "@apollo/client";

export const MUTATION_ADD_PROPERTY_TYPE = gql`
  mutation addPropertyType($name: String!) {
    addPropertyType( name: $name ) {
      id
      name
    }
  }
`;

export const MUTATION_UPDATE_PROPERTY_TYPE = gql`
  mutation updatePropertyType($id: String!, $name: String!) {
    updatePropertyType( id: $id, name: $name ) {
      id
      name
    }
  }
`;

export const MUTATION_DELETE_PROPERTY_TYPE = gql`
  mutation deletePropertyType($id: String!) {
    deletePropertyType(id: $id) {
      id
    }
  }
`;
