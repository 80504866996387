import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../components/sidebar/sidebar';
import { Tab } from '../../components/tab/tab';
import { Headline } from '../../components/text/headline';
import { FormTextArea } from '../../components/form/textarea';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MUTATION_SAVE_OR_UPDATE_CMS_VALUE } from '../../graphql/mutations/cms';
import { QUERY_GET_CMS_VALUES } from '../../graphql/queries/cms';
import { AuthService } from '../../services/auth.service';

interface Value {
  name: string;
  key: string;
  value: string | number;
  section: string;
  page: string;
  language: string;
  page_order?: number;
  value_order?: number;
  section_order?: number;
}

export function CmsPage() {
  const navigate = useNavigate();
  const [selectedTabItem, setSelectedTabItem] = useState('English');
  const [selectedPage, setSelectedPage] = useState('home');
  const [selectedSection, setSelectedSection] = useState('');
  const [values, setValues] = useState<Value[]>([]);
  const [UpdateValues, { loading }] = useMutation(MUTATION_SAVE_OR_UPDATE_CMS_VALUE);
  const [getValues, { data: getValuesData, loading: getLoading, error: getError }] = useLazyQuery(QUERY_GET_CMS_VALUES);

  const ITEMS = useRef([
    { name: 'Home Marketplace', icon: 'lni lni-shopping-basket', key: 'homeMarketplace' },
    { name: 'Home Join', icon: 'lni lni-users', key: 'homeJoin' },
    { name: 'Home Investment', icon: 'lni lni-investment', key: 'homeInvestment' },
    { name: 'About', icon: 'lni lni-information', key: 'about' },
    { name: 'Home Download App', icon: 'lni lni-download', key: 'homeDownloadApp' },
    { name: 'Home Coming Soon', icon: 'lni lni-agenda', key: 'homeCommingSoon' },
    { name: 'Home Calculator', icon: 'lni lni-calculator', key: 'homeCalculator' },
    { name: 'Home', icon: 'lni lni-home', key: 'home' },
    { name: 'Terms', icon: 'lni lni-files', key: 'terms' },
    { name: 'Welcome', icon: 'lni lni-hand', key: 'welcome' },
    { name: 'Submit', icon: 'lni lni-upload', key: 'submit' },
    { name: 'Services', icon: 'lni lni-service', key: 'services' },
    { name: 'Risks', icon: 'lni lni-warning', key: 'risks' },
    { name: 'Home Hero', icon: 'lni lni-home', key: 'homeHero' },
    { name: 'Home Growth', icon: 'lni lni-stats-up', key: 'homeGrowth' },
    { name: 'Home Get Started', icon: 'lni lni-play', key: 'homeGetStarted' },
    { name: 'Property', icon: 'lni lni-apartment', key: 'property' },
    { name: 'Privacy', icon: 'lni lni-lock', key: 'privacy' },
    { name: 'Marketplace', icon: 'lni lni-shopping-basket', key: 'marketplace' },
    { name: 'How', icon: 'lni lni-question-circle', key: 'how' },
    { name: 'FAQ', icon: 'lni lni-help', key: 'faq' },
    { name: 'Error 404', icon: 'lni lni-close', key: 'error404' },
    { name: 'Account Verify', icon: 'lni lni-checkmark-circle', key: 'accountVerify' },
    { name: 'Account Register', icon: 'lni lni-user', key: 'accountRegister' },
    { name: 'Account Login', icon: 'lni lni-key', key: 'accountLogin' },
    { name: 'Qualified Investor Page' , icon: 'lni lni-user', key: 'QualifiedInvestorPage' }

  ]);
  
  const tabs = useRef([
    { name: 'English', key: 'en' },
    { name: 'Español', key: 'es' },
  ]);

 
  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
  },[]);

  useEffect(() => {
    getValues(); // Fetch data from the server when the component loads
  }, [getValues]);

  useEffect(() => {
    if (getValuesData) {
      //console.log('getValuesData', getValuesData.getCMSValues);
      const filteredValues = getValuesData.getCMSValues.filter(
        (item: any) =>
          item.language.key === (selectedTabItem === "English" ? 'en' : 'es') &&
          item.page.name === selectedPage &&
          (!selectedSection || item.section.name === selectedSection)
      );
      setValues(filteredValues);
    }
  }, [getValuesData, selectedTabItem, selectedPage, selectedSection]);

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  function handlePageClick(pageKey: string) {
    setSelectedPage(pageKey);
    setSelectedSection(''); // Reset section when the page changes
  }

  function handleSectionClick(sectionKey: string) {
    setSelectedSection(sectionKey);
  }

  const sections = getValuesData
  ? getValuesData.getCMSValues.reduce((acc: string[], value: any): string[] => {
      if (
        value.page.name === selectedPage &&
        value.language.key === (selectedTabItem === 'English' ? 'en' : 'es') &&
        !acc.includes(value.section.name)
      ) {
        acc.push(value.section.name);
      }
      return acc;
    }, [])
  : [];


  const handleSave = async () => {
    const formData = new FormData(document.querySelector('form')!);
    const updatedValues = values.map((item) => ({
      key: item.key,
      name: item.name,
      value: formData.get(item.key) as string,
      order: item.value_order ?? 0, // Providing a default value if order is missing
      sectionName: selectedSection,
      pageName: selectedPage,
      languageName: selectedTabItem,
    }));
    
    try {
      if(selectedSection !== '' ) {
        await UpdateValues({
          variables: {
            input: updatedValues,
          },
        });
      }else{
        alert("Please select a section to save the values");
      }
      
      console.log("Values updated successfully");
    } catch (error) {
      console.error("Error updating values:", error);
    }
  };
  

  return (
    <>
      <Sidebar />
      <div className='cms-sidebar-container'>
        <nav className='cms-sidebar box-shadow bkg-white border-right' style={{ height: '100vh' }}>
          <div className='top-menu border-bottom'>
            {ITEMS.current.map((item, index) => (
              <a
                key={index}
                className={`flex text-grey border-radius ${selectedPage === item.key ? 'selected' : ''}`}
                onClick={() => handlePageClick(item.key)}
                href={`#${item.key}`}
              >
                <i className={item.icon + ' text-lg'} /> {item.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <section className='content ml-0 w-full '>
        <Headline className='headline'>
          {ITEMS.current.find(item => item.key === selectedPage)?.name || 'Default Title'}
        </Headline>

        <div className='flex justify-between'>
          <Tab
            items={tabs.current.map(tab => tab.name)}
            selectedTabItem={selectedTabItem}
            loading={false}
            handleTabItemClick={handleTabItemClick}
          />
          <button className='btn btn-primary ' type='button' onClick={handleSave} disabled={loading}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
        <form>
          {values.map((item) => (
            <div key={item.key} className='form-group'>
              <FormTextArea
                label={item.name}
                name={item.key}
                defaultValue={item.value}
                rows={3}
              />
            </div>
          ))}
        </form>
      </section>

      <div className='ml-0 cms-sidebar-container'>
        <nav className='ml-0 cms-sidebar box-shadow bkg-white border-right' style={{ height: '100vh' }}>
          <div className='top-menu border-bottom'>
            {sections.map((section: string, index: number) => (
              <a
                key={index}
                className={`flex text-grey border-radius ${selectedSection === section ? 'selected' : ''}`}
                onClick={() => handleSectionClick(section)}
                href={`#${section}`}
              >
                {section === "" ? 'All sections' : section}
              </a>
            ))}
          </div>
        </nav>
      </div>
    </>
  );
}
