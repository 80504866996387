import { ButtonHTMLAttributes } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export function CloseButton({ className, ...props }: Props) {

  function getClassname() {
    let classname = "text-gray-400 bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm justify-center items-center";
    if (className) {
      classname += ` ${className}`;
    }
    return classname;
  }

  return (
    <button {...props} type="button" className={getClassname()}>
      <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
      </svg>
    </button>
  )
}