import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { Property, PropertyFinancials } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_SOFT_DELETE_PROPERTY} from "../../../graphql/mutations/property";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../../routes";

interface Props {
  property?: Property;
  financial?: PropertyFinancials;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function PropertyDeleteModal({ property, show, onShowHide }: Props) {


  const [errorMessage, setErrorMessage] = useState<string>();
  const [softDeleteProperty, { data: deleteData, loading: deleting, error: deleteError }] = useMutation(MUTATION_SOFT_DELETE_PROPERTY, { errorPolicy: 'all' });
  const navigate = useNavigate();



  function close() {
    onShowHide && onShowHide(false)
  }

  function submit(e: FormEvent) {
    e.preventDefault();
    if (property && property.id) {
      softDeleteProperty({ variables: { id: property.id } });
    }
    onShowHide && onShowHide(false)
    navigate(AppRoute.PropertyList) ;
    setErrorMessage(undefined);

  }

  function getActionElement() {
    return (
      <div className="flex justify-center  content-center gap-2">
        <FormButton title="Cancel" type="button" color="white" onClick={close}/>
        <FormButton title={'Confirm'} type="submit" loading={deleting} disabled={deleting} />
      </div>
    )
  }

  return show ?
    <Modal title={'Delete'} onClose={close} actionElement={getActionElement()} onSubmit={submit} errorMessage={errorMessage}>
      <h2 className="text-xl text-center font-bold">Are you sure you want to delete this property?</h2>
    </Modal>

    : null

}