import { Property } from "../../../__generated__/graphql";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";

interface Props {
  property?: Property;
}

export function PropertyFormAreaSection({ property }: Props) {
  return (
    <FormSection title="Area" description="Size and attributes of the property">
      <div className="flex flex-col md:flex-row md:gap-3">
        <FormInput label="Beds" name="bed" type="number" required defaultValue={property?.bed} />
        <FormInput label="Baths" name="bath" type="number" required defaultValue={property?.bath} />
        <FormInput label="Square Footage" name="sqFootage" type="number" step={1} required defaultValue={property?.sqFootage} />
      </div>
    </FormSection>
  )
}
