import { gql } from "@apollo/client";

export const GET_PLEDGES = gql`
  query getPropertyPledgeById($take: Int, $skip: Int, $id: String) {
    getPropertyPledgeById(take: $take, skip: $skip, id: $id) {
      amount
      createdAt
      updatedAt
      property {
        title
      }
      status
    }
  }
`;
