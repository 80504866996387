import { gql } from "@apollo/client";

export const GET_TRANSACTION = gql`
  query getTransactionById($take: Int, $skip: Int, $id: String) {
    getTransactionById(take: $take, skip: $skip, id: $id) {
      type
      amount
      code
      createdAt
      updatedAt
      property {
        title
      }
      status
    }
  }
`;

export const QUERY_GET_DEPOSIT_LIST = gql`query deposits($take: Int, $skip: Int) {
  deposits(paginationOptions: {take:$take, skip:$skip}) {
    items {
      id
      code
      amount
      source
      ccFee
      stripeFee
      initiatedBy
      user {
        id
        firstName
        lastName
        email
        investorProfile {
          id
          balance
        }
      }
      updatedAt
    }
    total
  }
}`

export const QUERY_GET_INVESTMENT_LIST = gql`query investments($take: Int, $skip: Int) {
  investments(paginationOptions: {take:$take, skip:$skip}) {
    items {
      id
      amount
      user {
        id
        firstName
        lastName
        email
        investorProfile {
          id
          balance
        }
      }
      property {
        id
        title
      }
      updatedAt
    }
    total
  }
}`