import { useEffect, useState } from "react";
import { Property, PropertyTimeline } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { toDateForDisplay } from "../../../utils/string.utils";
import { PropertyTimelineModal } from "./timeline-modal";

interface Props {
  property?: Property;
  mode: 'add' | 'edit';
  refresh: () => void;
}

export function PropertyTimelineSection({ property, refresh, mode }: Props) {

  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<PropertyTimeline>();

  useEffect(() => {
    if (!showModal) {
      refresh && refresh();
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedEvent) {
      setShowModal(true);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (!showModal) {
      setSelectedEvent(undefined);
    }
  }, [showModal])

  function getItemClassname(index: number) {
    if (mode === 'add' || !property) return '';
    let className = 'w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-pn-dark-yellow transition-all duration-300';
    if (index === 0) {
      className = `${className} rounded-t-lg`;
    } else if (index === property?.financials.length - 1) {
      className = `${className} rounded-b-lg`;
    }
    return className;
  }

  return (
    <>
      <PropertyTimelineModal property={property} timelineEvent={selectedEvent} show={showModal} onShowHide={setShowModal} />
      <div className="shadow bg-white border rounded-lg p-4">
        <h2 className="font-semibold text-lg mb-2">Timeline</h2>
        {mode === 'add' && <p className="text-sm text-slate-400 mb-4">Funding timeline events can be added after the property is created</p>}
        {mode === 'edit' && property && property.timelineEvents && (
          <ul className="bg-white border border-gray-200 rounded-lg mb-3">
            {property?.timelineEvents.map((event, index) => (
              <li key={index} className={getItemClassname(index)}
                onClick={() => setSelectedEvent(event)}>
                  <h3 className="text-sm font-medium text-gray-900">{toDateForDisplay(event.date)}</h3>
                  <p className="text-xs font-light text-gray-700">{event.title}</p>
                </li>
            ))}
          </ul>
        )}
        <FormButton title="Add Event" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  )
}