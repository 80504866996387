import { useEffect, useState } from "react";
import { Role, RoleAction, RoleAssignment, RoleModule } from "../../../__generated__/graphql";
import { FormSection } from "../../../components/form/section";
import { ROLE_ASSIGNMENTS } from "../assignments";

interface Props {
  role?: Role;
}

export function RoleFormAssignmentSection({ role }: Props) {

  const [assignments, setAssignments] = useState<RoleAssignment[]>([]);

  useEffect(() => {
    if (role) {
      setAssignments(role.assignments);
    }
  }, [role]);

  function onAssignmentChange(checked: boolean, module: RoleModule, action: RoleAction) {
    if (checked) {
      setAssignments([...assignments, { module, action } as RoleAssignment]);
    } else {
      setAssignments(assignments.filter((a) => a.module !== module || a.action !== action));
    }
  }

  return (
    <>
      { ROLE_ASSIGNMENTS.map((assignment) => (
        <FormSection key={ assignment.module } title={ assignment.module } description={ assignment.description }>
          <ul className="space-y-1 text-sm text-gray-700" aria-labelledby="dropdownHelperRadioButton">
            { assignment.actions.map((action) => (
              <li key={ action.name }>
                <div className="flex px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-100">
                  <div className="flex items-center h-5">
                    <input
                      id={ `${assignment.module}-${action.name}` }
                      name={ `${assignment.module}-${action.name}` }
                      type="checkbox"
                      defaultChecked={ role?.assignments.some((a) => a.module === assignment.module && a.action === action.name) }
                      className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      onChange={ e => onAssignmentChange(e.target.checked, assignment.module, action.name) }
                    />
                  </div>
                  <div className="ms-2 text-sm">
                    <label htmlFor={ `${assignment.module}-${action.name}` } className="cursor-pointer font-medium text-gray-900">
                      <div>{ action.name }</div>
                      <p id="helper-radio-text-4" className="text-xs font-normal text-gray-500">{ action.description }</p>
                    </label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </FormSection>
    ))}
    </>
  )
}