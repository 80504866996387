import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { Property, PropertyFinancials } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_ADD_FINANCIAL, MUTATION_UPDATE_FINANCIAL } from "../../../graphql/mutations/finanicial";
import { toCurrencyValue, toDateForInput ,toCurrencyValueDecimal } from "../../../utils/string.utils";

interface Props {
  property?: Property;
  financial?: PropertyFinancials;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function PropertyFinancialModal({ property, financial, show, onShowHide }: Props) {

  const [addFinancial, { data: addData, loading: adding, error: addError }] = useMutation(MUTATION_ADD_FINANCIAL, { errorPolicy: 'all' })
  const [updateFinancial, { data: updateData, loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_FINANCIAL, { errorPolicy: 'all' })

  const [mode, setMode] = useState<'add' | 'update'>('add');
  const [financials, setFinancials] = useState<PropertyFinancials[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (property && property.financials) {
      setFinancials(property.financials);
    } else {
      setFinancials([]);
    }
  }, [property]);

  useEffect(() => {
    if (addData || updateData) {
      onShowHide && onShowHide(false);
    }
  }, [addData, updateData]);

  useEffect(() => {
    if (addError || updateError) {
      setErrorMessage(addError?.message || updateError?.message);
    }
  }, [addError, updateError]);

  useEffect(() => {
    if (financial) {
      setMode('update');
    } else {
      setMode('add');
    }
  }, [financial]);

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Cancel" type="button" color="white" onClick={close}/>
        <FormButton title={adding || updating ? 'Saving...' : 'Save'} type="submit" loading={adding || updating} disabled={adding || updating} />
      </div>
    )
  }

  function close() {
    onShowHide && onShowHide(false)
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    if (!property) {
      return;
    }

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const data: Partial<PropertyFinancials> & { propertyId: string }= {
      propertyId: property?.id,
      projectedAnnualRoIPercentage: Math.floor(parseFloat(formData.get('projectedAnnualRoIPercentage') as string) * 100),
      projectedAnnualRoIValue: Math.floor(parseFloat(formData.get('projectedAnnualRoIValue') as string) * 100),
      year2ProjectedAnnualRoIPercentage: Math.floor(parseFloat(formData.get('year2ProjectedAnnualRoIPercentage') as string) * 100),
      year2ProjectedAnnualRoIValue: Math.floor(parseFloat(formData.get('year2ProjectedAnnualRoIValue') as string) * 100),
      year3ProjectedAnnualRoIPercentage: Math.floor(parseFloat(formData.get('year3ProjectedAnnualRoIPercentage') as string) * 100),
      year3ProjectedAnnualRoIValue: Math.floor(parseFloat(formData.get('year3ProjectedAnnualRoIValue') as string) * 100),
      annualRent: Math.floor(parseFloat(formData.get('annualRent') as string) * 100),
      effectiveDate: formData.get('effectiveDate') as string,
    }

    if (mode === 'update' && financial) {
      data.id = financial.id;
      updateFinancial({ variables: data });
    } else {
      console.log(data);
      addFinancial({ variables: data });
    }

    setErrorMessage(undefined);
  }

  return show ?
    <Modal title={(mode === 'add' ? 'Add' : 'Update') + ' Property Financial'} onClose={close} actionElement={getActionElement()} onSubmit={submit} errorMessage={errorMessage}>
      <div className="grid grid-cols-2 gap-4">
        <FormInput name="projectedAnnualRoIPercentage" label="Annual ROI (%)" type="number" max={100} step={0.01} required defaultValue={toCurrencyValueDecimal(financial?.projectedAnnualRoIPercentage)} />
        <FormInput name="projectedAnnualRoIValue" label="Annual ROI (£)" type="number" isCurrency required defaultValue={toCurrencyValue(financial?.projectedAnnualRoIValue)} />
        <FormInput name="year2ProjectedAnnualRoIPercentage" label="2 Year ROI (%)" type="number" max={100} step={0.01} required defaultValue={toCurrencyValueDecimal(financial?.year2ProjectedAnnualRoIPercentage)} />
        <FormInput name="year2ProjectedAnnualRoIValue" label="2 Year ROI (£)" type="number" isCurrency required defaultValue={toCurrencyValue(financial?.year2ProjectedAnnualRoIValue)} />
        <FormInput name="year3ProjectedAnnualRoIPercentage" label="3 Year ROI (%)" type="number" max={100} step={0.01} required defaultValue={toCurrencyValueDecimal(financial?.year3ProjectedAnnualRoIPercentage)} />
        <FormInput name="year3ProjectedAnnualRoIValue" label="3 Year ROI (£)" type="number" isCurrency required defaultValue={toCurrencyValue(financial?.year3ProjectedAnnualRoIValue)} />
        <FormInput name="annualRent" label="Annual Rent (£)" type="number" isCurrency required defaultValue={toCurrencyValue(financial?.annualRent)} />
        <FormInput name="effectiveDate" label="Effective Date" type="date" required defaultValue={toDateForInput(financial?.effectiveDate)} />
      </div>
    </Modal>

    : null

}