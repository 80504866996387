import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InvestorLead } from '../../__generated__/graphql';
import { LoadingPlaceholder } from '../../components/loading/placeholder';
import { Sidebar } from '../../components/sidebar/sidebar';
import { QUERY_GET_LEAD_LIST } from '../../graphql/queries/leads';
import { AuthService } from '../../services/auth.service';
import { toDateForDisplay } from '../../utils/string.utils';
import { LinkGeneratorModal } from '../properties/components/link_generator_modal';
import { EmailModal } from './email-modal';
import { Intercom, showConversation } from '@intercom/messenger-js-sdk';
import { useAuthAndErrorHandling } from '../../utils/invalid-token.util';
import { MUTATION_SOFT_DELETE_LEAD } from '../../graphql/mutations/lead';

export function LeadListPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  //const [getLeads, { loading, error, data }] = useLazyQuery(QUERY_GET_LEAD_LIST, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [selectedConversationId, setSelectedConversationId] = useState<string | null>(null);
  const [take] = useState(6); // Items per page
  const [skip, setSkip] = useState(0); // Current offset
  const [currentPage, setCurrentPage] = useState(1);
  const [getLeads, { loading, error, data }] = useLazyQuery(QUERY_GET_LEAD_LIST, {
    variables: { take, skip },
    fetchPolicy: 'no-cache',
  });
  const [getAllLeads, { loading: loadingAllLeads, error: errorAllLeads, data: dataAllLeads }] = useLazyQuery(QUERY_GET_LEAD_LIST, {
    variables: { take: 10000, skip: 0 },
    fetchPolicy: 'no-cache',
  });

  const [deleteLead, { data: deleteLeadData, loading: deleteLeadLoading, error: deleteLeadError }] = useMutation(MUTATION_SOFT_DELETE_LEAD);


  useAuthAndErrorHandling(error);


  useEffect(() => {
    getLeads();
  }, [skip]);

  const handleNextPage = () => {
    setSkip(skip + take);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (skip > 0) {
      setSkip(skip - take);
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
    getLeads();
  }, []);

  useEffect(() => {
    if (deleteLeadData) {
      getLeads();
    }
  }, [deleteLeadData]);

  useEffect(() => {
    if (dataAllLeads && dataAllLeads.getInterestedInvestors) {
      exportToCSV();
    }
  }, [dataAllLeads]);

  const handleChatClick = (email: string, convId: string) => {
    setSelectedEmail(email);
    setShowEmailModal(true);
    if (convId) {
      setSelectedConversationId(convId);
    }
  };
  const handleDeleteClick = (email: string) => {
    if (email) {
      // Delete the lead
      deleteLead({ variables: { email } });
    }
  }

  const handleEmailSent = () => {
    getLeads();
  };

  const exportToCSV = () => {
    if (!dataAllLeads || !dataAllLeads.getInterestedInvestors.investors) return;

    const leads = dataAllLeads.getInterestedInvestors.investors;

    const csvHeaders = [
      'Name',
      'Email',
      'Phone Number',
      'Added on',
      'Referrer ID',
      'Campaign',
      'Medium',
      'Source',
      'Content',
      'Term',
    ];

    const csvRows = leads.map((lead: InvestorLead) => [
      lead.fullName,
      lead.email,
      lead.phoneNumber,
      new Date(lead.createdAt).toISOString().split('T')[0], // Format date as YYYY-MM-DD
      lead.utm_id || '',
      lead.utm_campaign || '',
      lead.utm_medium || '',
      lead.utm_source || '',
      lead.utm_content || '',
      lead.utm_term || '',
    ]);

    const csvContent = [
      csvHeaders.join(','),
      ...csvRows.map((row: any) => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', 'lead_list.csv');
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  return (
    <>
      <Sidebar />

      <section className='content full properties'>
        <div className='flex justify-between'>
          <h1 className='mb-4 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>Leads</h1>

        </div>
        <div className='flex flex-wrap justify-end mb-4'>
          <button className="py-4 mr-2 btn btn-secondary" onClick={() => setShowModal(true)}>Generate Link</button>
          <button className="py-4 btn btn-secondary" disabled={loadingAllLeads} onClick={() => { getAllLeads() }}>{!loadingAllLeads ? 'Export' : 'Exporting...'}</button>
        </div>
        {showModal && <LinkGeneratorModal show={showModal} onShowHide={setShowModal} />}

        <div className="flex m-4 ">
          <button onClick={handlePrevPage} disabled={skip === 0} className=" bg-[#14223D] rounded-lg mr-4 hover:bg-[#1d325e] text-white py-2 px-4 ">
            Previous
          </button>
          <button onClick={handleNextPage} disabled={!data || data.getInterestedInvestors.investors.length < take} className="bg-[#14223D] rounded-lg hover:bg-[#1d325e] text-white py-2 px-4 ">
            Next
          </button>
        </div>
        {/* Pagination Information */
          !loading && data && data.getInterestedInvestors.investors &&
          <div className="flex items-center justify-between m-4">
            <div>
              Page {currentPage} of {Math.ceil(data.getInterestedInvestors.total / take)} pages
            </div>
            <div>
              {(() => {
                const startingValue = skip + 1;
                const endingValue = Math.min(skip + take, data.getInterestedInvestors.total);
                const totalLeadsPresent = data.getInterestedInvestors.total;

                return `Showing ${startingValue}-${endingValue} of ${totalLeadsPresent} leads`;
              })()}
            </div>
          </div>
        }

        <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
          <thead>
            <tr>
              <th className='px-4 py-3 border-b'>Person</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Added on</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Referrer ID</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Campaign</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Medium</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Source</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Content</th>
              <th className='hidden px-4 py-3 border-b md:table-cell'>Term</th>
              <th className='px-4 py-3 border-b'>Chat</th>
              <th className='px-4 py-3 border-b'>Delete</th>

            </tr>
          </thead>
          <tbody>
            {!loading && data && data.getInterestedInvestors && data.getInterestedInvestors.investors.map((lead: InvestorLead, index: number) => (
              <tr key={index}>
                <td className='flex flex-col px-4 py-3 text-sm'>
                  <div className='font-bold'>{lead.fullName}</div>
                  <div className='text-[0.875rem]'>{lead.email}</div>
                  <div className='text-[0.875rem]'>{lead.phoneNumber}</div>
                </td>
                <td className='hidden px-4 py-3 text-sm md:table-cell'>{toDateForDisplay(lead.createdAt)}</td>
                <td className='hidden px-4 py-3 text-sm md:table-cell'>{lead.utm_id}</td>
                <td className='hidden px-4 py-3 text-sm md:table-cell'>{lead.utm_campaign}</td>
                <td className='hidden px-4 py-3 text-sm md:table-cell'>{lead.utm_medium}</td>
                <td className='hidden px-4 py-3 text-sm md:table-cell'>{lead.utm_source}</td>
                <td className='hidden px-4 py-3 text-sm md:table-cell'>{lead.utm_content}</td>
                <td className='hidden px-4 py-3 text-sm md:table-cell'>{lead.utm_term}</td>
                <td className='px-4 py-3 text-sm'>
                  <button className='text-3xl' onClick={() => handleChatClick(lead.email, lead.intercomLeadConversationId!)}>
                    <i className="lni lni-bubble"></i>
                  </button>
                </td>
                <td className='px-4 py-3 text-sm'>
                  <button className='btn ' onClick={() => handleDeleteClick(lead.email)} disabled={deleteLeadLoading} >
                    {deleteLeadLoading ? "Deleting" : "Delete"}
                  </button>
                </td>
              </tr>
            ))}
            {loading && <tr><td colSpan={9} className='text-center'><LoadingPlaceholder /></td></tr>}
          </tbody>
        </table>

        {selectedEmail && (
          <EmailModal
            conversationId={selectedConversationId}
            email={selectedEmail}
            show={showEmailModal}
            onShowHide={setShowEmailModal}
            onEmailSent={handleEmailSent} // Pass the callback function
          />
        )}



      </section>
    </>
  );
}
