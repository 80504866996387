import { FormEvent, useEffect, useState } from "react";
import { FormButton } from "../../../components/form/button";
import { FormTextArea } from "../../../components/form/textarea";
import { Modal } from "../../../components/modal/modal";
import { Property, PropertyAmenity } from "../../../__generated__/graphql";
import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_AMENITIES } from "../../../graphql/mutations/amenities";

interface Props {
  property?: Property;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function PropertyAmenitiesModal({ property, show, onShowHide }: Props) {

  const [updateAmenities, { data: updateData, loading: saving, error: updateError }] = useMutation(MUTATION_UPDATE_AMENITIES, { errorPolicy: 'all' })

  const [amenities, setAmenities] = useState<PropertyAmenity[]>([]);

  useEffect(() => {
    if (property && property.amenities) {
      setAmenities(property.amenities);
    } else {
      setAmenities([]);
    }
  }, [property]);

  useEffect(() => {
    if (updateData) {
      onShowHide && onShowHide(false);
    }
  }, [updateData]);

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Cancel" type="button" color="white" onClick={close}/>
        <FormButton title={saving ? 'Saving...' : 'Save'} type="submit" loading={saving} disabled={saving} />
      </div>
    )
  }

  function close() {
    onShowHide && onShowHide(false)
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const amenitiesValue = formData.get('amenities') as string;
    const newAmenities = amenitiesValue.split(/,|\n/).map((amenity: string) => amenity.trim());

    updateAmenities({ variables: { propertyId: property?.id, amenities: newAmenities } });
  }

  return show ?
    <Modal title="Manage Property Amenities" onClose={close} actionElement={getActionElement()} onSubmit={submit} errorMessage={updateError?.message}>
      <FormTextArea
        name="amenities"
        label="Amenities"
        hint="One in each line or separated by comma"
        placeholder="Gym&#10;Swimming Pool&#10;Roof Solar"
        required
        rows={8}
        defaultValue={amenities.map(amenity => amenity.title).join('\n')}
      />
    </Modal>

    : null

}