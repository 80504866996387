// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDo0WcqtWpiLMFO_rXggHTWa4OM-Y04i0U",
  authDomain: "sincere-canyon-309808.firebaseapp.com",
  projectId: "sincere-canyon-309808",
  storageBucket: "sincere-canyon-309808.appspot.com",
  messagingSenderId: "271336062483",
  appId: "1:271336062483:web:e53a070c7e611314fac9e6",
  measurementId: "G-CVJW5TXNPE",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { firebaseApp, messaging };
