import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { Property, PropertyRent } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_ADD_RENT, MUTATION_UPDATE_RENT } from "../../../graphql/mutations/rent";
import { toCurrencyValue, toDateForInput } from "../../../utils/string.utils";
import { FormCheck } from "../../../components/form/check";

interface Props {
  property?: Property;
  rent?: PropertyRent;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function PropertyRentModal({ property, rent, show, onShowHide }: Props) {

  const [addRent, { data: addData, loading: adding, error: addError }] = useMutation(MUTATION_ADD_RENT, { errorPolicy: 'all' })
  const [updateRent, { data: updateData, loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_RENT, { errorPolicy: 'all' })

  const [mode, setMode] = useState<'add' | 'update'>('add');
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (addData || updateData) {
      onShowHide && onShowHide(false);
    }
  }, [addData, updateData]);

  useEffect(() => {
    if (addError || updateError) {
      setErrorMessage(addError?.message || updateError?.message);
    }
  }, [addError, updateError]);

  useEffect(() => {
    if (rent) {
      setMode('update');
    } else {
      setMode('add');
    }
  }, [rent]);

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Cancel" type="button" color="white" onClick={close}/>
        <FormButton title={adding || updating ? 'Saving...' : 'Save'} type="submit" loading={adding || updating} disabled={adding || updating} />
      </div>
    )
  }

  function close() {
    onShowHide && onShowHide(false)
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    if (!property) {
      return;
    }

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const data: Partial<PropertyRent> & { propertyId: string }= {
      propertyId: property?.id,
      rent: parseInt(formData.get('rent') as string) * 100,
      date : formData.get('date') ? (formData.get('date') as string) : null, // Allow date to be null
      isPaid: !!formData.get('isPaid')
    }

    if (mode === 'update' && rent) {
      data.id = rent.id;
      updateRent({ variables: data });
    } else {
      addRent({ variables: data });
    }

    setErrorMessage(undefined);
  }

  return show ?
    <Modal title={(mode === 'add' ? 'Add' : 'Update') + ' Property Rent'} onClose={close} actionElement={getActionElement()} onSubmit={submit} errorMessage={errorMessage}>
      <div className="grid grid-cols-2 gap-4">
        <FormInput name="rent" label="Rent Amount" type="number" isCurrency step={1} required defaultValue={toCurrencyValue(rent?.rent)} />
        <FormInput name="date" label="Rent Due Date" type="date"  defaultValue={toDateForInput(rent?.date)} />
        <FormCheck name="isPaid" label="Paid?" defaultChecked={rent?.isPaid} />
      </div>
    </Modal>

    : null

}