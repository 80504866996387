import { useLazyQuery, useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Role, RoleAction, RoleModule } from "../../__generated__/graphql";
import { Form } from "../../components/form/form";
import { LoadingSpinner } from "../../components/loading/spinner";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Headline } from "../../components/text/headline";
import { MUTATION_ADD_ROLE, MUTATION_UPDATE_ROLE } from "../../graphql/mutations/role";
import { QUERY_GET_ROLE } from "../../graphql/queries/role";
import { AppRoute } from "../../routes";
import { AuthService } from "../../services/auth.service";
import { ROLE_ASSIGNMENTS } from "./assignments";
import { RoleFormMessages } from "./components/messages";
import { RoleFormActions } from "./sections/action-form";
import { RoleFormAssignmentSection } from "./sections/assignment-form";
import { RoleFormProfileSection } from "./sections/profile-form";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";

export function RolePage() {
  const location = useLocation()
  const navigate = useNavigate();

  const [role, setRole] = useState<Role>();

  const [getRole, { data, loading, error }] = useLazyQuery(QUERY_GET_ROLE, { errorPolicy: 'all', fetchPolicy: 'no-cache' });
  const [addRole, { data: submitData, loading: submitting, error: submitError }] = useMutation(MUTATION_ADD_ROLE, { errorPolicy: 'all' })
  const [updateRole, { data: updateData, loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_ROLE, { errorPolicy: 'all' })
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [added, setAdded] = useState(false);
  const [updated, setUpdated] = useState(false);
  
  useAuthAndErrorHandling(error);

  useEffect(() => {
    if (!AuthService.authenticate) {
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const _added = queryParams.get('added');
    const _updated = queryParams.get('updated');
    if (id) {
      getRole({ variables: { id } })
      setMode('edit');
    } else {
      setMode('add');
    }
    if (_added) {
      setAdded(true);
    }
    if (_updated) {
      setUpdated(true);
    }

    // Navigate back to property list when Escape key pressed
    const handleKeyNav = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          navigate(AppRoute.PropertyList)
          break;
      }
    };
  
    window.addEventListener('keydown', handleKeyNav);
  
    return () => {
      window.removeEventListener('keydown', handleKeyNav);
    };
  }, []);

  useEffect(() => {
    if (submitData && submitData.addRole && submitData.addRole.id) {
      navigate(`${AppRoute.RoleList}?id=${submitData.addRole.id}&added=1`);
    }
  }, [submitData]);

  useEffect(() => {
    if (updateData && updateData.updateRole && updateData.updateRole.id) {
      navigate(`${AppRoute.RoleList}?updated=1`);
    }
  }, [updateData]);

  useEffect(() => {
    if (data && data.roleById) {
      setRole(data.roleById);
    }
  }, [data]);

  function refresh() {
    if (!role) {
      return;
    }
    getRole({ variables: { id: role?.id } })
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    const selectedAssignments: { module: RoleModule, action: RoleAction }[] = [];

    ROLE_ASSIGNMENTS.forEach(assignment => {
      assignment.actions.forEach(action => {
        const isChecked = formData.get(`${assignment.module}-${action.name}`) === 'on';
        if (isChecked) {
          selectedAssignments.push({ module: assignment.module, action: action.name });
        }
      });
    });

    if (mode === 'edit') {
      updateRole({
        variables: {
          id: role?.id,
          name: formData.get('name') as string,
          description: formData.get('description') as string,
          assignments: selectedAssignments,
        }
      })
      return;
    } else {
      addRole({
        variables: {
          name: formData.get('name') as string,
          description: formData.get('description') as string,
          assignments: selectedAssignments,
        }
      })
    }
  }

  return (
    <>
      <Sidebar />

      <section className='content w-full'>

        <Headline className="flex gap-lg">
          {mode === 'add' ? 'Add Role' : 'Update Role'}
          {loading && <LoadingSpinner size={32} />}
        </Headline>

        <div className="flex flex-col md:flex-row gap">

          <Form className="flex-3" onSubmit={submit} disabled={submitting || updating}>
            <>

              <RoleFormMessages submitting={submitting} updating={updating} added={added} updated={updated}
                updateError={updateError} submitError={submitError} />
              
              <RoleFormProfileSection role={role} />
              <RoleFormAssignmentSection role={role} />
              <RoleFormActions mode={mode} />
              
            </>
          </Form>

        </div>

      </section>

    </>
  )
}