import { gql } from '@apollo/client';

export const QUERY_GET_PROPERTY = gql`
  query getProperty($id: String!) {
    property(id: $id) {
      id
      title
      description
      excerpt
      address
      location
      code
      price
      bed
      bath
      sqFootage
      agreementPrice
      transactionFee
      platformFee
      serviceCharges
      fundingDeadlineDate
      city {
        name
        excerpt
        description
        propertyDescription
      }
      amenities {
        title
      }
      timelineEvents {
        id
        title
        description
        date
      }
      financials {
        id
        projectedAnnualRoIPercentage,
        projectedAnnualRoIValue,
        year2ProjectedAnnualRoIPercentage,
        year2ProjectedAnnualRoIValue,
        year3ProjectedAnnualRoIPercentage,
        year3ProjectedAnnualRoIValue,
        annualRent,
        effectiveDate
        endDate
      }
      images {
        id
        ext
      }
      rents {
        id
        date
        rent
        isPaid
        isDisbursed
      }
      documents {
        id
        ext
        filename
      }
      status
      createdAt
      type {
        name
      }
    }
  }
`;
