import { Mermaid } from "../../../components/mermaid/mermaid";
import Data from './data'

const data = {
  nodes: [
    { id: 'Visited Website', target: 0 },
    { id: 'Signed Up', target: 1 },
    { id: 'Completed KYC', target: 2 },
    { id: 'Downloaded App', target: 3 },
    { id: 'Accessed Web Portal', target: 4 },
    { id: 'Made Investment', target: 5 },
    { id: 'Bounced', target: 6 } // Represents users who left the process at any stage
  ],
  links: [
    { source: 0, target: 1, value: 500 }, // Example: 500 users signed up after visiting the website
    { source: 0, target: 6, value: 2000 }, // Example: 2000 users left the website without signing up
    { source: 1, target: 2, value: 400 }, // Example: 400 signed-up users completed KYC
    { source: 1, target: 6, value: 100 }, // Example: 100 signed-up users left without completing KYC
    { source: 2, target: 3, value: 300 }, // Example: 300 users who completed KYC downloaded the app
    { source: 2, target: 4, value: 200 }, // Example: 200 users who completed KYC accessed the web portal
    { source: 3, target: 5, value: 100 }, // Example: 100 users who downloaded the app made an investment
    { source: 4, target: 5, value: 50 } // Example: 50 users who accessed the web portal made an investment
    // You can add more links as needed to represent additional transitions in the user journey
  ]
};

export function AnalyticsUserJourney() {
  return (
    <div className='rounded-lg shadow-lg bg-white p-5'>
      <h2 className="text-bold text-md mb-4">User journeys</h2>
      <Mermaid chart={Data} />
    </div>
  )
  
};