import { useQuery } from "@apollo/client";
import { Sidebar } from "../../components/sidebar/sidebar";
import { QUERY_GET_CITIES_WITH_INVESTMENT } from "../../graphql/queries/get-cities";
import { CityModal } from "./components/citymodal";
import { useEffect, useState } from "react";
import { City } from "../../__generated__/graphql";
import { toCurrency } from "../../utils/string.utils";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";

export function CitiesPage() {
  const [showModal, setShowModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const { loading, error, data } = useQuery(QUERY_GET_CITIES_WITH_INVESTMENT);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;
  useAuthAndErrorHandling(error);


  const citiesData = data?.cities?.map((city: any) => {
    const numberOfProperties = city.properties.length;
    const totalInvestment = city.properties.reduce((sum: number, property: any) => {
      return sum + property.pledges
        .filter((pledge: any) => pledge.status === 'Confirmed')
        .reduce((innerSum: number, pledge: any) => innerSum + parseInt(pledge.amount, 10), 0);
    }, 0);

    return {
      ...city,
      numberOfProperties,
      totalInvestment
    };
  }) || [];



  const handleRowClick = (city: any) => {
    setSelectedCity(city);
    setShowModal(true);
  };

  return (
    <>
      <Sidebar />
      <section className='content full properties'>
        <table className="table-auto w-full rounded-lg border-separate bg-white border pt-2">
          <thead>
            <tr>
              <th className="px-4 py-3 border-b">Name</th>
              <th className="px-4 py-3 border-b">Properties</th>
              <th className="px-4 py-3 border-b">Total Investment</th>
            </tr>
          </thead>
          <tbody>
            {citiesData.map((city: any, index: number) => (
              <tr key={index} onClick={() => handleRowClick(city)} className="cursor-pointer hover:bg-pn-blue hover:text-white">
                <td className="text-sm text-center px-4 py-3">{city.name}</td>
                <td className="text-sm text-center px-4 py-3">{city.numberOfProperties}</td>
                <td className="text-sm text-center px-4 py-3">{toCurrency(city.totalInvestment) || "£0"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <CityModal city={selectedCity || undefined} show={showModal} onShowHide={setShowModal} />
    </>
  );
}
