import { gql } from '@apollo/client';

export const QUERY_GET_CMS_VALUES = gql`
  query GetCMSValues {
    getCMSValues {
      name
      key
      value
      section {
        name
      }
      page {
        name
      }
      language {
        key
      }
    }
  }
`;