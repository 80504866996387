import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren<{}> {
  className?: string;
}

export function Headline({ className, children }: Props) {

  function getClassName() {
    let _className = 'text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mb-4';
    if (className) {
      _className += ` ${className}`;
    }
    return _className;
  }

  return <h1 className={getClassName()}>{children}</h1>
}
