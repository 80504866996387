import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { FormButton } from "../../components/form/button";
import { FormInput } from "../../components/form/input";  
import { FormTextArea } from "../../components/form/textarea";
import { Modal } from "../../components/modal/modal";
import { MUTATION_SEND_EMAIL_TO_LEAD } from "../../graphql/mutations/lead";

interface Props {
  email: string;
  show: boolean;
  onShowHide: (show: boolean) => void;
  onEmailSent: () => void; // Add this prop
  conversationId?: string | null;
}

export function EmailModal({ email, show, onShowHide, onEmailSent, conversationId }: Props) {
  const [sendEmailToLead, { data, loading, error }] = useMutation(MUTATION_SEND_EMAIL_TO_LEAD, { errorPolicy: 'all' });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<string>();

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const handleData = async () => {
      if (data) {
        await delay(3000);
        onShowHide(false);
        onEmailSent(); // Call the callback function
      }
    }
    handleData();
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  function close() {
    onShowHide(false);
  }

  async function submit(e: FormEvent) {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    try {
      await sendEmailToLead({
        variables: {
          email,
          subject: formData.get('subject') as string,
          body: formData.get('body') as string,
        },
      });
      setInfoMessage('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }

  return show ? (
    <Modal title="Send Email" onClose={close} actionElement={<FormButton title={loading ? 'Sending' : 'Send'} type="submit" loading={loading} disabled={loading} />} onSubmit={submit} errorMessage={errorMessage}>
      {infoMessage && <div className="info">{infoMessage}</div>}
      <div className="grid grid-cols-1 gap-4">
        <FormInput 
          name="subject" 
          label="Subject" 
          type="text" 
          required 
          autoFocus 
        />
        <FormTextArea
          name="body" 
          label="Body" 
          type="text" 
          required 
          rows={8}
        />
      </div>
      {conversationId && (
        <div 
          className="text-center mt-4 font-bold bg-slate-200 p-4 cursor-pointer rounded-md"
          onClick={() => window.open(`https://app.intercom.com/a/inbox/onnd9j9n/inbox/admin/7292837/conversation/${conversationId}`, '_blank')}
        >
          Continue the conversation here
        </div>
      )}
    </Modal>
  ) : null;
}
