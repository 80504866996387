import { gql } from "@apollo/client"

export const MUTATION_ADD_ROLE = gql`mutation addRole($name: String!, $description: String!, $assignments: [RoleAssignmentScalar!]) {
  addRole(data: {name: $name, description: $description, assignments: $assignments}) {
    id
  }
}`

export const MUTATION_UPDATE_ROLE = gql`mutation updateRole($id: String!, $name: String, $description: String, $assignments: [RoleAssignmentScalar!]) {
  updateRole(data: {id: $id, name: $name, description: $description, assignments: $assignments}) {
    id
  }
}`

export const MUTATION_DELETE_ROLE = gql`mutation deleteRole($id: String!) {
  deleteRole(data: {id: $id}) {
    id
  }
}`
