import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState, useMemo } from "react";
import { City } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input";
import { FormTextArea } from "../../../components/form/textarea";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_UPDATE_CITY } from "../../../graphql/mutations/city";
import { GoogleMap, MarkerF, useJsApiLoader, Libraries } from '@react-google-maps/api';

interface Props {
  city?: City;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function CityModal({ city, show, onShowHide }: Props) {
  const [updateCity, { data: updateData, loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_CITY, { errorPolicy: 'all' });

  const [mode, setMode] = useState<'add' | 'update'>('add');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [location, setLocation] = useState<{ lat: number, lng: number }>({ lat: 51, lng: -0.13 });
  const [mapCenter, setMapCenter] = useState<{ lat: number, lng: number }>({ lat: 51, lng: -0.13 });
  const [mapRef, setMapRef] = useState<any>(null);

  const libraries: Libraries = useMemo(() => ['places', 'marker'], []);

  const loaderOptions = useMemo(() => ({
    id: 'google-map-script', // Ensure this is consistent across all uses
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
    libraries,
    language: 'en',
    region: 'US',
  }), [libraries]);

  const { isLoaded } = useJsApiLoader(loaderOptions);

  useEffect(() => {
    if (updateData) {
      onShowHide && onShowHide(false);
    }
  }, [updateData]);

  useEffect(() => {
    if (updateError) {
      setErrorMessage(updateError?.message);
    }
  }, [updateError]);

  useEffect(() => {
    if (city) {
      setMode('update');
      if (city.location) {
        setLocation({ lat: city.location.coordinates[1], lng: city.location.coordinates[0] });
        setMapCenter({ lat: city.location.coordinates[1], lng: city.location.coordinates[0] });
      }
    }
  }, [city]);

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Cancel" type="button" color="white" onClick={close} />
        <FormButton title={updating ? 'Saving...' : 'Save'} type="submit" loading={updating} disabled={updating} />
      </div>
    );
  }

  function close() {
    onShowHide && onShowHide(false);
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const data = {
      id: city!.id,
      name: formData.get('name') as string,
      excerpt: formData.get('excerpt') as string,
      description: formData.get('description') as string,
      propertyDescription: formData.get('propertyDescription') as string,
      latitude: location ? location.lat : undefined,
      longitude: location ? location.lng : undefined
    };
    
    updateCity({ variables: { updateCityInput: data } });

    setErrorMessage(undefined);
  }

  function handleMapClick(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      setLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    }
  }

  function handleOnLoad(map: any) {
    setMapRef(map);
  };

  function handleDragEnd() {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      setLocation({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };

  function handleCenterChanged() {
    if (mapRef) {
      const newCenter = mapRef.getCenter();
      setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
    }
  };

  return show && isLoaded ? (
    <Modal title={'Update City'} onClose={close} actionElement={getActionElement()} onSubmit={submit} errorMessage={errorMessage}>
      <div className="grid grid-cols-2 gap-4">
        <FormInput name="name" label="Name" type="text" required autoFocus defaultValue={city?.name} />
        <FormTextArea rows={3} name="excerpt" label="Excerpt" type="text" required defaultValue={city?.excerpt || ""} />
        <FormTextArea rows={6} name="description" label="Description" type="text" required defaultValue={city?.description || ""} />
        <FormTextArea rows={6} name="propertyDescription" label="Property Description" type="text" required defaultValue={city?.propertyDescription || ""} />
      </div>
      <div className="col-span-2">
        <label className="block text-sm font-medium text-gray-700">
          Location {location ? `(Lat: ${location.lat.toFixed(4)}, Lng: ${location.lng.toFixed(4)})` : ""}
        </label>
        <div style={{ height: "300px", width: "100%" }}>
          <GoogleMap
            center={location || { lat: 51.505, lng: -0.09 }}
            zoom={13}
            mapContainerStyle={{ height: "100%", width: "100%" }}
            onLoad={handleOnLoad}
            onDragEnd={handleDragEnd}
            onCenterChanged={handleCenterChanged}
          >
            <MarkerF position={mapCenter} />
          </GoogleMap>
        </div>
      </div>
    </Modal>
  ) : null;
}
