import { useMutation, useQuery } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input-type";
import { Modal } from "../../../components/modal/modal";
import { QUERY_GET_PROPERTY_TYPES } from "../../../graphql/queries/property-types";
import { MUTATION_ADD_PROPERTY_TYPE, MUTATION_UPDATE_PROPERTY_TYPE, MUTATION_DELETE_PROPERTY_TYPE } from "../../../graphql/mutations/property-types";
import { set } from "date-fns";
import { useAuthAndErrorHandling } from "../../../utils/invalid-token.util";

interface Props {
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

interface PropertyType {
  id: string;
  name: string;
}

export function PropertyTypeModal({ show, onShowHide }: Props) {
  const { data, loading, error, refetch } = useQuery<{ propertyTypes: PropertyType[] }>(QUERY_GET_PROPERTY_TYPES, {
    fetchPolicy: 'no-cache',
  });  
  const [addPropertyType, { loading: adding, error: addError }] = useMutation(MUTATION_ADD_PROPERTY_TYPE, {
    errorPolicy: 'all',
    onCompleted: () => {
      refetch();
      setTypeName('');
      setSelectedType(null);
    },
  });
  const [updatePropertyType, { loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_PROPERTY_TYPE, {
    errorPolicy: 'all',
    onCompleted: () => {
      refetch();
      setTypeName('');
      setSelectedType(null);
    },
  });
  const [deletePropertyType, { loading: deleting, error: deleteError }] = useMutation(MUTATION_DELETE_PROPERTY_TYPE, { errorPolicy: 'all' });
  
  useAuthAndErrorHandling(error);


  const [mode, setMode] = useState<'add' | 'update'>('add');
  const [typeName, setTypeName] = useState<string>('');
  const [selectedType, setSelectedType] = useState<PropertyType | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (addError || updateError || deleteError) {
      setErrorMessage(addError?.message || updateError?.message || deleteError?.message);
    }
  }, [addError, updateError, deleteError]);

  useEffect(() => {
    if (selectedType) {
      setMode('update');
      setTypeName(selectedType.name);
    } else {
      setMode('add');
      setTypeName('');
    }
  }, [selectedType]);

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Cancel" type="button" color="white" onClick={close} />
        <FormButton title={adding || updating ? 'Saving...' : 'Save'} type="submit" loading={adding || updating} disabled={adding || updating} />
      </div>
    );
  }

  function close() {
    onShowHide && onShowHide(false);
    setSelectedType(null);
    setErrorMessage(undefined);
    setTypeName('');
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const name = formData.get('name') as string;

    if (mode === 'update' && selectedType) {
      updatePropertyType({ variables: { id: selectedType.id, name } });
    } else {
      addPropertyType({ variables: { name } });
    }

    setErrorMessage(undefined);
  }

  return show ? (
    <Modal title={(mode === 'add' ? 'Add' : 'Update') + ' Property Type'} onClose={close} actionElement={getActionElement()} onSubmit={submit} errorMessage={errorMessage}>
      <div className="grid grid-cols-1 gap-4">
        <FormInput name="name" label="Type Name" type="text" required autoFocus value={typeName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTypeName(e.target.value)} />
      </div>
      <div className="mt-4">
        <h3 className="font-semibold mb-2">Existing Types</h3>
        <ul>
          {data?.propertyTypes.map((type: PropertyType) => (
            <li key={type.id} className="flex justify-between items-center py-1">
              {type.name}
              <div>
                <button onClick={() =>{ 
                  if(typeName !== '' || selectedType !== null ){
                    setTypeName('');
                    setSelectedType(null);
                    
                  }
                  if (type.id !== selectedType?.id) {
                    setSelectedType(type);
                  }
                  
                }
                  } className="btn btn-sm btn-primary mr-2">Edit</button>
                <button
                  onClick={() => deletePropertyType({ variables: { id: type.id } }).then(refetch)}
                  className="btn btn-sm btn-danger"
                  disabled={deleting}
                >
                  {deleting ? 'Deleting...' : 'Delete'}
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  ) : null;
}
