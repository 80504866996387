import { gql } from '@apollo/client';

export const QUERY_GET_CITIES_WITH_INVESTMENT = gql`
  query GetCitiesWithPropertiesAndInvestments{
    cities {
    id
    name
    excerpt
    description
    propertyDescription
    location
    properties {
      id
      pledges {
        amount
        status
      }
    }
    
  }
  }
`;