import { gql } from "@apollo/client";

export const MUTATION_DEPOSIT_FIAT = gql`
  mutation depositFiat(
    $userId: String!
    $amount: Int!
    $reference: String
    $source: String!
  ) {
    depositFiat(
      userId: $userId
      amount: $amount
      reference: $reference
      source: $source
    ) {
      id
      status
    }
  }
`;
