import { gql } from "@apollo/client"

export const QUERY_ROLES = gql`query findRoles($take: Int, $skip: Int, $where: WhereInput) {
  roles(paginationOptions: {take:$take, skip:$skip}, where:$where) {
    id
    name
    description
    assignments {
      id
      module
      action
    }
    admins {
      id
    }
  }
}`

export const QUERY_GET_ROLE = gql`query getRole($id: String!) {
  roleById(id: $id) {
    id
    name
    description
    assignments {
      id
      module
      action
    }
  }
}`