import { useEffect, useState } from "react";
import { Property } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { PropertyAmenitiesModal } from "./amenities-modal";

interface Props {
  property?: Property;
  mode: 'add' | 'edit';
  refresh?: () => void;
}

export function PropertyAmenitiesSection({ property, mode, refresh }: Props) {

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) {
      refresh && refresh();
    }
  }, [showModal]);

  return (
    <>
      <PropertyAmenitiesModal property={property} show={showModal} onShowHide={setShowModal} />

      <div className="shadow bg-white border rounded-lg p-4">
        <h2 className="font-semibold text-lg mb-2">Amenities</h2>
        {mode === 'add' && <p className="text-sm text-slate-400 mb-4">Amenities can be added after the property is created</p>}
        {mode === 'edit' && property && property.amenities && (
          <ul className="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg mb-3 max-h-80 overflow-auto">
            {property?.amenities.map((amenity, index) => (
              <li key={index} className={`w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-pn-dark-yellow transition-all duration-300 ${index === 0 ? 'rounded-t-lg' : ''} ${index === property?.financials.length - 1 ? 'rounded-b-lg' : ''}`}>{amenity.title}</li>
            ))}
          </ul>
        )}
        <FormButton title="Manage Amenities" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  );
}
