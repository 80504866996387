import { gql } from "@apollo/client";

export const MUTATION_ADD_RENT = gql`mutation addRent(
    $propertyId: String!,
    $rent: BigInt!,
    $date: String,
    $isPaid: Boolean!,
  ) {
  addRent(data: {
    propertyId: $propertyId,
    rent: $rent,
    date: $date,
    isPaid: $isPaid,
  }) {
    id
  }
}`;

export const MUTATION_UPDATE_RENT = gql`mutation updateRent(
    $id: String!,
    $propertyId: String!,
    $rent: BigInt!,
    $date: String,
    $isPaid: Boolean!,
  ) {
  updateRent(data: {
    id: $id,
    propertyId: $propertyId,
    rent: $rent,
    date: $date,
    isPaid: $isPaid,
  }) {
    id
  }
}`;

export const MUTATION_DELETE_RENT = gql`mutation deleteRent($id: String!) {
  deleteRent(data: { id: $id })
}`;
