import { useEffect, useState } from "react";
import { Property, PropertyRent } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { toCurrency, toDateForDisplay } from "../../../utils/string.utils";
import { PropertyTimelineModal } from "./timeline-modal";
import { PropertyRentModal } from "./rent-modal";

interface Props {
  property?: Property;
  mode: 'add' | 'edit';
  refresh: () => void;
}

export function PropertyRentSection({ property, refresh, mode }: Props) {

  const [showModal, setShowModal] = useState(false);
  const [selectedRent, setSelectedRent] = useState<PropertyRent>();

  useEffect(() => {
    if (!showModal) {
      refresh && refresh();
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedRent) {
      setShowModal(true);
    }
  }, [selectedRent]);

  useEffect(() => {
    if (!showModal) {
      setSelectedRent(undefined);
    }
  }, [showModal])

  function getItemClassname(index: number) {
    if (mode === 'add' || !property) return '';
    let className = 'w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-pn-dark-yellow transition-all duration-300';
    if (index === 0) {
      className = `${className} rounded-t-lg`;
    } else if (index === property?.financials.length - 1) {
      className = `${className} rounded-b-lg`;
    }
    return className;
  }

  return (
    <>
      <PropertyRentModal property={property} rent={selectedRent} show={showModal} onShowHide={setShowModal} />
      <div className="shadow bg-white border rounded-lg p-4">
        <h2 className="font-semibold text-lg mb-2">Rents</h2>
        {mode === 'add' && <p className="text-sm text-slate-400 mb-4">Rent can be added after the property is created</p>}
        {mode === 'edit' && <p className="text-sm text-slate-400 mb-4">Please add atleast 1 rent</p>}
        {mode === 'edit' && property && property.rents && (
          <ul className="bg-white border border-gray-200 rounded-lg mb-3">
            {property?.rents.map((rent, index) => (
              <li key={index} className={getItemClassname(index)}
                onClick={() => setSelectedRent(rent)}>
                  <h3 className="text-sm font-medium text-gray-900">{toDateForDisplay(rent.date)}</h3>
                  <p className="text-xs font-light text-gray-700">{toCurrency(rent.rent)}</p>
                </li>
            ))}
          </ul>
        )}
        <FormButton title="Add Rent" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  )
}