import { gql } from "@apollo/client";

export const MUTATION_UPDATE_ADMIN = gql`mutation updateAdmin($id: String!, $firstName: String, $lastName: String, $email: String, $password: String, $status: UserStatus, $roleId: String!) {
  updateAdmin(data: {id: $id, firstName: $firstName, lastName: $lastName, email: $email, password: $password, status: $status, roleId: $roleId}) {
    id
  }
}`

export const MUTATION_ADD_ADMIN = gql`mutation addUser($firstName: String!, $lastName: String!, $email: String!, $password: String!, $phone: String!, $status: UserStatus, $roleId: String!) {
  addAdmin(data:{ firstName: $firstName, lastName: $lastName, email: $email, password: $password, phone: $phone, status: $status, roleId: $roleId}) {
    id
  }
}`

export const MUTATION_DELETE_ADMIN = gql`mutation deleteAdmin($id: String!) {
  deleteAdmin(data: {id: $id}) {
    id
  }
}`

export const MUTATION_SEND_UNVERIFIED_REMINDER_EMAIL = gql`mutation sendUnverifiedReminderEmail($id: String!) {
  sendUnverifiedReminderEmail(id: $id) {
    id
  }
}`