import { Property } from "../../../__generated__/graphql";
import { PropertyType } from "../../../__generated__/graphql";
import { FormAddress } from "../../../components/form/address";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";
import { FormSelect } from "../../../components/form/select-type"; // Import the FormSelect component
import { QUERY_GET_PROPERTY_TYPES } from "../../../graphql/queries/property-types";
import { useQuery } from "@apollo/client";
import { useAuthAndErrorHandling } from "../../../utils/invalid-token.util";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useState, useEffect } from "react";

interface Props {
  property?: Property;
}

export function PropertyFormProfileSection({ property }: Props) {
  const { data, loading, error } = useQuery<{ propertyTypes: PropertyType[] }>(QUERY_GET_PROPERTY_TYPES);
  const [description, setDescription] = useState(property?.description || '');
  const [excerpt, setExcerpt] = useState(property?.excerpt || '');

  useAuthAndErrorHandling(error);

  useEffect(() => {
    console.log('property:', property);
    if (property?.description) {
      setDescription(property.description);
      // console.log('property address:', property.address);
    }
    if(property?.excerpt) {
      setExcerpt(property.excerpt);
    }
  }, [property]);

  if (error) return <p>Error loading property types</p>;

  const propertyTypeOptions = data?.propertyTypes.map(type => ({ value: type.name, label: type.name })) || [];

  if (property?.type?.name && !propertyTypeOptions.some(option => option.value === property?.type?.name)) {
    propertyTypeOptions.push({ value: property.type.name, label: property.type.name });
  }

  return (
    <FormSection title="Property Profile" description="Basic details about the property">
      <>
        <FormInput label="Property Title" name="title" autoFocus required defaultValue={property?.title} />
        
        <FormAddress
          label="Property Address"
          name="address"
          required
          defaultValue={property?.address}
          defaultCity={property?.city.name}
          defaultLat={property?.location.coordinates[1]}
          defaultLng={property?.location.coordinates[0]}
        />

        <FormSelect 
          label="Property Type"
          name="type"
          options={propertyTypeOptions}
          defaultValue={property?.type?.name || ''}
          required
        />

        <div className="flex flex-col mb-4 max-w-lg">
          <label className="text-sm font-semibold mb-2" htmlFor="excerpt">Property Excerpt</label>
          <ReactQuill 
            id="excerpt"
            placeholder="Enter a excerpt for the property"
            value={excerpt} 
            onChange={setExcerpt}
            className="custom-quill" // Apply custom styles

            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image'
            ]}
            theme="snow" 
          />
          {/* Hidden input to store excerpt value */}
          <input type="hidden" name="excerpt" required value={excerpt} />
        </div>

        <div className="flex flex-col mb-4 max-w-lg">
          <label className="text-sm font-semibold mb-2" htmlFor="description">Property Description</label>
          <ReactQuill 
            id="description"
            placeholder="Enter a description for the property"
            value={description} 
            onChange={setDescription}
            className="custom-quill" // Apply custom styles

            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image'
            ]}
            theme="snow" 
          />
          {/* Hidden input to store description value */}
          <input type="hidden" name="description" required value={description} />
        </div>
      </>
    </FormSection>
  );
}
