import { gql } from '@apollo/client';

export const MUTATION_SEND_EMAIL_TO_LEAD = gql`
  mutation sendEmailToLead($email: String!, $subject: String!, $body: String!) {
    sendEmailToLead(email: $email, subject: $subject, body: $body)
  }
`;

export const MUTATION_SOFT_DELETE_LEAD = gql`
  mutation softDeleteLead($email: String!) {
    softDeleteLead(email: $email) {
      email
    }
  }
`;