import { gql } from '@apollo/client';

export const MUTATION_UPDATE_CITY = gql`
  mutation UpdateCity($updateCityInput: UpdateCityInput!) {
    updateCity(updateCityInput: $updateCityInput) {
      id
      name
      excerpt
      description
      propertyDescription
      location
    }
  }
`;
