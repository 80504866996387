import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { AccountLoginPage } from "./pages/account/login/login";
import { AccountForgetPage } from "./pages/account/forget/forget";
import { AccountResetPage } from "./pages/account/reset/reset";
import { AdminPage } from "./pages/admins/admin";
import { AdminListPage } from "./pages/admins/admin_list";
import { InvestorListPage } from "./pages/investors/investor_list";
import { PropertyEditPage } from "./pages/properties/property";
import { PropertyListPage } from './pages/properties/property_list';
import { PropertyPage } from "./pages/property/property";
import { RewardsPage } from "./pages/rewards/rewards";
import { RoleListPage } from "./pages/roles/roles_list";
import { RolePage } from "./pages/roles/role";
import { AnalyticsPage } from "./pages/analytics/analytics";
import { LeadListPage } from "./pages/leads/lead_list";
import { CitiesPage } from "./pages/cities/cities";
import { Error404Page } from "./pages/error/error404";
import { OtpPage } from "./pages/account/otp/otp";
import { CmsPage } from "./pages/cms/cms";
import Inverstor from "./pages/investors/inverstor";
import { TransactionListPage } from "./pages/transactions/transaction_list";

export enum AppRoute {
  Home = "/",
  Analytics = "/analytics",
  PropertyList = "/property/list",
  PropertyEdit = "/property/edit",
  Property = "/property",
  Investors = "/investor/list",
  Investor = "/investor",
  InvestorProfile = "/investor/:id",
  Transactions = "/transaction/list",
  LeadList = "/lead/list",
  AdminList = "/admin/list",
  Admin = "/admin",
  RoleList = "/role/list",
  Role = "/role",
  Rewards = '/rewards',
  Config = "/config",
  AccountLogin = "/account/login",
  Cities = "/cities",
  AccountReset = "/account/reset",
  AccountForget = "/account/forget",
  LoginVefitication = "/account/verify",
  Cms = "/cms",
}

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path={AppRoute.Home} element={<Navigate to={AppRoute.PropertyList} />} />,
    <Route path={AppRoute.Analytics} element={<AnalyticsPage />} />,
    <Route path={AppRoute.PropertyList} element={<PropertyListPage />} />,
    <Route path={AppRoute.PropertyEdit} element={<PropertyEditPage />} />,
    <Route path={AppRoute.Property} element={<PropertyPage />} />,
    <Route path={AppRoute.Investors} element={<InvestorListPage />} />,
    <Route path={AppRoute.Investor} element={<InvestorListPage />} />,
    <Route path={AppRoute.InvestorProfile} element={<Inverstor />} />,
    <Route path={AppRoute.Transactions} element={<TransactionListPage />} />,
    <Route path={AppRoute.LeadList} element={<LeadListPage />} />,
    <Route path={AppRoute.AdminList} element={<AdminListPage />} />,
    <Route path={AppRoute.Admin} element={<AdminPage />} />,
    <Route path={AppRoute.RoleList} element={<RoleListPage />} />,
    <Route path={AppRoute.Role} element={<RolePage />} />,
    <Route path={AppRoute.Rewards} element={<RewardsPage />} />,
    <Route path={AppRoute.AdminList} element={<RewardsPage />} />,
    <Route path={AppRoute.Config} element={<RewardsPage />} />,
    <Route path={AppRoute.AccountLogin} element={<AccountLoginPage />} />,
    <Route path={AppRoute.AccountForget} element={<AccountForgetPage />} />,
    <Route path={AppRoute.AccountReset} element={<AccountResetPage />} />,
    <Route path={AppRoute.LoginVefitication} element={<OtpPage />} />,
    <Route path={AppRoute.Cities} element={<CitiesPage />} />,
    <Route path={AppRoute.Cms} element={<CmsPage />} />,
    <Route path="*" element={<Error404Page />} />,
  ]),
);

export const AppRoutes = () => <RouterProvider router={router} />
