import { FormEvent, PropsWithChildren, useEffect } from "react";
import { CloseButton } from "../form/close";

interface Props extends PropsWithChildren {
  title: string;
  actionElement?: JSX.Element;
  errorMessage?: string;
  onSubmit?: (e: FormEvent) => void;
  onClose?: () => void;
}

export function Modal({ title, onClose, onSubmit, actionElement, errorMessage, children }: Props) {

  // Close window on Esc key press
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose && onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <div id="default-modal" tabIndex={-1} aria-hidden="true" className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
        <div className="relative w-full max-w-2xl max-h-full p-4">
          <form className="relative bg-white rounded-lg shadow" onSubmit={onSubmit}>
            <div className="flex items-center justify-between p-4 border-b rounded-t md:p-5">
              <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
              <CloseButton onClick={() => onClose && onClose()} />
            </div>
            <div className="p-4 space-y-4 md:p-5">
              {children}
            </div>
            {errorMessage && (
              <p className="text-red-500">{errorMessage}</p>
            )}
            {actionElement && (
              <div className="flex justify-end p-4 border-t border-gray-200 rounded-b md:p-5">
                {actionElement}
              </div>
            )}
          </form>

        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-gray-900/50" onClick={() => onClose && onClose()}></div>
    </>
  );
}