import { useEffect, useState } from "react";
import { Property, PropertyFinancials } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { toDateForDisplay } from "../../../utils/string.utils";
import { PropertyFinancialModal } from "./financial-modal";

interface Props {
  mode: 'add' | 'edit';
  property?: Property;
  refresh?: () => void;
}

export function PropertyFinancialSection({ mode, property, refresh }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [selectedFinancial, setSelectedFinancial] = useState<PropertyFinancials>();

  useEffect(() => {
    if (!showModal) {
      refresh && refresh();
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedFinancial) {
      setShowModal(true);
    }
  }, [selectedFinancial]);

  useEffect(() => {
    if (!showModal) {
      setSelectedFinancial(undefined);
    }
  }, [showModal])

  function getDateRange(financial: PropertyFinancials) {
    const effectiveDate = toDateForDisplay(financial.effectiveDate);
    const endDate = financial.endDate ? toDateForDisplay(financial.endDate) : 'Present';
    return `${effectiveDate} - ${endDate}`;
  }

  function getItemClassname(index: number) {
    if (mode === 'add' || !property) return '';
    let className = 'w-full px-4 py-2 border-b border-gray-200 cursor-pointer hover:bg-pn-dark-yellow transition-all duration-300';
    if (index === 0) {
      className = `${className} rounded-t-lg`;
    } else if (index === property?.financials.length - 1) {
      className = `${className} rounded-b-lg`;
    }
    return className;
  }

  return (
    <>
      <PropertyFinancialModal show={showModal} onShowHide={setShowModal} property={property} financial={selectedFinancial} />
      <div className="shadow bg-white border rounded-lg p-4">
        <h2 className="font-semibold text-lg mb-2">Financials</h2>
        {mode === 'add' && <p className="text-sm text-slate-400 mb-4">Financials can be added after the property is created</p>}
        {mode === 'edit' && <p className="text-sm text-slate-400 mb-4">Please add atleast 1 financial</p>}
        {mode === 'edit' && property && property.financials && (
          <ul className="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg mb-3">
            {property?.financials.map((financial, index) => (
              <li key={index} className={getItemClassname(index)}
                onClick={() => setSelectedFinancial(financial)}>{getDateRange(financial)}</li>
            ))}
          </ul>
        )}
        <FormButton title="Add Financial" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  )
}