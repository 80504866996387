import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  title: string;
  description: string;
  separator?: boolean;
}

export function FormSection(props: Props) {

  const separator = props.separator === undefined ? true : props.separator;

  function getClassname() {
    let className = 'flex flex-col md:flex-row gap-lg ';
    if (separator) {
      className += 'border-b pb-4 mb-10';
    }
    return className;
  }

  return (
    <div className={getClassname()}>
      <div className="flex-1">
        <h3 className="font-semibold mb-2">{props.title}</h3>
        <p className="text-gray-500 text-sm">{props.description}</p>
      </div>
      <div className="flex-2">
        {props.children}
      </div>
    </div>
  )
}