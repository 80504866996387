import { useEffect, useState } from "react";
import { User, UserStatus } from "../../../__generated__/graphql";
import { FormSection } from "../../../components/form/section";

interface Props {
  user?: User;
}

export function AdminFormStatusSection({ user }: Props) {

  const [selectedStatus, setSelectedStatus] = useState<UserStatus>(user?.status || UserStatus.Active);

  useEffect(() => {
    if (user) {
      setSelectedStatus(user.status);
    }
  }, [user]);

  return (
    <FormSection title="Status" description="Status of the admin. Only active admins will be allowed to login to the system">
      <ul className="space-y-1 text-sm text-gray-700" aria-labelledby="dropdownHelperRadioButton">
        <li>
          <div className="flex px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-100">
            <div className="flex items-center h-5">
            <input
                id="blocked"
                name="status"
                type="radio"
                value={UserStatus.Blocked} checked={selectedStatus === UserStatus.Blocked}
                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                onChange={(e) => setSelectedStatus(e.currentTarget.value as UserStatus)}
              />
            </div>
            <div className="ms-2 text-sm">
              <label htmlFor="blocked" className="cursor-pointer font-medium text-gray-900">
                <div>Blocked</div>
                <p id="helper-radio-text" className="text-xs font-normal text-gray-500">Admin has blocked and unable to login</p>
              </label>
            </div>
          </div>
        </li>
        <li>
          <div className="flex px-4 py-2 cursor-pointer rounded-lg hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                id="active"
                name="status"
                type="radio"
                value={UserStatus.Active} checked={selectedStatus === UserStatus.Active}
                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                onChange={(e) => setSelectedStatus(e.currentTarget.value as UserStatus)}
              />
            </div>
            <div className="ms-2 text-sm">
              <label htmlFor="active" className="cursor-pointer font-medium text-gray-900">
                <div>Active</div>
                <p id="helper-radio-text-4" className="text-xs font-normal text-gray-500">Admin is active and allowed to login</p>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </FormSection>
  )
}