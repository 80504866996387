import { useMutation } from '@apollo/client';
import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { MUTATION_RESET_PASSWORD } from '../../../graphql/mutations/password-reset';
import { AppRoute } from '../../../routes';
import { GlobalErrorHandler } from '../../../components/error/global_error.component';

export function AccountResetPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [resetId, setResetId] = useState<string>('');

  const [resetPassword, { loading, error, data }] = useMutation(MUTATION_RESET_PASSWORD, { errorPolicy: 'all' });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const idParam = queryParams.get('id');
    if (idParam) {
      setResetId(idParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    setInfoMessage('Resetting password. Please wait...');
    try {
      const response = await resetPassword({ variables: { id: resetId, code: verificationCode, newPassword } });
      if (response.data.resetPassword.success) {
        setInfoMessage('Password reset successfully. Please log in with your new password.');
        navigate(AppRoute.AccountLogin);
      } else {
        setErrorMessage(response.data.resetPassword.message);
      }
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  }

  return (
    <section className="flex flex-col content account">
      <GlobalErrorHandler />
      <SvgLogo className='mx-auto -mt-16 text-center max-w-52' />
      <h1 className='mt-24 mb-4 font-sans text-5xl font-bold leading-normal tracking-wide text-deep-blue'>Reset password</h1>
      <form className='w-1/2 max-w-md p-8 mx-auto bg-white rounded-lg shadow-md card' onSubmit={onSubmit}>
        <div className="flex flex-col gap-2 p-4">
          <label className='mb-2 -mt-2 text-base font-bold tracking-normal'>Verification code</label>
          <input className='box-border w-full p-4 text-base border border-gray-300 rounded' autoFocus type="text" value={verificationCode} placeholder='Enter your verification code' onChange={e => setVerificationCode(e.target.value)} />
        </div>
        <div className="flex flex-col gap-2 p-4">
          <label className='mb-2 -mt-2 text-base font-bold tracking-normal'>New Password</label>
          <input className='box-border w-full p-4 text-base border border-gray-300 rounded' type="password" value={newPassword} placeholder='Enter your new password' onChange={e => setNewPassword(e.target.value)} />
        </div>
        {infoMessage && <div className="px-12 py-6 mb-12 border rounded-lg bg-light-grey">{infoMessage}</div>}
        {errorMessage && <div className="px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey -600">{errorMessage}</div>}
        <div className="flex flex-col gap-2 p-4">
          <button type="submit" className='btn btn-primary' disabled={loading} style={{ height: '3rem' }}>Reset password</button>
        </div>
      </form>
    </section>
  );
}
