import { gql } from '@apollo/client';

export const QUERY_GET_PROPERTY_LIST = gql`query getProperties($take: Int, $skip: Int, $where: WhereInput) {
  properties(paginationOptions: {take:$take, skip:$skip}, where:$where) {
    id
    title
    code
    price
    agreementPrice
    city {
      name
    }
    isRented
    status
  }
}`