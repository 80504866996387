import { useRef, useState } from "react";
import { RoleAction, RoleModule } from "../../__generated__/graphql";
import { Sidebar } from "../../components/sidebar/sidebar";
import { Tab } from "../../components/tab/tab";
import { Headline } from "../../components/text/headline";
import { AuthService } from "../../services/auth.service";
import { AnalyticsInvestmentRealtime } from "./investment-realtime/analytics-investment-realtime";
import { AnalyticsInvestmentCount } from "./investment-size/analytics-investment-count";
import { AnalyticsInvestmentSize } from "./investment-size/analytics-investment-size";
import { AnalyticsLogin } from "./login/analytics-login";
import { AnalyticsRoI } from "./roi/analytics-roi";
import { AnalyticsUserJourney } from "./user-journey/analytics-user-journey";

export function AnalyticsPage() {
  const newSignups = 100;
  const newProperties = 50;
  const investmentAmounts = 10000;
  const loginFrequency = 2.5; // times per day
  const kycCompletionRate = 0.8; // 80%
  const roi = {
    propertyType: 0.1, // 10%
    location: 0.2, // 20%
    timePeriod: 0.15, // 15%
  };
  const irr = 0.12; // 12%

  const tabs = useRef([
    {
      name: 'Investments',
      action: RoleAction.View,
    },
    {
      name: 'Cities',
      action: RoleAction.View,
    },
    {
      name: 'Investors',
      action: RoleAction.View,
    },
  ]);

  const [selectedTabItem, setSelectedTabItem] = useState('Investments');

  function getTabList() {
    return tabs.current
      .filter(tab => AuthService.hasRole(RoleModule.Analytics, tab.action))
      .map(tab => tab.name);
  }

  function handleTabItemClick(tabItem: string) {
    setSelectedTabItem(tabItem);
  }

  return (
    <>
      <Sidebar />

      <section className='content w-full'>

        <Headline className="flex gap-lg">Analytics</Headline>
        
        <Tab items={getTabList()}
          selectedTabItem={selectedTabItem}
          loading={false}
          handleTabItemClick={handleTabItemClick}
        />


        { selectedTabItem === 'Investments' && (
          <div className="flex flex-col gap-lg">
            <AnalyticsInvestmentRealtime />
          </div>
        )}

        { selectedTabItem === 'Cities' && (
          <div className="flex flex-col gap-lg">
            <div className="flex gap-lg">
              <AnalyticsInvestmentSize />
              <AnalyticsInvestmentCount />
            </div>
            <div className="flex gap-lg">
              <AnalyticsRoI />
            </div>
          </div>
        )}

        { selectedTabItem === 'Investors' && (
          <div className="flex flex-col gap-lg">
            <AnalyticsLogin />
            <AnalyticsUserJourney />
          </div>
        
        )}

        {/* <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">New Signups</h2>
            <p>{newSignups}</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">New Properties Submitted</h2>
            <p>{newProperties}</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">Investment Amounts</h2>
            <p>${investmentAmounts}</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">Average Login Frequency</h2>
            <p>{loginFrequency} times/day</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">KYC Completion Rate</h2>
            <p>{kycCompletionRate * 100}%</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">ROI by Property Type</h2>
            <p>{roi.propertyType * 100}%</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">ROI by Location</h2>
            <p>{roi.location * 100}%</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">ROI by Time Period</h2>
            <p>{roi.timePeriod * 100}%</p>
          </div>
          <div className="p-4 bg-white shadow rounded-lg">
            <h2 className="font-bold text-lg">Internal Rate of Return</h2>
            <p>{irr * 100}%</p>
          </div>
        </div> */}
      </section>
    </>
  )
}
