import { Role } from "../../../__generated__/graphql";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";
import { FormTextArea } from "../../../components/form/textarea";

interface Props {
  role?: Role;
}

export function RoleFormProfileSection({ role }: Props) {
  return (
    <FormSection title="Role Profile" description="Basic details about the role">
      <>
        <FormInput label="Name" name="name" autoFocus required defaultValue={role?.name} />
        <FormTextArea label="Description" name="description" required defaultValue={role?.description} />
      </>
    </FormSection>
  )
}