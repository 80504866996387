import { useEffect, useState } from "react";
import { Property } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { getPropertyDocumentUrl } from "../../../utils/document.utils";
import { PropertyDocumentsModal } from "./doc-modal";
import ImgPdf from '../../../assets/img/common/pdf.png';

interface Props {
  property?: Property
  mode: 'add' | 'edit';
  refresh?: () => void;
}

export function PropertyDocumentsSection({ property, mode, refresh }: Props) {

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      refresh();
    }
  }, [showModal]);

  return (
    <>
      <PropertyDocumentsModal property={property} show={showModal} onShowHide={setShowModal} />

      <div className="shadow bg-white border rounded-lg p-4">
        <h2 className="font-semibold text-lg mb-2">Documents { property && property.documents && property.documents?.length > 0 ? `(${property.documents.length})` : ''}</h2>
        { property && property.documents && property.documents.length > 0 && (
          <div className="flex gap-2 flex-wrap mb-4 max-h-80 overflow-auto">
            {property.documents.map((document, index) => (
              <div key={index} className="relative">
                <img src={ImgPdf} alt="Property" className=" object-cover rounded-lg" /> <p className="text-sm">{document.filename}</p>
              </div>
            ))}
          </div>
        )}
        { property && property.documents?.length === 0 && <p className="text-sm mb-4">No documents added</p>}
        {mode === 'add' && <p className="text-sm text-slate-400 mb-4">Property documents can be added after the property is created</p>}
        <FormButton title="Manage Documents" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  );
}