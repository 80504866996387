import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ReactComponent as SvgLogo } from '../../../assets/img/common/logo.svg';
import { AppRoute } from '../../../routes';
import { AuthService } from '../../../services/auth.service';
import { useMutation } from '@apollo/client';
import { MUTATION_LOGIN } from '../../../graphql/mutations/auth';
import { MUTATION_SEND_OTP } from '../../../graphql/mutations/user';
import { MUTATION_CHECK_AND_SAVE_DEVICE } from '../../../graphql/mutations/device';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export function AccountLoginPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [infoMessage, setInfoMessage] = useState<string>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  const [loginUser, { loading, error, data }] = useMutation(MUTATION_LOGIN, { errorPolicy: 'all' });
  const [sendOtp, { loading: sendOtpLoading, error: sendOtpError, data: sendOtpData }] = useMutation(MUTATION_SEND_OTP, { errorPolicy: 'all' });
  const [checkAndSaveDevice, { loading: checkDeviceLoading, error: checkDeviceError, data: checkDeviceData }] = useMutation(MUTATION_CHECK_AND_SAVE_DEVICE, { errorPolicy: 'all' });

  function getClassNameForRoute(route: AppRoute) {
    let className = 'flex align-middle items-center text-#686e7c text-base border-radius';
    return location.pathname === route ? className + ' selected' : className;
  }

  useEffect(() => {
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        setDeviceId(result.visitorId);
      });
    });
  }, []);

  useEffect(() => {
    if (data && data.login && data.login.accessToken) {
      const verify = async () => {
        await AuthService.login(email, data.login.accessToken, deviceId);
      }
      verify();

      const userAgent = navigator.userAgent;
      const osVersion = getOSVersion(userAgent);
      const deviceType = getDeviceType(userAgent);
      const model = getModel(userAgent);


      checkAndSaveDevice({ variables: { deviceId, osVersion, deviceType, model } });

    }
    setInfoMessage(undefined);
  }, [data]);

  useEffect(() => {
    if (checkDeviceData) {
      sendOtp({ variables: { deviceId } });


      navigate(`${AppRoute.LoginVefitication}?id=${deviceId}`);
    }

  }, [checkDeviceData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    setInfoMessage('Logging in. Please wait...');
    await loginUser({ variables: { email, password } });
  }

  function getOSVersion(userAgent: string) {
    let osVersion = 'Unknown OS Version';

    if (/Windows NT 10.0/.test(userAgent)) osVersion = 'Windows 10';
    else if (/Windows NT 6.3/.test(userAgent)) osVersion = 'Windows 8.1';
    else if (/Windows NT 6.2/.test(userAgent)) osVersion = 'Windows 8';
    else if (/Windows NT 6.1/.test(userAgent)) osVersion = 'Windows 7';
    else if (/Mac OS X/.test(userAgent)) {
      const match = userAgent.match(/Mac OS X (\d+[\._]\d+[\._]?\d*)/);
      if (match) osVersion = `macOS ${match[1].replace(/_/g, '.')}`;
    } else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android\s([0-9\.]*)/);
      if (match) osVersion = `Android ${match[1]}`;
    } else if (/iPhone OS/.test(userAgent)) {
      const match = userAgent.match(/iPhone OS (\d+[\._]\d+)/);
      if (match) osVersion = `iOS ${match[1].replace(/_/g, '.')}`;
    }

    return osVersion;
  }

  function getDeviceType(userAgent: string) {
    if (/android/i.test(userAgent)) return 'android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) return 'ios';
    return 'browser';
  }


  function getModel(userAgent: string) {
    let model = 'Unknown Model';

    if (/iPhone/.test(userAgent)) model = 'iPhone';
    else if (/iPad/.test(userAgent)) model = 'iPad';
    else if (/Macintosh/.test(userAgent)) model = 'Mac';
    else if (/Windows NT/.test(userAgent)) model = 'Windows PC';
    else if (/Android/.test(userAgent)) {
      const match = userAgent.match(/Android.*;\s([a-zA-Z0-9\s]+)\sBuild/);
      if (match) model = match[1];
    }

    return model;
  }

  return (
    <section className="flex content account flex-column">
      <SvgLogo className='logo' />
      <h1 className='mb-4'>Admin Login</h1>
      <p>As an admin, you have the power to manage the platform, view and manage user accounts, and monitor the platform's performance. Let's continue revolutionizing real estate investment together.</p>
      <form className='card' onSubmit={onSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input autoFocus type="email" value={email} placeholder='Enter your email address' onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input type="password" value={password} placeholder='Enter your password' onChange={e => setPassword(e.target.value)} />
        </div>
        {infoMessage && <div className="info">{infoMessage}</div>}
        {error && <div className="error">{error.message}</div>}
        <div className="form-group">
          <button type="submit" className='btn' disabled={loading} style={{ height: '3rem', backgroundColor: '#14223D' }}>Login</button>
        </div>
        <div className="flex justify-center text-center">
          <Link className={getClassNameForRoute(AppRoute.AccountForget)} to={AppRoute.AccountForget}>Forgot password?</Link>
        </div>
      </form>
    </section>
  );
}
