import { gql } from "@apollo/client";

export const MUTATION_ADD_TIMELINE_EVENT = gql`mutation addTimeline(
  $propertyId: String!,
  $date: String!,
  $title: String!,
  $description: String!
) {
  addTimelineEvent(data: {
    propertyId: $propertyId,
    date: $date,
    title: $title,
    description: $description
  }) {
    id
  }
}`;

export const MUTATION_UPDATE_TIMELINE_EVENT = gql`mutation updateTimeline(
  $id: String!,
  $propertyId: String!,
  $date: String!,
  $title: String!,
  $description: String!
) {
  updateTimelineEvent(data: {
    id: $id,
    propertyId: $propertyId,
    date: $date,
    title: $title,
    description: $description
  }) {
    id
  }
}`;

export const MUTATION_DELETE_TIMELINE_EVENT = gql`mutation deleteTimeline($id: String!) {
  deleteTimelineEvent(data: { id: $id })
}`;
