import { gql } from '@apollo/client';

export const GET_NOTIFICATION_STATUS = gql`
  query GetNotificationStatus($deviceId: String!) {
    getNotificationStatus (deviceId: $deviceId) {
      id
      turnNotificationsOn
      hideNotificationBar
    }
  }
`;
