import { gql } from "@apollo/client";

export const MUTATION_UPDATE_AMENITIES = gql`mutation updateAmenities(
  $propertyId: String!,
  $amenities: [String!]!,
) {
  updateAmenities(data:{
    propertyId: $propertyId,
    amenities: $amenities,
  }) {
    id
  }
}`;