import { HTMLProps } from "react";

interface Props extends HTMLProps<HTMLFormElement> {

}

export function Form(props: Props) {
  return (
    <form {...props}>
      <div className="shadow border bg-white rounded-lg p-10">
        {props.children}
      </div>
    </form>
  );
}