import { createContext, useState, ReactNode, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NOTIFICATION_STATUS } from '../graphql/queries/get-notification-status';

interface NotificationContextProps {
  turnNotificationsOn: boolean;
  hideNotificationBar: boolean;
  loading: boolean;
  setTurnNotificationsOn: (value: boolean) => void;
  setHideNotificationBar: (value: boolean) => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  turnNotificationsOn: false,
  hideNotificationBar: false,
  loading: true,
  setTurnNotificationsOn: () => {},
  setHideNotificationBar: () => {},
});

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [turnNotificationsOn, setTurnNotificationsOn] = useState<boolean>(false);
  const [hideNotificationBar, setHideNotificationBar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const deviceId = localStorage.getItem('deviceId');

  const { data,  error } = useQuery(GET_NOTIFICATION_STATUS, {
    variables: { deviceId },
    skip: !deviceId, // This skips the query if deviceId is null
  });

  
  useEffect(() => {
    if (data && data.getNotificationStatus) {
      setTurnNotificationsOn(data.getNotificationStatus.turnNotificationsOn);
      setHideNotificationBar(data.getNotificationStatus.hideNotificationBar);
      setLoading(false);
      console.log('Notification Status:', data.getNotificationStatus);
    }
  }, [data]);

  return (
    <NotificationContext.Provider value={{ turnNotificationsOn, hideNotificationBar, loading, setTurnNotificationsOn, setHideNotificationBar }}>
      {children}
    </NotificationContext.Provider>
  );
};
