import { NavigateFunction } from "react-router-dom";
import { apolloClient } from "..";
import {
  RoleAction,
  RoleAssignment,
  RoleModule,
} from "../__generated__/graphql";
import { QUERY_MY_ROLE } from "../graphql/queries/user";
import { AppRoute } from "../routes";
const { request, gql } = require("graphql-request");

class _AuthService {
  private sKeyEmail = "email";
  private sKeyToken = "token";
  private sKeyRole = "role";
  private skeydeviceId = "deviceId";

  async login(email: string, accessToken: string, deviceId: string) {
    localStorage.setItem(this.sKeyEmail, email);
    localStorage.setItem(this.sKeyToken, accessToken);
    localStorage.setItem(this.skeydeviceId, deviceId);

    await this.refreshRoles();
  }

  async logout(navigate: NavigateFunction) {
    localStorage.clear();
    navigate(AppRoute.AccountLogin);
  }

  async authenticate(navigate: NavigateFunction) {
    const email = localStorage.getItem(this.sKeyEmail);

    if (!email) {
      this.logout(navigate);
      return false;
    }

    const deviceId = localStorage.getItem(this.skeydeviceId);
    const token = localStorage.getItem(this.sKeyToken);
    const query = gql`
      query verifyDevice($email: String!, $deviceId: String!) {
        verifyDevice(email: $email, deviceId: $deviceId)
      }
    `;
    //console.log("api url" , process.env.REACT_APP_API_URL) ;
    const authenticated = await request(
      process.env.REACT_APP_API_URL! + "/admin",
      query,
      { email, deviceId },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    //console.log("api response" , authenticated);

    if (!authenticated.verifyDevice) {
      navigate(AppRoute.LoginVefitication);
      return false;
    }

    await this.refreshRoles();
    return true;
  }

  getToken() {
    return localStorage.getItem(this.sKeyToken);
  }

  async refreshRoles() {
    const response = await apolloClient.query({
      query: QUERY_MY_ROLE,
      fetchPolicy: "no-cache",
    });
    localStorage.setItem(this.sKeyRole, JSON.stringify(response.data.myRole));
  }

  hasRole(module: RoleModule, action: RoleAction) {
    const role = JSON.parse(localStorage.getItem(this.sKeyRole) || "[]");
    console.log("role", role);
    if (!role || !role.assignments) {
      return false;
    }
    return role.assignments.some(
      (role: RoleAssignment) => role.module === module && role.action === action
    );
  }
}

export const AuthService = new _AuthService();
