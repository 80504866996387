import { HTMLProps, useEffect, useState } from "react";
import { toHumanReadableCurrency } from "../../utils/string.utils";

interface Props extends HTMLProps<HTMLInputElement> {
  label: string;
  containerClassname?: string;
}

export function FormCheck({ label, containerClassname, className, ...props }: Props) {

  const [value, setValue] = useState(props.value || '');

  useEffect(() => {
    setValue(props.defaultValue || '')
  }, [props.defaultValue])

  function getInputClassname() {
    let className = 'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg '
    className += 'focus:ring-pn-blue focus:border-pn-blue block w-full p-2.5 '

    if (className) {
      className += className;
    }
    return className;
  }

  function getId() {
    return props.id || label.toLowerCase().replace(' ', '_');
  }

  return (
    <div className={`flex flex-col mb-4 bg-slate-50 ${containerClassname}`}>
      <div className="flex items-center px-4 py-2 border border-slate-300 rounded-lg cursor-pointer">
        <input
          id={getId()}
          name="bordered-checkbox"
          type="checkbox"
          className="w-4 h-4 text-pn-blue bg-slate-500 border-slate-500 rounded-lg cursor-pointer"
          {...props}
        />
        <label htmlFor={getId()} className="w-full py-1 ms-2 text-sm font-medium text-pn-blue cursor-pointer select-none">{label}</label>
    </div>
      {/* <label className="text-sm font-semibold mb-2" htmlFor={getId()}>{label}</label>
      <div className="relative">
        <p className="absolute top-3 text-slate-500 right-5 text-sm">{isCurrency ? toHumanReadableCurrency(parseInt(value as string) * 100) : ''}</p>
        <input className={getInputClassname()} id={getId()} {...props} onChange={(e) => setValue(e.currentTarget.value)} />
      </div> */}
    </div>
  );
}