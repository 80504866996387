import { FormEvent, useEffect, useState } from "react";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input-type";
import { Modal } from "../../../components/modal/modal";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function LinkGeneratorModal({ show, onShowHide }: Props) {
  const [campaignName, setCampaignName] = useState("");
  const [medium, setMedium] = useState("");
  const [source, setSource] = useState("");
  const [content, setContent] = useState("");
  const [term, setTerm] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [generatedLink, setGeneratedLink] = useState<string>();
  const [copied, setCopied] = useState(false);

  function close() {
    onShowHide && onShowHide(false);
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    // Validate required fields
    if (!campaignName || !medium || !source || !content) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }

    setErrorMessage(undefined);

    const baseUrl = "https://propnerd.io";
    const params = new URLSearchParams({
      utm_campaign: campaignName,
      utm_medium: medium,
      utm_source: source,
      utm_content: content,
    });

    if (term) {
      params.append("utm_term", term);
    }

    if (campaignId) {
      params.append("utm_id", campaignId);
    }

    setGeneratedLink(`${baseUrl}?${params.toString()}`);
    setCopied(false);
  }

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton
          title="Cancel"
          type="button"
          color="white"
          onClick={close}
        />
        <FormButton title="Generate" type="submit" color="blue" />
      </div>
    );
  }

  return show ? (
    <Modal
      title={"Generate UTM Link"}
      onClose={close}
      actionElement={getActionElement()}
      onSubmit={submit}
      errorMessage={errorMessage}
    >
      <div className="grid grid-cols-1 gap-4">
        <FormInput
          name="campaignName"
          label="Campaign Name"
          type="text"
          required
          value={campaignName}
          onChange={(e) => setCampaignName(e.currentTarget.value)}
          autoFocus
          placeholder="Enter campaign name (e.g., SeasonalHolidayCampaign)"
        />
        <FormInput
          name="medium"
          label="Medium"
          type="text"
          required
          value={medium}
          onChange={(e) => setMedium(e.currentTarget.value)}
          placeholder="Enter medium (e.g., Social)"
        />
        <FormInput
          name="source"
          label="Source"
          type="text"
          required
          value={source}
          onChange={(e) => setSource(e.currentTarget.value)}
          placeholder="Enter source (e.g., LinkedIn)"
        />
        <FormInput
          name="content"
          label="Content"
          type="text"
          required
          value={content}
          onChange={(e) => setContent(e.currentTarget.value)}
          placeholder="Enter content (e.g., AdVersion1)"
        />
        <FormInput
          name="term"
          label="Term (Optional)"
          type="text"
          value={term}
          onChange={(e) => setTerm(e.currentTarget.value)}
          placeholder="Enter term (e.g., HolidayShopping)"
        />
        <FormInput
          name="campaignId"
          label="Campaign ID (Optional)"
          type="text"
          value={campaignId}
          onChange={(e) => setCampaignId(e.currentTarget.value)}
          placeholder="Enter campaign ID (e.g., 1234)"
        />
      </div>
      {generatedLink && (
  <div className="mt-4 p-4 border border-gray-300 rounded-lg">
    <p className="mb-2 font-semibold">Your generated link:</p>
    <div className="flex items-center flex-wrap break-all">
      <a href={generatedLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline w-full">
        {generatedLink}
      </a>
    </div>
    <div className="flex justify-end mt-2">
      <CopyToClipboard text={generatedLink} onCopy={() => setCopied(true)}>
        <button className="p-2 bg-gray-200 rounded hover:bg-gray-300">
          <i className="lni lni-clipboard" />
        </button>
      </CopyToClipboard>
    </div>
    {copied && <p className="mt-2 text-green-500">Link copied to clipboard!</p>}
  </div>
)}

    </Modal>
  ) : null;
}
