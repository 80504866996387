export default `
---
config:
  sankey:
    showValues: true
    width: 1100
    height: 300
    nodeAlignment: 'center'
    linkColor: 'source'
---
sankey-beta

Signed up, Completed KYC, 42
Completed KYC, Accessed web portal, 10
Completed KYC, Downloaded app, 27
Accessed web portal, Reached Property Marketplace, 20
Downloaded app, Reached Property Marketplace, 27
Reached Property Marketplace, Viewed Property Details, 20
Viewed Property Details, Added Property to Cart, 15
Added Property to Cart, Initiated Investment, 10
Initiated Investment, Completed Investment, 5
    `;
