import Axios from 'axios';
import { AuthService } from './auth.service';

class _UploadService {
  public client = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  constructor() {
    this.client.interceptors.request.use(function (config) {
      const token = AuthService.getToken();
      config.headers.Authorization =  token ? `Bearer ${token}` : '';
      return config;
    });
  }
}

export const UploadService = new _UploadService();