import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { Property, PropertyFile } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { CloseButton } from "../../../components/form/close";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_DELETE_PROPERTY_IMAGE } from "../../../graphql/mutations/property";
import { UploadService } from "../../../services/upload.service";
import { getPropertyImageUrl } from "../../../utils/image.utils";

interface Props {
  property?: Property;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

enum ImageStatus {
  Pending = 'pending',
  Uploading = 'uploading',
  Error = 'error',
  Completed = 'completed',
  Deleting = 'deleting',
}

interface NewImage {
  status: ImageStatus,
  progress?: number,
  errorMessage?: string,
  file: File,
}

export function PropertyImagesModal({ property, show, onShowHide }: Props) {

  const [newImages, setNewImages] = useState<NewImage[]>([]);
  const [currentImages, setCurrentImages] = useState<PropertyFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const [deletePropertyImage, { loading: deleting, error: deleteError }] = useMutation(MUTATION_DELETE_PROPERTY_IMAGE, { errorPolicy: 'all' });

  useEffect(() => {
    return () => {
      setNewImages([]);
      setCurrentImages([]);
    }
  }, []);

  useEffect(() => {
    if (property && property.images) {
      setCurrentImages(property.images);
    } else {
      setCurrentImages([]);
    }
  }, [property]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newNewImages = acceptedFiles.map(file => ({ status: ImageStatus.Pending, progress: 0, file }));
    setNewImages([ ...newImages, ...newNewImages ]);
  }, [newImages]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/webp': [],
    },
    maxSize: 4 * 1024 * 1024,
    multiple: true,
    autoFocus: true,
  });

  function getRootClassname() {
    let className = "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100";
    if (isDragAccept) {
      className += " border-pn-dark-yellow";
    } else if (isDragReject) {
      className += " border-red-500";
    }
    return className;
  }

  function deleteNewImage(index: number) {
    const images = newImages.filter((_, i) => i !== index);
    setNewImages(images);
  }

  function deleteCurrentImage(index: number) {
    if (!property) {
      return;
    }
    const imageToDelete = currentImages[index];
    deletePropertyImage({ variables: { propertyId: property.id, imageId: imageToDelete.id } });
    const images = currentImages.filter((_, i) => i !== index);
    setCurrentImages(images);
  }

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Done" type="button" color="white" onClick={close} />
        <FormButton title={!uploading ? 'Upload' : 'Uploading...'} type="button" onClick={submit} disabled={!(newImages.filter(i => i.status !== ImageStatus.Completed)).length || uploading} />
      </div>
    )
  }

  function close() {
    onShowHide && onShowHide(false)
  }

  async function submit() {
    setErrorMessage(undefined);
    
    if (!property?.id) {
      setErrorMessage('No property selected');
      return;
    }
    
    setUploading(true);
    for (let newImage of newImages) {
      if (newImage.status === ImageStatus.Completed) {
        continue;
      }

      setNewImages(prevImages => prevImages.map(image => {
        if (image.file.name === newImage.file.name) {
          return { ...image, status: ImageStatus.Uploading, progress: 0 };
        }
        return image;
      }));

      const formData = new FormData()
      formData.set('propertyId', property.id);
      formData.append('image', newImage.file)
      try {
        await UploadService.client.post('/property/upload/images', formData, {
          onUploadProgress: (progressEvent) => {
            setNewImages(prevImages => prevImages.map(image => {
              if (image.file.name === newImage.file.name) {
                const progress = Math.round((progressEvent.loaded / (progressEvent?.total || progressEvent.loaded)) * 100);
                return { ...image, progress };
              }
              return image;
            }));
          },
        });
        setNewImages(prevImages => prevImages.map(image => {
          if (image.file.name === newImage.file.name) {
            return { ...image, status: ImageStatus.Completed };
          }
          return image;
        }));
      } catch(err) {
        setNewImages(prevImages => prevImages.map(image => {
          if (image === newImage) {
            return { ...image, status: ImageStatus.Error, errorMessage: 'Failed to upload' };
          }
          return image;
        }));
      }
    }
    setUploading(false);
  }

  function getImageClassname(status: ImageStatus) {
    let className = "border border-radius";
    if (status === ImageStatus.Error) {
      className += " border-red-500 border-4";
    }
    if (status === ImageStatus.Completed) {
      className += " border-green-500 border-4";
    }
    return className;
  }

  return show ?
    <Modal title="Manage Property Images" onClose={close} actionElement={getActionElement()}>
      <p className="text-sm text-gray-500">No changes are made until you click Upload</p>
      { errorMessage && <p className="text-sm text-red-500">{errorMessage}</p> }
      <div className="flex flex-col items-center justify-center w-full" onSubmit={submit}>
        <input type="hidden" name="propertyId" value={property?.id} />
        <label {...getRootProps()} htmlFor="dropzone-file"
          className={getRootClassname()}>
          <div className="flex flex-row items-center justify-center pt-2 pb-2 gap-lg">
            <svg className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <div className="flex flex-col items-center justify-center">
              <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
              <p className="text-xs text-gray-500">PNG, JPG, WEBP (Max 4MB)</p>
            </div>
          </div>
          <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
        </label>
        {property && currentImages.length > 0 && (
          <>
            <h2 className="mt-4 mb-2 text-bold text-lg">Current Images</h2>
            <div className="grid grid-cols-3 gap">
              {currentImages.map((image, index) => (
                <div key={index} className="relative">
                  <CloseButton className="absolute right-1 top-1" onClick={() => deleteCurrentImage(index)} />
                  <img src={getPropertyImageUrl(property.id, image)} alt="Placeholder" className="border border-radius" />
                </div>
              ))}
            </div>
          </>
        )}

        {newImages.length > 0 && (
          <>
            <h2 className="mt-4 mb-2 text-bold text-lg">New Images</h2>
            <div className="grid grid-cols-3 gap">
              {newImages.map((newImage, index) => (
                <div key={index} className="relative">
                  <CloseButton className="absolute right-1 top-1" onClick={() => deleteNewImage(index)} />
                  <img src={URL.createObjectURL(newImage.file)} alt="Placeholder" className={getImageClassname(newImage.status)} />
                  { newImage.errorMessage && <p className="text-center text-xs text-bold text-red-500 mt-2">{newImage.errorMessage}</p> }
                  { newImage.status === ImageStatus.Uploading && (
                    <div className="absolute left-0 bottom-0 right-0 h-2 bg-gray-300 rounded-sm">
                      <div className="h-full bg-pn-dark-yellow" style={{ width: `${newImage.progress}%` }} />
                      <p className="text-center text-xs text-bold text-pn-dark-yellow">{ newImage.progress !== 100 ? 'Uploading...' : 'Processing...' }</p>
                    </div>
                  )}
                  { newImage.status === ImageStatus.Completed && (
                    <p className="text-center text-xs text-bold text-green-700 mt-2">Uploaded</p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>

    : null

}