import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { Property, PropertyTimeline } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { FormInput } from "../../../components/form/input";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_ADD_TIMELINE_EVENT, MUTATION_UPDATE_TIMELINE_EVENT } from "../../../graphql/mutations/timeline";
import { toDateForInput } from "../../../utils/string.utils";
import { FormTextArea } from "../../../components/form/textarea";

interface Props {
  property?: Property;
  timelineEvent?: PropertyTimeline;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

export function PropertyTimelineModal({ property, timelineEvent, show, onShowHide }: Props) {

  const [addEvent, { data: addData, loading: adding, error: addError }] = useMutation(MUTATION_ADD_TIMELINE_EVENT, { errorPolicy: 'all' })
  const [updateEvent, { data: updateData, loading: updating, error: updateError }] = useMutation(MUTATION_UPDATE_TIMELINE_EVENT, { errorPolicy: 'all' })

  const [mode, setMode] = useState<'add' | 'update'>('add');
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (addData || updateData) {
      onShowHide && onShowHide(false);
    }
  }, [addData, updateData]);

  useEffect(() => {
    if (addError || updateError) {
      setErrorMessage(addError?.message || updateError?.message);
    }
  }, [addError, updateError]);

  useEffect(() => {
    if (timelineEvent) {
      setMode('update');
    } else {
      setMode('add');
    }
  }, [timelineEvent]);

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Cancel" type="button" color="white" onClick={close}/>
        <FormButton title={adding || updating ? 'Saving...' : 'Save'} type="submit" loading={adding || updating} disabled={adding || updating} />
      </div>
    )
  }

  function close() {
    onShowHide && onShowHide(false)
  }

  function submit(e: FormEvent) {
    e.preventDefault();

    if (!property) {
      return;
    }

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const data: Partial<PropertyTimeline> & { propertyId: string }= {
      propertyId: property?.id,
      title: formData.get('title') as string,
      description: formData.get('description') as string,
      date: formData.get('date') as string,
    }

    if (mode === 'update' && timelineEvent) {
      data.id = timelineEvent.id;
      updateEvent({ variables: data });
    } else {
      addEvent({ variables: data });
    }

    setErrorMessage(undefined);
  }

  return show ?
    <Modal title={(mode === 'add' ? 'Add' : 'Update') + ' Property Financial'} onClose={close} actionElement={getActionElement()} onSubmit={submit} errorMessage={errorMessage}>
      <div className="grid grid-cols-2 gap-4">
        <FormInput name="title" label="Event title" type="text" required autoFocus defaultValue={timelineEvent?.title} />
        <FormInput name="date" label="Event Date" type="date" required defaultValue={toDateForInput(timelineEvent?.date)} />
        <FormTextArea containerClassname="flex-1 col-span-2" name="description" label="Event description" type="text" rows={5} required defaultValue={timelineEvent?.description} />
      </div>
    </Modal>

    : null

}