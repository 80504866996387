import { useEffect } from "react";
import { Property } from "../../../__generated__/graphql";
import { FormInput } from "../../../components/form/input";
import { FormSection } from "../../../components/form/section";
import { toCurrencyValue, toDateForInput } from "../../../utils/string.utils";

interface Props {
  property?: Property;
}

export function PropertyFormAgreementSection({ property }: Props) {

  return (
    <FormSection title="Agreement" description="Details about the agreement with the property owner">
      <div className="flex flex-col md:flex-row md:gap-3">
        <FormInput label="Agreed Price" containerClassname="flex-1" name="agreementPrice" type="number" step={1000} required defaultValue={property ? toCurrencyValue(property?.agreementPrice) : ''} isCurrency />
        <FormInput label="Funding Deadline" containerClassname="flex-1" name="fundingDeadlineDate" type="date" required defaultValue={property ? toDateForInput(property.fundingDeadlineDate) : ''} />
      </div>
      <div className="flex flex-col md:flex-row md:gap-3">
        <FormInput label="Transaction Fee" containerClassname="flex-1" name="transactionFee" type="number" step={10} required defaultValue={property ? toCurrencyValue(property?.transactionFee) : ''} isCurrency />
        <FormInput label="Service Charges" containerClassname="flex-1" name="serviceCharges" type="number" step={10} required defaultValue={property ? toCurrencyValue(property?.serviceCharges) : ''} isCurrency />
      </div>
    </FormSection>
  )
}