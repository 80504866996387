import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { AuthService } from '../services/auth.service';

export function useAuthAndErrorHandling(error?: ApolloError) {
  const navigate = useNavigate();

  useEffect(() => {
    if (error && error.message.includes('Invalid token')) {
      AuthService.logout(navigate);
      
    }
  }, [error]);

}
