import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { Property, PropertyFile } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { CloseButton } from "../../../components/form/close";
import { Modal } from "../../../components/modal/modal";
import { MUTATION_DELETE_PROPERTY_DOCUMENT } from "../../../graphql/mutations/property";
import { UploadService } from "../../../services/upload.service";
import { getPropertyDocumentUrl } from "../../../utils/document.utils";
import ImgPdf from '../../../assets/img/common/pdf.png';
import { Console } from "console";

interface Props {
  property?: Property;
  show?: boolean;
  onShowHide?: (show: boolean) => void;
}

enum DocumentStatus {
  Pending = 'pending',
  Uploading = 'uploading',
  Error = 'error',
  Completed = 'completed',
  Deleting = 'deleting',
}

interface NewDocument {
  status: DocumentStatus,
  fileName?: string,
  progress?: number,
  errorMessage?: string,
  file: File,
}

export function PropertyDocumentsModal({ property, show, onShowHide }: Props) {

  const [newDocuments, setNewDocuments] = useState<NewDocument[]>([]);
  const [currentDocuments, setCurrentDocuments] = useState<PropertyFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const [deletePropertyDocument, { loading: deleting, error: deleteError }] = useMutation(MUTATION_DELETE_PROPERTY_DOCUMENT, { errorPolicy: 'all' });

  useEffect(() => {
    return () => {
      setNewDocuments([]);
      setCurrentDocuments([]);
    }
  }, []);

  useEffect(() => {
    console.log("documents: ",property)
    if (property && property.documents) {
      setCurrentDocuments(property.documents);
    } else {
      setCurrentDocuments([]);
    }
  }, [property]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newNewDocuments = acceptedFiles.map(file => ({ status: DocumentStatus.Pending, progress: 0, file }));
    setNewDocuments([ ...newDocuments, ...newNewDocuments ]);
  }, [newDocuments]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
    },
    maxSize: 100 * 1024 * 1024,
    multiple: true,
    autoFocus: true,
  });

  function getRootClassname() {
    let className = "flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100";
    if (isDragAccept) {
      className += " border-pn-dark-yellow";
    } else if (isDragReject) {
      className += " border-red-500";
    }
    return className;
  }

  function deleteNewDocument(index: number) {
    const documents = newDocuments.filter((_, i) => i !== index);
    setNewDocuments(documents);
  }

  function deleteCurrentDocument(index: number) {
    if (!property) {
      return;
    }
    const documentToDelete = currentDocuments[index];
    deletePropertyDocument({ variables: { propertyId: property.id, documentId: documentToDelete.id } });
    const documents = currentDocuments.filter((_, i) => i !== index);
    setCurrentDocuments(documents);
  }

  function getActionElement() {
    return (
      <div className="flex gap content-end gap-2">
        <FormButton title="Done" type="button" color="white" onClick={close} />
        <FormButton title={!uploading ? 'Upload' : 'Uploading...'} type="button" onClick={submit} disabled={!(newDocuments.filter(i => i.status !== DocumentStatus.Completed)).length || uploading} />
      </div>
    )
  }

  function close() {
    onShowHide && onShowHide(false)
  }


  function handleNewFileNameChange(index: number, newFileName: string) {
    setNewDocuments(prevDocuments => prevDocuments.map((document, i) => {
      if (i === index) {
        return { ...document, fileName: newFileName };
      }
      return document;
    }));
  }

  async function submit() {
    setErrorMessage(undefined);
    
    if (!property?.id) {
      setErrorMessage('No property selected');
      return;
    }
    
    setUploading(true);
    for (let newDocument of newDocuments) {
      if (newDocument.status === DocumentStatus.Completed) {
        continue;
      }

      setNewDocuments(prevDocuments => prevDocuments.map(document => {
        if (document.file.name === newDocument.file.name) {
          return { ...document, status: DocumentStatus.Uploading, progress: 0 };
        }
        return document;
      }));

      const formData = new FormData()
      formData.set('propertyId', property.id);
      formData.append('document', newDocument.file)
      formData.set('fileName', newDocument.fileName ? newDocument.fileName: '');
      try {
        await UploadService.client.post('/property/upload/documents', formData, {
          onUploadProgress: (progressEvent) => {
            setNewDocuments(prevDocuments => prevDocuments.map(document => {
              if (document.file.name === newDocument.file.name) {
                const progress = Math.round((progressEvent.loaded / (progressEvent?.total || progressEvent.loaded)) * 100);
                return { ...document, progress };
              }
              return document;
            }));
          },
        });
        setNewDocuments(prevDocuments => prevDocuments.map(document => {
          if (document.file.name === newDocument.file.name) {
            return { ...document, status: DocumentStatus.Completed };
          }
          return document;
        }));
      } catch(err) {
        setNewDocuments(prevDocuments => prevDocuments.map(document => {
          if (document === newDocument) {
            return { ...document, status: DocumentStatus.Error, errorMessage: 'Failed to upload' };
          }
          return document;
        }));
      }
    }
    setUploading(false);
  }

  function getDocumentClassname(status: DocumentStatus) {
    let className = "border border-radius";
    if (status === DocumentStatus.Error) {
      className += " border-red-500 border-4";
    }
    if (status === DocumentStatus.Completed) {
      className += " border-green-500 border-4";
    }
    return className;
  }

  return show ?
    <Modal title="Manage Property Documents" onClose={close} actionElement={getActionElement()}>
      <p className="text-sm text-gray-500">No changes are made until you click Upload</p>
      { errorMessage && <p className="text-sm text-red-500">{errorMessage}</p> }
      <div className="flex flex-col items-center justify-center w-full" onSubmit={submit}>
        <input type="hidden" name="propertyId" value={property?.id} />
        <label {...getRootProps()} htmlFor="dropzone-file"
          className={getRootClassname()}>
          <div className="flex flex-row items-center justify-center pt-2 pb-2 gap-lg">
            <svg className="w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <div className="flex flex-col items-center justify-center">
              <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
              <p className="text-xs text-gray-500">PDF (Max 100MB)</p>
            </div>
          </div>
          <input {...getInputProps()} id="dropzone-file" type="file" className="hidden" />
        </label>
        {property && currentDocuments.length > 0 && (
          <>
            <h2 className="mt-4 mb-2 text-bold text-lg">Current Documents</h2>
            <div className="grid grid-cols-3 gap">
              {currentDocuments.map((document, index) => (
                <div key={index} className="relative">
                  <CloseButton className="w-8 h-8 ms-auto inline-flex absolute right-1 top-1" onClick={() => deleteCurrentDocument(index)} />
                  <img src={ImgPdf} alt="PDF" className="border border-radius" />
                </div>
              ))}
            </div>
          </>
        )}

        {newDocuments.length > 0 && (
          <>
            <h2 className="mt-4 mb-2 text-bold text-lg">New Documents</h2>
            <div className="flex flex-col">
              {newDocuments.map((newDocument, index) => (
                <div key={index} className="flex gap">
                  <img src={ImgPdf} alt="PDF" className={getDocumentClassname(newDocument.status)} />
                  <input className="px-4 border rounded" type="text" defaultValue={newDocument.fileName? newDocument.fileName: newDocument.file.name.split('.')[0]} onChange={(e) => handleNewFileNameChange(index, e.target.value)} disabled={newDocument.status==='completed'}/>
                  <CloseButton className="px-4" onClick={() => deleteNewDocument(index)} />
                  { newDocument.errorMessage && <p className="text-center text-xs text-bold text-red-500 mt-2">{newDocument.errorMessage}</p> }
                  { newDocument.status === DocumentStatus.Uploading && (
                    <div className="absolute left-0 bottom-0 right-0 h-2 bg-gray-300 rounded-sm">
                      <div className="h-full bg-pn-dark-yellow" style={{ width: `${newDocument.progress}%` }} />
                      <p className="text-center text-xs text-bold text-pn-dark-yellow">{ newDocument.progress !== 100 ? 'Uploading...' : 'Processing...' }</p>
                    </div>
                  )}
                  { newDocument.status === DocumentStatus.Completed && (
                    <p className="text-center text-xs text-bold text-green-700 mt-2">Uploaded</p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Modal>

    : null

}