import { Link, useNavigate,  } from "react-router-dom";
import { AppRoute } from "../../routes";
import { useEffect } from "react";
import { AuthService } from "../../services/auth.service";

export function Error404Page() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!AuthService.authenticate(navigate)) {
      return;
    }
  },[]);
  return (
    <>
        <section className="content account flex flex-column">
            <h1 className="mb-5">404 Page Not Found</h1>
            <p>Looks like you've stumbled upon uncharted territory.</p>
            <p>No worries, even the most seasoned real estate investors get lost sometimes!</p>
            <p className="underline hover:text-gray-600"><Link to={AppRoute.Home}>Back to Home</Link></p>
        </section>
    </>
  );
}