import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

let isInitialized = false;

export function AnalyticsRoI() {

  const margin = { top: 20, right: 20, bottom: 0, left: 0 },
    width = 360 - margin.left - margin.right,
    height = 460 - margin.top - margin.bottom,
    innerRadius = 40,
    outerRadius = Math.min(width, height) / 2;

  const ref = useRef<HTMLDivElement>(null);
  const svg = useRef<d3.Selection<SVGGElement, unknown, null, undefined>>();

  useEffect(() => {
    return () => {
      isInitialized = false;
      if (ref.current) {
        while (ref.current.hasChildNodes()) {
          ref.current.removeChild(ref.current.firstChild!);
        }
      }
    }
  }, []);

  useEffect(() => {
    init();
  }, [ref]);

  function init() {

    if (isInitialized) {
      return;
    }
    isInitialized = true;

    svg.current = d3.select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${width / 2 + margin.left}, ${height / 2 + margin.top})`);

      const data = [
        { city: 'London', value: 13000 },
        { city: 'Edinburgh', value: 5000 },
        { city: 'Manchester', value: 10000 },
        { city: 'Birmingham', value: 7000 },
        { city: 'Liverpool', value: 3000 },
        { city: 'Glasgow', value: 4000 },        
      ];

      const colorize = d3.scaleSequential()
        .interpolator(d3.interpolateLab("#14223d", "#ffffff"))
        .domain([13000, 1])

      // X scale: common for 2 data series
      const x = d3.scaleBand()
        .range([0, 2 * Math.PI])    // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
        .align(0)                  // This does nothing
        .domain(data.map(d => d.city)); // The domain of the X axis is the list of states.

      // Y scale outer variable
      const y = d3.scaleRadial()
        .range([innerRadius, outerRadius])   // Domain will be define later.
        .domain([0, 13000]); // Domain of Y is from 0 to the max seen in the data

      const arc1 = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius((d: any) => y(d.value))
        .startAngle((d: any) => x(d.city)!)
        .endAngle((d: any) => x(d.city)! + x.bandwidth())
        .padAngle(0.01)
        .padRadius(innerRadius)

      // Add the bars
      svg.current!.append("g")
        .selectAll("path")
        .data(data)
        .join("path")
        .attr("fill", d => colorize(d.value))
        .attr("class", "yo")
        .attr("d", arc1 as any)

      // Add the labels
      svg.current!.append("g")
        .selectAll("g")
        .data(data)
        .join("g")
        .attr("text-anchor", (d) => (x(d.city)! + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "end" : "start")
        .attr("transform", (d) => "rotate(" + ((x(d.city)! + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")" + "translate(" + (y(d.value) + 10) + ",0)")
        .append("text")
        .text(d => d.city)
        .attr("transform", (d) => (x(d.city)! + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "rotate(180)" : "rotate(0)")
        .style("font-size", "11px")
        .attr("alignment-baseline", "middle")
  }

  return (
    <div className='rounded-lg shadow-lg bg-white p-5'>
      <h2 className="text-bold text-md">Investments by city</h2>
      <div className="container mt-3" ref={ref} style={{ width: '100%', height: 450 }}></div>
    </div>
  )
}
