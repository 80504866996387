import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import { useNavigate } from 'react-router-dom';
import { Transaction, User } from '../../../__generated__/graphql';
import { LoadingPlaceholder } from '../../../components/loading/placeholder';
import { PaginatedData } from '../../../interfaces/pagination.interface';
import { toDateTimeForDisplay } from '../../../utils//string.utils';
import { toCurrencyDisplay } from '../../../utils/currency.util';
import { formatRelative } from 'date-fns';

interface Props {
  data: PaginatedData<Transaction>,
  loading: boolean;
}

export function TransactionDepositTable({ data, loading }: Props) {
  const navigate = useNavigate();

  if (loading) {
    return <LoadingPlaceholder />;
  }

  function getFullName(user: User) {
    return [user.firstName, user.lastName].join(' ');
  }

  return (
    <table className="w-full pt-2 bg-white border border-separate rounded-lg table-auto">
      <thead>
        <tr>
          <th className='px-4 py-3 border-b'>TX Code</th>
          <th className='px-4 py-3 border-b'>User</th>
          <th className='px-4 py-3 border-b'>Amount</th>
          <th className='hidden px-4 py-3 border-b md:table-cell'>Time</th>
        </tr>
      </thead>
      <tbody>

        {!loading && data && data.items && data.items. map((transaction: Transaction, index: number) => (
          <tr
            key={index}
            className='transition-all duration-300 cursor-pointer hover:bg-pn-blue hover:text-white'
          >
            <td className='px-4 py-3 text-sm text-nowrap'>{transaction.code}</td>
            <td className='px-4 py-3 text-sm text-nowrap'>
              <b>{getFullName(transaction.user)}</b>
              <br />
              {transaction.user.email}
            </td>
            <td className='px-4 py-3 text-sm'>{toCurrencyDisplay(transaction.amount)}</td>
            <td className='hidden px-4 py-3 text-sm text-center md:table-cell' title={toDateTimeForDisplay(transaction.updatedAt)}>{formatRelative(transaction.updatedAt, new Date())}</td>
          </tr>
        ))}
        {!loading && (!data || !data.items || data.items.length === 0) && <tr><td colSpan={5} className='text-sm text-center'>
          <div className='flex flex-col items-center gap-2 p-5'>
            <InformationCircleIcon width={60} />
            <p className='font-bold'>No Deposits</p>
          </div>
        </td></tr>}
      </tbody>
    </table>
  );

}