import { Link } from "react-router-dom";
import { AppRoute } from "../../../routes";
import { ApolloError } from "@apollo/client";

interface Props {
  submitting: boolean;
  updating: boolean;
  added: boolean;
  updated: boolean;
  submitError?: ApolloError;
  updateError?: ApolloError;
}

export function AdminFormMessages(props: Props) {
  return (
    <>
    {props.submitting && <div className="">
      <p className="text-sm text-slate-400">Adding admin. Please wait...</p>
    </div>}
    {props.updating && <div className="">
      <p className="text-sm text-slate-400">Updating admin. Please wait...</p>
    </div>}
    {props.submitError && <div className="flex bg-amber-100 py-2 px-4 rounded shadow mb-4">
      <p className="text-sm font-semibold text-red">{props.submitError.message}</p>
    </div>}
    {props.updateError && <div className="flex bg-amber-100 py-2 px-4 rounded shadow mb-4">
      <p className="text-sm font-semibold text-red">{props.updateError.message}</p>
    </div>}
    {props.added && <div className="flex justify-between bg-green-100 py-2 px-4 rounded shadow mb-4">
      <p className="text-sm font-semibold">Admin added successfully</p>
      <Link className="text-xs text-pn-blue hover:underline" to={AppRoute.AdminList}>Back to Admins</Link>
    </div>}
    {props.updated && <div className="flex justify-between bg-green-100 py-2 px-4 rounded shadow mb-4">
      <p className="text-sm font-semibold mb-1">Admin saved successfully</p>
      <Link className="text-xs text-pn-blue hover:underline" to={AppRoute.AdminList}>Back to Admins  </Link>
    </div>}
    </>
  )
}