import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RoleAction, RoleModule } from '../../__generated__/graphql';
import { ReactComponent as SvgLogo } from '../../assets/img/common/logo.svg';
import { AppRoute } from '../../routes';
import { AuthService } from '../../services/auth.service';

export function Sidebar() {

  const ITEMS = useRef([
    {
      name: 'Analytics',
      icon: 'lni lni-dashboard',
      route: AppRoute.Analytics,
      module: RoleModule.Analytics,
      actions: [RoleAction.View],
    },
    {
      name: 'Properties',
      icon: 'lni lni-home',
      route: AppRoute.PropertyList,
      highlightOnRoute: [AppRoute.PropertyList, AppRoute.PropertyEdit],
      module: RoleModule.Property,
      actions: [RoleAction.List],
    },
    {
      name: 'Cities',
      icon: 'lni lni-map',
      route: AppRoute.Cities,
      module: RoleModule.Cities,
      actions: [RoleAction.List],
    },
    {
      name: 'Investors',
      icon: 'lni lni-investment',
      route: AppRoute.Investors,
      highlightOnRoute: [AppRoute.Investors, AppRoute.InvestorProfile],
      module: RoleModule.Investor,
      actions: [RoleAction.List],
    },
    {
      name: 'Transactions',
      icon: 'lni lni-graph',
      route: AppRoute.Transactions,
      module: RoleModule.Transaction,
      actions: [RoleAction.List],
    },
    {
      name: 'Rewards',
      icon: 'lni lni-star-empty',
      route: AppRoute.Rewards,
      module: RoleModule.Rewards,
      actions: [RoleAction.List],
    },
    // {
    //   name: 'Config',
    //   icon: 'lni lni-cog',
    //   route: AppRoute.Config,
    //   module: RoleModule.Config,
    //   actions: [RoleAction.List],
    // },
    {
      name: 'Admins',
      icon: 'lni lni-users',
      route: AppRoute.AdminList,
      highlightOnRoute: [AppRoute.AdminList, AppRoute.Admin],
      module: RoleModule.Admin,
      actions: [RoleAction.List],
    },
    
    {
      name: 'Leads',
      icon: 'lni lni-coffee-cup',
      route: AppRoute.LeadList,
      module: RoleModule.Leads,
      actions: [RoleAction.List],
    },
    {
      name: 'Roles',
      icon: 'lni lni-key',
      route: AppRoute.RoleList,
      highlightOnRoute: [AppRoute.RoleList, AppRoute.Role],
      module: RoleModule.Role,
      actions: [RoleAction.List],

    },
    {
      name: 'CMS',
      icon: 'lni lni-control-panel',
      route: AppRoute.Cms,
      module: RoleModule.Cms,
      actions: [RoleAction.Update],
    },
  ]);

  const location = useLocation();
  const navigate = useNavigate();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function getClassNameForSidebarContainer() {
    let className = 'sidebar-container'
    if (showMobileMenu) {
      className += ' show-mobile';
    }
    return className;
  }

  function getClassNameForRoute(highlightForRoutes: AppRoute[]) {
    let className = 'flex text-grey border-radius';
    return isRouteHighlighted(highlightForRoutes, location.pathname) ? className + ' selected' : className;
  }

  function isRouteHighlighted(highlightForRoutes: string[], locationPathname: string): boolean {
    // Split the location pathname into parts
    const pathnameParts = locationPathname.split('/').filter(Boolean);
  
    // Check each route in the highlightForRoutes array
    return highlightForRoutes.some(route => {
      const routeParts = route.split('/').filter(Boolean);
  
      // If the number of segments does not match, they can't be the same route
      if (routeParts.length !== pathnameParts.length) return false;
  
      // Compare each part, allowing :id dynamic segments
      return routeParts.every((part, index) => {
        return part.startsWith(':') || part === pathnameParts[index];
      });
    });
  };

  function logout() {
    AuthService.logout(navigate)
  }

  return (
    <div className={getClassNameForSidebarContainer()}>
      <a href="#mobile-menu" className='mobile-menu' onClick={() => setShowMobileMenu(!showMobileMenu)}><i className='lni lni-menu ' /></a>
      <nav className='sidebar box-shadow bkg-white border-right' style={{ height: '100vh'}}>
        <div className='py-3 mb-3 border-b'>
          <SvgLogo className='flex w-40 mx-auto' />
        </div>
        <div className='top-menu border-bottom'>
          {ITEMS.current.map((item, index) => {
            if (item.actions.some(action => AuthService.hasRole(item.module, action))) {
              return (
                <Link key={index} className={getClassNameForRoute(item.highlightOnRoute || [item.route])} to={item.route}>
                  <i className={item.icon + ' text-lg'} /> {item.name}
                </Link>
              )
            }
            return null;
          })}
        </div>
        <div className='bottom-menu'>
          {/* <Link className={getClassNameForRoute(AppRoute.Profile)} to={AppRoute.Profile}><i className="lni lni-user" />Profile</Link> */}
          <a href="#logout" className={getClassNameForRoute([AppRoute.AccountLogin])} onClick={logout}><i className="text-lg lni lni-exit" />Logout</a>
        </div>
      </nav>
    </div>
  );
}