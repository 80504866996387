import * as d3 from 'd3';
import { format } from 'date-fns';
import { useEffect, useRef } from 'react';

let isInitialized = false;

export function AnalyticsInvestmentRealtime() {

  const ref = useRef<HTMLDivElement>(null);
  const svg = useRef<d3.Selection<SVGGElement, unknown, null, undefined>>();

  const margin = { top: 10, right: 30, bottom: 30, left: 60 },
    width = 1060 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;

  useEffect(() => {
    return () => {
      isInitialized = false;
      if (ref.current) {
        while (ref.current.hasChildNodes()) {
          ref.current.removeChild(ref.current.firstChild!);
        }
      }
    }
  }, []);

  useEffect(() => {
    init();
  }, [ref]);

  function init() {

    if (isInitialized) {
      return;
    }
    isInitialized = true;

    svg.current = d3.select(ref.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`)

    const tooltip = d3.select(ref.current)
      .append("div")
      .style("opacity", 0)
      .style("position", "absolute")
      .attr("class", "tooltip")
      .style("background-color", "#14223d")
      .style("color", "#ffffff")
      .style("border", "1px solid #cccccc")
      .style("border-radius", "8px")
      .style("padding", "15px 20px")


    // A function that change this tooltip when the user hover a point.
    // Its opacity is set to 1: we can now see it. Plus it set the text and position of tooltip depending on the datapoint (d)
    const mouseover = () => {
      tooltip
        .style("opacity", 1)
    }

    const mousemove = (event: any, d: any) => {
      tooltip
        .html(`Investor: ${d.investor} <br> Property: ${d.property} <br> City: ${d.city} <br> Investment: £${d.investment} <br> Date: ${format(d.timestamp, 'yyyy-MM-dd HH:mm:ss')}`)
        .style("left", (event.x) / 2 + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
        .style("top", (event.y) / 2 + "px")
    }

    // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
    const mouseleave = () => {
      tooltip
        .transition()
        .duration(50)
        .style("opacity", 0)
    }

    const colorize = d3.scaleSequential()
      .interpolator(d3.interpolateInferno)
      .domain([10000, 1])

    // Add X axis
    const x = d3.scaleTime()
      .domain([new Date('2024-04-03'), 0])
      .range([0, width]);

    // Add Y axis
    const y = d3.scaleLinear()
      .domain([0, 10000])
      .range([height, 0]);

    svg.current!.append("g")
      .call(d3.axisLeft(y).tickFormat((d: any) => `£${d}`));

    // Add dots
    svg.current!.append('g')
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.timestamp))
      .attr("cy", (d) => y(d.investment))
      .attr("r", 20)
      .style("fill", d => colorize(d.investment))
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)

    x.domain([new Date('2024-04-03'), new Date('2024-04-14')])
    svg.current!.selectAll("circle")
      .transition()
      .delay((_d, i) => (i * 50))
      .duration(1000)
      .attr("cx", (d: any) => x(d.timestamp))
      .attr("cy", (d: any) => y(d.investment))

    svg.current!.append("g")
      .attr("class", "myXaxis")   // Note that here we give a class to the X axis, to be able to call it later and modify it
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x).ticks(d3.timeDay)
        .tickFormat((domainValue: any) => d3.timeFormat("%Y-%m-%d")(domainValue))
      )

  }

  return (
    <div className='rounded-lg shadow-lg bg-white p-5'>
      <h2 className="text-bold text-md">Investments over time</h2>
      <div className="container mt-3" ref={ref} style={{ width: '100%', height: 400 }}></div>
    </div>
  )

}

const data = [
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 500, timestamp: '2024-04-03 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 500, timestamp: '2024-04-03 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 2500, timestamp: '2024-04-03 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 1200, timestamp: '2024-04-04 09:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 4200, timestamp: '2024-04-04 10:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 1000, timestamp: '2024-04-04 11:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 5000, timestamp: '2024-04-05 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 1500, timestamp: '2024-04-05 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 500, timestamp: '2024-04-06 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 3000, timestamp: '2024-04-06 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 1000, timestamp: '2024-04-06 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 3000, timestamp: '2024-04-07 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 800, timestamp: '2024-04-08 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 2200, timestamp: '2024-04-08 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 8000, timestamp: '2024-04-08 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 500, timestamp: '2024-04-09 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 3500, timestamp: '2024-04-09 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 3000, timestamp: '2024-04-09 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 1000, timestamp: '2024-04-10 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 500, timestamp: '2024-04-10 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 500, timestamp: '2024-04-10 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 500, timestamp: '2024-04-11 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 4000, timestamp: '2024-04-12 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 500, timestamp: '2024-04-12 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 500, timestamp: '2024-04-12 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 800, timestamp: '2024-04-12 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 1500, timestamp: '2024-04-12 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 1000, timestamp: '2024-04-13 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 500, timestamp: '2024-04-13 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 1000, timestamp: '2024-04-13 15:01:12' },
  { investor: 'John Smith', property: 'Cozy cabin', city: 'London', investment: 2000, timestamp: '2024-04-13 12:22:54' },
  { investor: 'Michael Fritz', property: 'Luxury apartment', city: 'Edinburgh', investment: 1000, timestamp: '2024-04-14 14:29:41' },
  { investor: 'Alice Johnson', property: 'Beach house', city: 'London', investment: 1000, timestamp: '2024-04-14 15:01:12' },
].map(d => ({ ...d, timestamp: new Date(d.timestamp) }))