import { useEffect, useState } from 'react';
import { Sidebar } from '../../components/sidebar/sidebar'
import { QUERY_GET_USER_BY_ID } from '../../graphql/queries/user';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GlobalErrorHandler } from '../../components/error/global_error.component';
import { toCurrencyDisplay } from '../../utils/currency.util';
import { GET_TRANSACTION } from '../../graphql/queries/get-transactions';
import { format } from 'date-fns';
import { GET_PLEDGES } from '../../graphql/queries/get-pledges';
import { MUTATION_DEPOSIT_FIAT } from '../../graphql/mutations/transaction';

const Inverstor = () => {
    const { id } = useParams(); // Get the 'id' from URL params
    // States for form fields
    const [tokens, setTokens] = useState('');
    const [source, setSource] = useState('Bank Transfer');
    const [reference, setReference] = useState('');
    const [fee, setFee] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

    const [getUser, { loading, error, data }] = useLazyQuery(QUERY_GET_USER_BY_ID, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
    });
    const [getTransactions, { loading: loadingTransactions, data: transactionData, error: transactionError }] = useLazyQuery(GET_TRANSACTION, { fetchPolicy: 'network-only' });
    const [getPledgings, { loading: loadingPledges, data: pledgingData, error: pledgingError }] = useLazyQuery(GET_PLEDGES, { fetchPolicy: 'network-only' });
    const [depositFiat, { loading: depositLoading, error: depositError }] = useMutation(MUTATION_DEPOSIT_FIAT);

    useEffect(() => {
        if (id) {
            // Trigger the query with the 'id' from the URL
            getUser({ variables: { id } });
        }
    }, [id, getUser]);


    // Fetch transactions when component mounts and investorId is available
    useEffect(() => {
        if (id) {
            getTransactions({ variables: { id, take: 10, skip: 0 } }); // Adjust take and skip as necessary
        }
    }, [id, getTransactions]);

    // Fetch pledges when component mounts and investorId is available
    useEffect(() => {
        if (id) {
            getPledgings({ variables: { id, take: 10, skip: 0 } }); // Adjust take and skip as necessary
        }
    }, [id, getPledgings]);

    const handleDeposit = async () => {
        try {

            await depositFiat({
                variables: {
                    userId: id,
                    amount: Number(tokens) * 100 * 100,
                    reference,
                    source,
                },
            });

            // Close the modal
            setIsModalOpen(false);

            if (depositError) {
                console.error("Deposit failed:", depositError);
                return;
            }

            if (!depositError && !depositLoading) {
                clearForm();
                window.location.reload();
            }
        } catch (error) {
            console.error("Deposit failed:", error);
        }
    };

    const clearForm = () => {
        setTokens('');
        setSource('Bank Transfer');
        setReference('');
        setFee('');
    }

    return (
        <>
            <GlobalErrorHandler />

            <Sidebar />

            <section className='content full properties'>

                {/* User Info */}
                <div className="flex items-center justify-between p-4 mb-6">
                    <div className="flex items-center space-x-4">
                        {/* Investor Portfolio Title */}
                        <div>
                            <h1 className="font-sans text-4xl font-bold text-pn-blue">Investor Portfolio</h1>
                        </div>
                    </div>

                    {/* Investor Info */}
                    <div className="text-right">
                        <p className="text-lg font-semibold text-gray-800">
                            {data?.userById?.firstName} {data?.userById?.lastName}
                        </p>
                        <p className="text-sm text-gray-500">
                            {data?.userById?.email}
                        </p>
                    </div>
                </div>

                {/* Balance Info */}
                <div className="flex items-center justify-between card">
                    <div className="mb-4">
                        <p className="text-lg text-gray-600">Investor Balance</p>
                        {error && <p className="mt-4 text-red-600">Error loading balance</p>}
                        {loading ?
                            <p className="mt-4 text-pn-blue">Loading...</p> :
                            <h3 className="pt-2 font-sans text-5xl font-bold text-pn-blue">
                                {toCurrencyDisplay(data?.userById?.investorProfile.balance)}
                            </h3>
                        }
                    </div>

                    <a
                        href={`https://token.propnerd.io/address/${(data?.userById.investorProfile.solanaWalletAddress)}`}
                        className="inline-block px-6 py-3 font-semibold text-center text-white transition duration-300 rounded-lg shadow bg-pn-blue hover:opacity-90"
                        target="_blank"
                        rel="noreferrer"
                    >
                        View Solana Wallet
                    </a>
                </div>

                {/* Transactions */}
                <h2 className="mt-12 mb-3 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">Transactions</h2>
                <div className="w-full !px-8 !py-8 card">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="pb-2 font-semibold text-left border-b">Type</th>
                                <th className="pb-2 font-semibold text-left border-b">Status</th>
                                <th className="pb-2 font-semibold text-left border-b">Date</th>
                                <th className="pb-2 font-semibold text-left border-b">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionError && (
                                <tr><td className="p-8 text-center text-red-600" colSpan={4}>
                                    Error loading transactions
                                </td></tr>
                            )}
                            {loadingTransactions ? (
                                <tr><td className="p-8 text-center" colSpan={4}>
                                    Loading transactions...
                                </td></tr>
                            ) : (
                                transactionData && transactionData.getTransactionById.length === 0 && (
                                    <tr><td className="p-8 text-center" colSpan={4}>
                                        No transactions
                                    </td></tr>
                                )
                            )}
                            {transactionData && transactionData.getTransactionById.length > 0 && [...transactionData.getTransactionById]
                                .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
                                .map((transaction: any) => (
                                    <>
                                        {transaction.status === "Confirmed" &&
                                            <tr key={transaction.createdAt}>
                                                <td className="px-2 py-3 text-left">{transaction.type}</td>
                                                <td className="px-2 py-3 text-left">{transaction.status}</td>
                                                <td className="px-2 py-3 text-left">{format(transaction.createdAt, 'PPP')}</td>
                                                <td className="px-2 py-3 text-left">{toCurrencyDisplay(transaction.amount)}</td>
                                            </tr>
                                        }
                                    </>
                                ))}
                        </tbody>
                    </table>
                </div>

                {/* Pledges/Investments */}
                <h2 className="mt-12 mb-3 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">Investments</h2>
                <div className="w-full !px-8 !py-8 card">
                    <table className="w-full">
                        <thead className='pb-4'>
                            <tr>
                                <th className="pb-2 font-semibold text-left border-b">Title</th>
                                <th className="pb-2 font-semibold text-left border-b">Date</th>
                                <th className="pb-2 font-semibold text-left border-b">Status</th>
                                <th className="pb-2 font-semibold text-left border-b">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pledgingError && (
                                <tr><td className="p-8 text-center text-red-600" colSpan={4}>
                                    Error loading investments
                                </td></tr>
                            )}
                            {loadingPledges ? (
                                <tr><td className="p-8 text-center" colSpan={4}>
                                    Loading investments...
                                </td></tr>
                            ) : (
                                pledgingData && pledgingData.getPropertyPledgeById.length === 0 && (
                                    <tr><td className="p-8 text-center" colSpan={4}>
                                        No investments
                                    </td></tr>
                                )
                            )}
                            {pledgingData && pledgingData.getPropertyPledgeById.length > 0 && [...pledgingData.getPropertyPledgeById]
                                .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
                                .map((pledge: any) => (
                                    <>
                                        {pledge.status === "Confirmed" &&
                                            < tr key={pledge.createdAt} >
                                                <td className="px-2 py-3 text-left">{pledge.property.title}</td>
                                                <td className="px-2 py-3 text-left">{format(pledge.createdAt, 'PPP')}</td>
                                                <td className="px-2 py-3 text-left">{pledge.status}</td>
                                                <td className="px-2 py-3 text-left">{toCurrencyDisplay(pledge.amount)}</td>
                                            </tr>
                                        }
                                    </>
                                ))}
                        </tbody>
                    </table>
                </div>

                {/* Deposit Form */}
                <h1 className="mt-12 mb-3 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">Deposit Tokens</h1>
                <form
                    className="w-full md:w-4/5 lg:w-2/5 card"
                    onSubmit={(e) => e.preventDefault()}
                >
                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="tokens">
                            Tokens (1-10,000)
                        </label>
                        <input
                            id="tokens"
                            type="number"
                            min="1"
                            max="10000"
                            value={tokens}
                            onChange={(e) => {
                                const value = e.target.value;

                                // Allow clearing the input temporarily (when backspacing)
                                if (value === "") {
                                    setTokens(value);
                                    return;
                                }

                                // If the value is less than 1, set it to 1
                                if (Number(value) < 1) {
                                    setTokens('1');
                                    return;
                                }

                                // Ensure the value is within the range and prevent exceeding the max
                                if (Number(value) <= 10000) {
                                    setTokens(value);
                                }
                            }}
                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="source">
                            Source
                        </label>
                        <select
                            id="source"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded"
                        >
                            <option>Bank Transfer</option>
                            <option>Stripe</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="reference">
                            Reference (Optional)
                        </label>
                        <input
                            id="reference"
                            type="text"
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="fee">
                            Fee (Optional)
                        </label>
                        <input
                            id="fee"
                            type="text"
                            value={fee}
                            onChange={(e) => setFee(e.target.value)}
                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded"
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <button
                            className="px-4 py-2 font-bold text-white rounded bg-pn-blue hover:opacity-90 focus:outline-none focus:shadow-outline"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Deposit
                        </button>
                    </div>
                </form>

                {isModalOpen && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
                            <h2 className="mb-4 text-xl font-semibold">Confirm Deposit</h2>
                            <p className="mb-4">Are you sure you want to deposit {tokens} tokens?</p>

                            <div className="flex justify-end space-x-4">
                                <button
                                    className={`px-4 py-2 font-bold text-gray-700 bg-gray-200 rounded hover:bg-gray-300 ${depositLoading && 'cursor-not-allowed'}`}
                                    onClick={() => setIsModalOpen(false)}
                                    disabled={depositLoading}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={`px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-600 ${depositLoading && 'cursor-not-allowed'}`}
                                    onClick={handleDeposit}
                                    disabled={depositLoading}
                                >
                                    {depositLoading ? 'Depositing...' : 'Confirm'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </section >
        </>
    )
}

export default Inverstor