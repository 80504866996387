import { gql } from '@apollo/client';

export const QUERY_GET_LEAD_LIST = gql`
  query getInterestedInvestors($take: Int, $skip: Int) {
    getInterestedInvestors(paginationOptions: { take: $take, skip: $skip }) {
      investors {
        email
        createdAt
        utm_id
        utm_campaign
        utm_medium
        utm_source
        utm_content
        utm_term
        intercomLeadConversationId
        fullName
        phoneNumber
      }
      total
    }
  }
`;
