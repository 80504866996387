import { useEffect, useState } from "react";
import { Property } from "../../../__generated__/graphql";
import { FormButton } from "../../../components/form/button";
import { getPropertyImageUrl } from "../../../utils/image.utils";
import { PropertyImagesModal } from "./images-modal";

interface Props {
  property?: Property
  mode: 'add' | 'edit';
  refresh?: () => void;
}

export function PropertyImagesSection({ property, mode, refresh }: Props) {

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      refresh();
    }
  }, [showModal]);

  return (
    <>
      <PropertyImagesModal property={property} show={showModal} onShowHide={setShowModal} />

      <div className="shadow bg-white border rounded-lg p-4">
        <h2 className="font-semibold text-lg mb-2">Images { property && property.images && property.images?.length > 0 ? `(${property.images.length})` : ''}</h2>
        { property && property.images && property.images.length > 0 && (
          <div className="flex gap-2 flex-wrap mb-4 max-h-80 overflow-auto">
            {property.images.map((image, index) => (
              <div key={index} className="relative">
                <img src={getPropertyImageUrl(property.id, image)} alt="Property" className="w-24 h-24 object-cover rounded-lg" />
              </div>
            ))}
          </div>
        )}
        { property && property.images?.length === 0 && <p className="text-sm mb-4">No images added</p>}
        {mode === 'add' && <p className="text-sm text-slate-400 mb-4">Property images can be added after the property is created</p>}
        {mode === 'edit' &&  <p className="text-sm text-slate-400 mb-4">Please add atleast 3 images</p>}
        <FormButton title="Manage Images" size="sm" disabled={mode === 'add'} onClick={() => setShowModal(true)} />
      </div>
    </>
  );
}