import { HTMLProps } from "react";

interface Props extends HTMLProps<HTMLTextAreaElement> {
  label: string;
  hint?: string;
  containerClassname?: string;
}

export function FormTextArea({ className, containerClassname, ...props }: Props) {

  function getInputClassname() {
    let _className = 'w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg '
    _className += 'focus:ring-pn-blue focus:border-pn-blue block w-full p-2.5 '

    if (className) {
      _className += className;
    }
    return _className;
  }

  return (
    <div className={`flex flex-col mb-4 ${containerClassname}`}>
      <label className={`text-sm font-semibold ${!props.hint ? 'mb-2' : ''}`} htmlFor={props.id}>{props.label}</label>
      {props.hint && <span className="text-xs text-gray-500 mb-2">{props.hint}</span>}
      <textarea className={getInputClassname()} {...props} />
    </div>
  );
}